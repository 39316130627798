import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { BaseApiError } from 'src/errors';
import { SENTRY_URL } from 'src/shared/constants/sentry';
import { serializeAxiosError } from 'src/shared/utils/serialize-axios-error';
import { shouldWeSendThisErrorToSentry } from 'src/shared/utils/should-we-send-this-error-to-sentry';

export const initializeSentry = () => {
  try {
    Sentry.init({
      dsn: SENTRY_URL,
      integrations: [
        new BrowserTracing(),
        new Sentry.Integrations.GlobalHandlers({
          onerror: false,
          onunhandledrejection: true,
        }),
        new CaptureConsole({
          levels: ['error'],
        }),
      ],
      tracesSampleRate: 1.0,
      beforeSend(event, hint) {
        const error = hint.originalException;
        if (error instanceof BaseApiError && error.axiosError) {
          event.extra = {
            apiError: serializeAxiosError(error),
          };
        }
        if (error) {
          if (shouldWeSendThisErrorToSentry(error)) {
            return event;
          } else {
            return null;
          }
        }
        return event;
      },
    });
  } catch (e) {
    console.error('sentry initialization error', e);
  }
};
