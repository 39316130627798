import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './combobox-dropdown.module.scss';

type Props = PropsWithChildren<{
  onButtonClick: () => void;
}>;

export const ComboboxDropdown = observer(function ComboboxDropdown({ children, onButtonClick }: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      {children}
      <button className={styles.button} onClick={onButtonClick}>
        <p className={styles.buttonText}>{t('directory:addNewDirectoryButton')}</p>
      </button>
    </div>
  );
});
