import { DatePicker } from 'antd';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Moment } from 'moment';
import { ReactNode } from 'react';

import { ReactComponent as CalendarIcon } from 'src/shared/assets/icons/calendar-2.svg';
import { DATE_FORMAT } from 'src/shared/constants/date';

import { InputError } from '../input-error';
import { InputLabel } from '../input-label';

import styles from './form-date-picker.module.scss';

type Props = {
  allowClear?: boolean;
  value?: Moment | null; // TODO: make required
  label?: ReactNode;
  errorText?: string | [string, { [locKey: string]: string | number }];
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  inputClassName?: string;
  format?: string;
  defaultTime?: Moment;
  defaultDate?: Moment;
  withTime?: boolean;
  ghostLabel?: boolean;
  picker?: 'week' | 'month' | 'quarter' | 'year';
  onFocus?: () => void;
  onBlur?: () => void;
  disabledDate?: (date: Moment) => boolean;
  onChange?(value: Moment | null, dateString: string): void; // TODO: make required
  getPopupContainer?(props?: unknown): HTMLElement;
};

const dateTimeFormat = 'DD.MM.YYYY HH:mm';

export const FormDatePicker = observer(function FormDatePicker({
  allowClear,
  value,
  label,
  className,
  inputClassName,
  disabled,
  placeholder,
  withTime,
  errorText,
  defaultTime,
  defaultDate,
  ghostLabel = false,
  picker,
  format = withTime ? dateTimeFormat : DATE_FORMAT,
  onFocus,
  onBlur,
  disabledDate,
  onChange,
  getPopupContainer,
}: Props) {
  const _defaultTime = defaultTime ? { defaultValue: defaultTime } : undefined;
  const labelRender = () => {
    if (typeof label === 'string') {
      return <InputLabel text={label} className={clsx(!ghostLabel && styles.label)} />;
    }

    return label;
  };

  return (
    <div className={clsx(styles.inputWrapper, className)}>
      {labelRender()}

      <DatePicker
        value={value}
        disabled={disabled}
        format={format}
        allowClear={allowClear}
        className={clsx(
          styles.input,
          allowClear && !!value && !disabled && styles.input__clearable,
          !!inputClassName && inputClassName
        )}
        placeholder={placeholder}
        suffixIcon={<CalendarIcon className={styles.icon} />}
        defaultPickerValue={defaultDate}
        showTime={_defaultTime || withTime}
        disabledDate={disabledDate}
        onChange={onChange}
        picker={picker}
        onFocus={onFocus}
        onBlur={onBlur}
        getPopupContainer={getPopupContainer}
      />

      {!!errorText && <InputError errorTextRaw={errorText} />}
    </div>
  );
});
