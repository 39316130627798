import { observer } from 'mobx-react-lite';

import { ColumnType, TableRow, TCellType } from 'src/store/table/types';

import { CheckTableCell } from './check-table-cell';
import { DateTableCell } from './date-table-cell';
import { EllipsisTableCell } from './ellipsis-table-cell';
import { SwitcherTableCell } from './switcher-table-cell';
import { TextTableCell } from './text-table-cell';

interface Props {
  row: TableRow;
  rowIndex: number;
  handleSelectRow: (index: number) => void;
  onEditCell: (
    currentRowIndex: number,
    column: string,
    value: string | number | boolean,
    id?: number,
    type?: string
  ) => void;
  type?: string;
  columnName: string;
  cellType?: TCellType;
  editing?: boolean;
  defaultActive?: boolean;
  columnsDataSource?: ColumnType[];
  disabled?: boolean;
}

export const TableCell = observer(function TableCell({
  row,
  rowIndex,
  type,
  cellType,
  columnName,
  onEditCell,
  editing,
  defaultActive,
  disabled,
}: Props) {
  switch (cellType) {
    case 'date':
      return (
        <DateTableCell
          editing={editing}
          fieldValue={row[columnName] as string}
          onEditCell={onEditCell}
          currentRowIndex={rowIndex}
          column={columnName}
          defaultActive={defaultActive}
        />
      );
    case 'string':
      return (
        <TextTableCell
          fieldValue={row[columnName]}
          onEditCell={onEditCell}
          currentRowIndex={rowIndex}
          column={columnName}
          defaultActive={defaultActive}
          editing={editing}
        />
      );
    case 'checkbox':
      return (
        <CheckTableCell
          id={typeof row.id === 'number' ? row.id : undefined}
          type={type}
          currentRowIndex={rowIndex}
          column={columnName}
          isChecked={Boolean(row[columnName])}
          disabled={disabled}
          isReadonly
        />
      );
    case 'switcher':
      return (
        <SwitcherTableCell
          id={typeof row.id === 'number' ? row.id : undefined}
          type={type}
          currentRowIndex={rowIndex}
          column={columnName}
          onEditCell={onEditCell}
          isToggled={Boolean(row[columnName])}
          disabled={disabled}
        />
      );
    default: {
      return <EllipsisTableCell value={row[columnName]} />;
    }
  }
});
