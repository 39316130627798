import { TMatchingItemType, MatchHeadersType } from 'src/pages/import-matching-page/import-matching-page.store';
import { hasValue } from 'src/shared/lib/common';

import { MatchResponseType } from './types';

export function mapMatchExcelHeaders(response: MatchResponseType): MatchHeadersType {
  return {
    fileId: response.fileId,
    unmatchedAttrs: response.unmatchedAttrs.map((item) => ({
      name: item,
      value: null,
    })),
    unmatchedHeaders: response.unmatchedHeaders.map((header) => {
      return {
        columnIndex: header.columnIndex,
        title: header.titles.join('/'),
      };
    }),
    matched: response.matched.map((item) => ({
      name: item.attr,
      value: {
        columnIndex: item.columnIndex,
        title: item.titles.join('/'),
      },
    })),
  };
}

export function serializeMatchHeadersData(matchedData: TMatchingItemType[]): Record<string, string | number> {
  return matchedData.reduce<Record<string, string | number>>((target, item) => {
    if (!hasValue(item.value) || !hasValue(item.value.columnIndex)) {
      return target;
    }

    return {
      ...target,
      [item.name]: item.value.columnIndex,
    };
  }, {});
}
