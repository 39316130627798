import { observer } from 'mobx-react-lite';

import { Switcher } from 'src/shared/components/switcher/switcher';
import { LANGUAGES } from 'src/shared/constants/languages';
import { useStore } from 'src/store';

import { languageItems } from './consts';

type Props = {
  orientation: 'vertical' | 'horizontal';
  isCompact: boolean;
};

export const LanguageSwitcher: React.FC<Props> = observer(function LanguageSwitcher({
  orientation = 'horizontal',
  isCompact,
}) {
  const { language } = useStore();

  if (language.isLanguageSwitcherHidden) {
    return null;
  }

  return (
    <div>
      <Switcher
        isCompact={isCompact}
        orientation={orientation}
        isActive={language.language === LANGUAGES.en}
        items={languageItems}
        onToggle={language.toggle}
      />
    </div>
  );
});
