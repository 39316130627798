import { Switcher } from 'src/features/directory-form/entities/control-entities';
import { TDirectoryTypeAttribute } from 'src/store/directories/types';

import { TSwitcherRaw } from '../types';

export const mapSwitchItem = (item: TSwitcherRaw, attribute: TDirectoryTypeAttribute | undefined): Switcher => {
  const data = {
    formElementRefId: item.attrName,
    fieldId: attribute?.name,
    comment: item.comment,
    enableIf: item.enableIf,
    showIf: item.showIf,
    label: attribute?.defaultLabel,
    defaultValueLink: item.defaultValueLink,
    disabledOnFalse: item.enableElement,
    requiredIf: item.requiredIf,
    disabledOnTrue: item.disableElement,
    type: item.type,
  };

  return new Switcher(data);
};
