import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import styles from './dropdown-preloader.module.scss';

export const DropdownPreloader = observer(function DropdownPreloader() {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <p className={styles.text}>{t('common:pleaseWait')}</p>
      <Spin spinning />
    </div>
  );
});
