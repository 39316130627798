import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { Control, ValidatableItem } from './entities/abstract-entities';
import { TextField, NumberField, IntegerField, RegularComboBox, Switcher } from './entities/control-entities';
import { DateField } from './entities/control-entities/date-field.entity';
import { JoinComboBoxItem } from './entities/control-entities/join-combobox.entity';
import { Label } from './entities/control-entities/label.entity';
import { MultiCombobox } from './entities/control-entities/multicombobox.entity';
import { RemovableRow } from './entities/control-entities/removable-row.entity';
import { StringsList } from './entities/control-entities/strings-list.entity';
import { YearField } from './entities/control-entities/year-field.entity';
import { FormStore } from './entities/form.entity';
import {
  TextFormField,
  NumberFormField,
  FormRegularComboBox,
  SwitcherFeature,
  FormMultiCombobox,
  YearPickerControl,
} from './form-controls';
import { DatePickerControl } from './form-controls/date-picker-control';
import { FormLabelControl } from './form-controls/form-label-control/form-label-control';
import { RemovableRowControl } from './form-controls/removable-row/removable-row';
import { StringsListControl } from './form-controls/strings-list/strings-list-control';

type Props = {
  className?: string;
  item: Control;
  formStore: FormStore;
  showLabel?: boolean;
  getLabel: (item: Control) => string | undefined;
};

// TODO: убрать использование formStore
export const FormElement = observer(function FormElement({
  className,
  item,
  formStore,
  showLabel = true,
  getLabel,
}: Props) {
  const isItemDisabled = formStore.checkIsFormComponentDisabled(item);
  const isItemVisible = formStore.checkIsFormComponentVisible(item);
  const getLabelWithRequired = (item: Control) => {
    return showLabel
      ? `${getLabel(item)}${item instanceof ValidatableItem && item.restrictions?.required ? '*' : ''}`
      : undefined;
  };

  //TODO: переделать выключение филда, добавив состояние в класс филда
  useEffect(() => {
    if (isItemDisabled) {
      item.resetControl();
    }
  }, [item, isItemDisabled]);

  if (!isItemVisible) return null;

  return (
    <>
      {item instanceof TextField && (
        <TextFormField
          className={className}
          item={item}
          isDisabled={item.isDisabled || isItemDisabled}
          label={getLabelWithRequired(item)}
        />
      )}
      {item instanceof NumberField && (
        <NumberFormField
          className={className}
          item={item}
          isDisabled={item.isDisabled || isItemDisabled}
          label={getLabelWithRequired(item)}
        />
      )}
      {item instanceof IntegerField && (
        <NumberFormField
          className={className}
          item={item}
          isInteger
          isDisabled={item.isDisabled || isItemDisabled}
          label={getLabelWithRequired(item)}
        />
      )}
      {item instanceof RegularComboBox && (
        <FormRegularComboBox
          className={className}
          item={item}
          isDisabled={item.isDisabled || isItemDisabled}
          label={getLabelWithRequired(item)}
        />
      )}
      {item instanceof JoinComboBoxItem && (
        <FormRegularComboBox
          className={className}
          item={item}
          isDisabled={item.isDisabled || isItemDisabled}
          label={getLabelWithRequired(item)}
        />
      )}
      {item instanceof MultiCombobox && (
        <FormMultiCombobox
          item={item}
          isDisabled={item.isDisabled || isItemDisabled}
          label={getLabelWithRequired(item)}
        />
      )}
      {item instanceof DateField && (
        <DatePickerControl
          item={item}
          label={getLabelWithRequired(item)}
          isDisabled={item.isDisabled || isItemDisabled}
        />
      )}
      {item instanceof YearField && (
        <YearPickerControl
          item={item}
          label={getLabelWithRequired(item)}
          isDisabled={item.isDisabled || isItemDisabled}
        />
      )}
      {item instanceof StringsList && <StringsListControl item={item} label={getLabelWithRequired(item)} />}
      {item instanceof Switcher && <SwitcherFeature item={item} isDisabled={item.isDisabled || isItemDisabled} />}
      {item instanceof Label && <FormLabelControl item={item} label={getLabelWithRequired(item)} />}
      {item instanceof RemovableRow && (
        <RemovableRowControl item={item} formStore={formStore} getLabel={getLabelWithRequired} />
      )}
    </>
  );
});
