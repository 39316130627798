import { TextField } from '../entities/control-entities';
import { ITextRestrictions } from '../types';

import { Validation } from './validation';

export class TextValidation extends Validation<string> {
  readonly restrictions: ITextRestrictions;

  constructor(control: TextField, restrictions: ITextRestrictions) {
    super(control);
    this.restrictions = restrictions;
  }

  validate(value?: string): string | undefined {
    if (!value) {
      if (this.restrictions.required) return 'directory:Errors.required';
      return;
    }
  }
}
