import { action, computed, makeObservable, observable } from 'mobx';
import moment, { Moment } from 'moment';

import { TRequiredIf } from 'src/api/directory/types';

import { IDateRestrictions } from '../../types';
import { DateValidation } from '../../validation/date-validation';
import { ValidatableItem } from '../abstract-entities';
import { TControlValueType, TEnableIf } from '../types';

type TYearOptions = {
  formElementRefId: string;
  fieldId?: string;
  unit?: string;
  defaultValueLink?: string;
  enableIf?: TEnableIf[];
  requiredIf?: TRequiredIf[];
  restrictions: IDateRestrictions;
  type: TControlValueType;
};

export class YearField extends ValidatableItem<number | null> {
  @observable year: Moment | null = null;
  readonly validation: DateValidation;

  constructor(data: TYearOptions) {
    super(data);
    this.validation = new DateValidation(this, data.restrictions);

    makeObservable(this);
  }

  @computed
  get value(): number | null {
    return this.year?.year() || null;
  }

  checkIsReady(): boolean {
    if (!!this.errorText) return false;
    return this.value !== null;
  }

  @action.bound
  clearError(): void {
    this.errorText = undefined;
  }

  @action.bound
  validate = () => {
    const errorText = this.validation.validate(this.year);
    if (!!errorText) this.setError(errorText);
  };

  @action.bound
  setValue(value: number | null): void {
    if (value === null) {
      this.year = null;
      return;
    }
    const yearDate = new Date(value, 1);
    this.year = moment(yearDate.valueOf());
  }

  @action.bound
  resetControl(): void {
    this.year = null;
    this.clearError();
  }

  @action.bound
  changeValue(value: Moment | null) {
    this.year = value;
  }
}
