import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { LanguageSwitcher } from 'src/features/language-switcher/language-switcher';
import { ThemeSwitcher } from 'src/features/theme-switcher/theme-switcher';

import styles from './styles.module.scss';

type Props = {
  isOpened: boolean;
};

export const Switchers: React.FC<Props> = observer(function Switchers({ isOpened }) {
  return (
    <div className={styles.wrapper}>
      <div className={clsx(styles.switcherWrapper, !isOpened && styles.switcherWrapper__compact)}>
        <LanguageSwitcher orientation={isOpened ? 'horizontal' : 'vertical'} isCompact={!isOpened} />
      </div>
      <div className={clsx(styles.switcherWrapper, !isOpened && styles.switcherWrapper__compact)}>
        <ThemeSwitcher orientation={isOpened ? 'horizontal' : 'vertical'} isCompact={!isOpened} />
      </div>
    </div>
  );
});
