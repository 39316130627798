import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { UnmountWithTransition } from 'src/shared/components/core/unmount-with-transition/unmount-with-transition';

import { ColumnHeader } from '../components/column-header/column-header';
import { InvalidItem } from '../components/invalid-item/invalid-item';
import { useInvalidValuesModel } from '../hooks/useInvalidValuesColumnModel';
import { MatchingImportStore } from '../import-matching-page.store';

import styles from './invalid-values-column.module.scss';

type Props = {
  matchingImport: MatchingImportStore;
};

export const InvalidValuesColumn = observer(function InvalidValuesColumn({ matchingImport }: Props) {
  const { t } = useTranslation('matching');
  const { values, isOver, isFromHeap, setNodeRef } = useInvalidValuesModel(matchingImport);

  return (
    <div ref={setNodeRef} className={styles.wrapper}>
      <ColumnHeader className={styles.header} title={t('columns.freeValues.title')} />
      <p className={styles.descText}>{t('columns.freeValues.desc')}</p>
      <div className={styles.invalidItemsWrapper}>
        {values.map((item) => (
          <InvalidItem key={item.columnIndex} header={item} />
        ))}
        {
          <UnmountWithTransition
            isShown={isOver && !isFromHeap}
            mountStylesObj={{ opacity: 1 }}
            unmountStylesObj={{ opacity: 0 }}
          >
            <div className={styles.dropArea} />
          </UnmountWithTransition>
        }
      </div>
    </div>
  );
});
