import { useSortable } from '@dnd-kit/sortable';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { ReactComponent as DeleteIcon } from 'src/shared/assets/icons/delete.svg';
import { ReactComponent as DotsIcon } from 'src/shared/assets/icons/dots.svg';
import { Button } from 'src/shared/components/button';

import { Control } from '../../entities/abstract-entities';
import { RemovableRowRow } from '../../entities/control-entities/removable-row.entity';
import { FormStore } from '../../entities/form.entity';
import { FormElement } from '../../form-element';

import styles from './removable-row-item.module.scss';

type Props = {
  item: RemovableRowRow;
  formStore: FormStore;
  overlay?: boolean;
  isStatic: boolean;
  getLabel: (item: Control) => string | undefined;
  deleteRow(row: RemovableRowRow): void;
};

export const RemovableRowItem = observer(function RemovableRowItem({
  item,
  formStore,
  overlay,
  isStatic,
  getLabel,
  deleteRow,
}: Props) {
  const { active, attributes, listeners, transform, transition, setNodeRef } = useSortable({
    id: item.id,
  });

  const onDeleteRow = (): void => {
    deleteRow(item);
  };

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      style={{ transform: `translateY(${transform?.y || 0}px)`, transition }}
      className={clsx(
        styles.wrapper,
        active?.id === item.id && styles.wrapper__active,
        overlay && styles.wrapper__overlay
      )}
    >
      {!isStatic && (
        <div className={styles.contentWrapper}>
          <DotsIcon {...listeners} className={styles.dotsIcon} />
          <Button
            variant="default"
            className={styles.deleteButton}
            icon={<DeleteIcon width={18} height={18} />}
            onClick={onDeleteRow}
          />
        </div>
      )}
      <div className={clsx(styles.fieldsWrapper)}>
        {item.fieldsList.map((field) => (
          <FormElement
            key={field.fieldId || field.formElementRefId}
            item={field}
            getLabel={getLabel}
            formStore={formStore}
          />
        ))}
      </div>
    </div>
  );
});
