import { TSwitcherItems } from 'src/shared/components/switcher/switcher';

export const languageItems: TSwitcherItems = [
  {
    text: 'Русский',
    compactText: 'Ru',
  },
  {
    text: 'English',
    compactText: 'En',
  },
];
