import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as BookOpenedIcon } from 'src/shared/assets/icons/book-opened.svg';
import { ROUTES } from 'src/shared/constants/routes';
import { TDirectoryTypeItem } from 'src/store/directories/types';

import styles from './directory-card.module.scss';

type Props = {
  directory: TDirectoryTypeItem;
};

export const DirectoryCard = observer(function DirectoryCard({ directory }: Props) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`${ROUTES.dicts}${directory.name}`);
  };

  return (
    <button className={styles.wrapper} onClick={handleClick}>
      <BookOpenedIcon className={styles.icon} />
      <p className={styles.text}>{directory.label}</p>
    </button>
  );
});
