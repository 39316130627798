import { DragOverlay, useDndContext } from '@dnd-kit/core';
import { snapCenterToCursor } from '@dnd-kit/modifiers';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import styles from './drag-overlay-item.module.scss';

export const DragOverlayItem = observer(function DragOverlayItem() {
  const ctx = useDndContext();

  return (
    <DragOverlay modifiers={[snapCenterToCursor]}>
      <div className={clsx(styles.wrapper)}>
        <p className={styles.text}>{ctx.active?.data.current?.header?.title}</p>
      </div>
    </DragOverlay>
  );
});
