import { ReactComponent as BellIcon } from 'src/shared/assets/icons/bell.svg';
import { ReactComponent as EarphonesIcon } from 'src/shared/assets/icons/earphones.svg';
import { ReactComponent as InfoIcon } from 'src/shared/assets/icons/info.svg';

export const support = [
  {
    name: 'Nav.support',
    renderIcon: (className: string) => <EarphonesIcon className={className} />,
    onClick: () => {},
  },
  {
    name: 'Nav.help',
    renderIcon: (className: string) => <InfoIcon className={className} />,
    onClick: () => {},
  },
  {
    name: 'Nav.notifications',
    renderIcon: (className: string) => <BellIcon className={className} />,
    notifications: {
      count: 1123,
    },
    onClick: () => {},
  },
];
