import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { Control, ValidatableItem } from '../entities/abstract-entities';
import { Switcher } from '../entities/control-entities';
import { Label } from '../entities/control-entities/label.entity';
import { FormStore } from '../entities/form.entity';
import { FormElement } from '../form-element';

import styles from './new-well-column-item.module.scss';

type Props = {
  item: Control;
  formStore: FormStore;
  getLabel: (item: Control) => string;
};

export const NewWellColumnItem = observer(function NewWellColumnItem({ item, formStore, getLabel }: Props) {
  const isItemRequired = (item instanceof ValidatableItem && item.restrictions?.required) || false;

  return (
    <div className={styles.wrapper}>
      <div className={styles.rowWrapper}>
        {(item instanceof Switcher || (item instanceof Label && !item.isPrimitive)) && (
          <div className={styles.itemNameWrapper}>
            <p className={styles.itemName}>{getLabel(item)}</p>
            {isItemRequired && <p>*</p>}
          </div>
        )}
        <div
          className={clsx(
            styles.itemWrapper,
            item instanceof Switcher && styles.switcherWrapper,
            item instanceof Label && (item.isPrimitive ? styles.primitiveLabelWrapper : styles.labelWrapper)
          )}
        >
          <FormElement item={item} formStore={formStore} getLabel={getLabel} showLabel={!(item instanceof Label)} />
        </div>
      </div>
      {item.comment && <p className={styles.comment}>{item.comment}</p>}
    </div>
  );
});
