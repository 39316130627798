import { AxiosResponse } from 'axios';

import { FormStore } from 'src/features/directory-form/entities/form.entity';
import { Group } from 'src/features/directory-form/entities/group.entity';
import { hasValue } from 'src/shared/lib/common';
import { throwApiError } from 'src/shared/utils/throwApiError';
import { RootStore } from 'src/store';
import {
  TDirectoryTypeAttribute,
  TDirectoryItem,
  TDirectoryTypeItem,
  TTableData,
  TTableView,
  TRefQuery,
} from 'src/store/directories/types';

import { agent } from '../agent';

import { mapData } from './serializers/common-serializers';
import {
  serializeDataForRequest,
  serializeFormForRequest,
  serializeValidationList,
  serializeYearCopyDataForRequest,
} from './serializers/serialize-form-for-request';
import { TFieldItem, TFormRaw } from './types';

export const fetchDirectoryFormView = async (
  rootStore: RootStore,
  view: string
): Promise<{ formView: TFormRaw; formInstance: FormStore }> => {
  try {
    const { data } = await agent.get<TFormRaw>(`view/GOlibrary.Form.${view}`);

    const formInstance = mapData(data, rootStore);

    return {
      formView: data,
      formInstance,
    };
  } catch (e) {
    throwApiError(e);
  }
};

export const saveChanges = async (data: TDirectoryItem[], type: string) => {
  const serializedData = serializeDataForRequest(data);

  try {
    await agent.post(`/object/list/${type}`, serializedData);
  } catch (error) {
    throwApiError(error);
  }
};

export const saveYearChanges = async (
  data: TDirectoryItem[],
  idsList: (string | number)[],
  type: string,
  year: string
) => {
  const serializedData = serializeYearCopyDataForRequest(data, idsList, year);

  try {
    await agent.post(`/object/list/${type}`, serializedData);
  } catch (error) {
    throwApiError(error);
  }
};

export const sendForm = async (groups: Group[], type: string) => {
  const serializedForm = serializeFormForRequest(groups);
  try {
    await agent.post(`/object/new/${type}`, serializedForm);
  } catch (error) {
    throwApiError(error);
  }
};

export const updateDirectoryValue = async (groups: Group[], type: string, id: string | number): Promise<void> => {
  const serializedForm = serializeFormForRequest(groups);
  try {
    await agent.post(`object/${type}/${id}`, serializedForm);
  } catch (error) {
    throwApiError(error);
  }
};

export const getTypesList = async (linkedTo?: string): Promise<TDirectoryTypeItem[]> => {
  const res = await agent.get<TDirectoryTypeItem[]>(
    'type/list',
    linkedTo
      ? {
          params: {
            linkedTo: linkedTo,
          },
        }
      : undefined
  );
  return res.data;
};

export const getTableData = async (data: TRefQuery): Promise<Record<string, TTableData | TTableData[]>[]> => {
  const res = await agent.post('object/select', data);
  return res.data;
};

export const getTableView = async (type: string): Promise<TTableView> => {
  try {
    const res = await agent.get(`view/GOlibrary.Table.${type}`);
    return res.data;
  } catch (e) {
    throwApiError(e);
  }
};

export const changeObjectStatus = async (type: string, id: number, status: string): Promise<void> => {
  await agent.patch(`object/${type}/${id}/status`, status);
};

export const fetchDirectoryAttributes = async (type: string): Promise<TDirectoryTypeAttribute[]> => {
  const res = await agent.get<TDirectoryTypeAttribute[]>(`type/${type}`);

  return res.data;
};

export const fetchDirectoryAttributesList = async (
  typesList: TDirectoryTypeItem[]
): Promise<TDirectoryTypeAttribute[][]> => {
  return await Promise.all(typesList.map((type) => fetchDirectoryAttributes(type.name)));
};

export const fetchDirectory = async (type: string): Promise<TDirectoryItem[]> => {
  try {
    const res = await agent.get<TDirectoryItem[]>(`object/list/${type}`);
    return res.data;
  } catch (e) {
    throwApiError(e);
  }
};

export async function fetchJoinedDirectory(
  refQuery: TRefQuery | TRefQuery<Record<string, string | number | boolean | null>>
): Promise<Record<string, string | number | boolean>[]> {
  try {
    const res = await agent.post<TRefQuery, AxiosResponse<{ [key: string]: string | number | boolean }[]>>(
      'object/select?flatResponse=true',
      refQuery
    );
    return res.data;
  } catch (error) {
    throwApiError(error);
  }
}

export const fetchObjectValidation = async ({
  type,
  draftData,
  objectData,
  editingDirectoryId,
}: {
  type: string;
  draftData: TDirectoryItem[];
  objectData: Record<string, TFieldItem>;
  editingDirectoryId: number | string | null;
}): Promise<void> => {
  const list = serializeValidationList({
    draftData,
    editingDirectoryId,
  });

  try {
    await agent.post(`object/${type}/validate`, {
      list,
      object: {
        ...(hasValue(editingDirectoryId) && { id: editingDirectoryId }),
        data: objectData,
      },
    });
  } catch (error) {
    throwApiError(error);
  }
};

type TGetFiltersDataOptions = {
  includeInactive?: boolean;
  includeFields?: string[];
};

export const getFiltersData = async (
  directory: string,
  options?: TGetFiltersDataOptions
): Promise<Record<string, unknown[]>> => {
  const processedOptions = options
    ? {
        ...options,
        ...(options.includeFields && { includeFields: options.includeFields.join(',') }),
      }
    : undefined;

  try {
    const { data } = await agent.get<Record<string, unknown[]>>(`object/uniqueFieldValues/${directory}`, {
      params: processedOptions,
    });
    return data;
  } catch (error) {
    throwApiError(error);
  }
};
