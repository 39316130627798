import { observer } from 'mobx-react-lite';
import moment from 'moment';

import { EllipsisTableCell } from 'src/shared/components/table-cell/ellipsis-table-cell';
import { DATE_FORMAT } from 'src/shared/constants/date';

import styles from './date-table-cell.module.scss';

interface Props {
  editing?: boolean;
  fieldValue?: string;
  currentRowIndex: number;
  column: string;
  onEditCell: (currentRowIndex: number, column: string, value: string | number) => void;
  defaultActive?: boolean;
}
export const DateTableCell = observer(function EditableTextField({ fieldValue }: Props) {
  return (
    <div className={styles.wrapper}>
      <EllipsisTableCell value={fieldValue ? moment(Number(fieldValue) * 1000).format(DATE_FORMAT) : '-'} />
    </div>
  );
});
