import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { ReactComponent as DeleteIcon } from 'src/shared/assets/icons/cross-mini.svg';
import { IconButton } from 'src/shared/components/icon-button';

import styles from './collapse-item.module.scss';

type Props = {
  showButtons: boolean;
  value: string | number;
  isLast: boolean;
  onDelete(item: string | number): void;
};

export const CollapseItem = observer(function CollapseItem({ value, isLast, showButtons, onDelete }: Props) {
  const handleDelete = () => {
    onDelete(value);
  };

  return (
    <div className={clsx(styles.wrapper, isLast && styles.lastItem)}>
      <p className={styles.valueText}>{value}</p>
      {showButtons && (
        <IconButton
          className={styles.deleteButton}
          onClick={handleDelete}
          icon={<DeleteIcon stroke="var(--red-color)" />}
        />
      )}
    </div>
  );
});
