import { IntegerField, NumberField } from '../entities/control-entities';
import { INumberRestrictions } from '../types';

import { Validation } from './validation';

export class NumberValidation extends Validation<number | null> {
  readonly restrictions: INumberRestrictions;

  constructor(control: NumberField | IntegerField, restrictions: INumberRestrictions) {
    super(control);
    this.restrictions = restrictions;
  }

  validate(value: number | null): string | [string, { [locKey: string]: string | number }] | undefined {
    if (value === null || isNaN(value)) {
      if (this.restrictions.required) return 'directory:Errors.required';
      return;
    }

    if (this.restrictions?.higherThan !== undefined) {
      if (value > this.restrictions.higherThan) {
        return;
      }

      return ['directory:Errors.moreThan', { minimum: this.restrictions.higherThan }];
    }

    if (this.restrictions.min) {
      const minValue: number | null = this.processMinMaxRestrictrions(
        this.restrictions.min,
        (left, right) => left <= right
      );

      if (minValue !== null && value < minValue) {
        return ['directory:Errors.moreThan', { minimum: minValue }];
      }
    }

    if (this.restrictions.max) {
      const maxValue: number | null = this.processMinMaxRestrictrions(
        this.restrictions.max,
        (left, right) => left >= right
      );

      if (maxValue !== null && value > maxValue) {
        return ['directory:Errors.lessThan', { maximum: maxValue }];
      }
    }
  }
}
