export function hasValue<T>(value: T | null | undefined): value is T {
  if (Array.isArray(value)) {
    return value.length !== 0;
  }
  return value !== null && value !== undefined;
}

export function omit<T, K extends keyof T>(obj: T, key?: K) {
  if (key) {
    const { [key]: omitted, ...rest } = obj;
    return rest;
  }
  return obj;
}
