import { action, computed, makeObservable, observable } from 'mobx';

import { TComboboxFilter, TRequiredIf } from 'src/api/directory/types';
import { hasValue } from 'src/shared/lib/common';
import { joinsToOptions } from 'src/shared/utils/process-joins-to-options';
import { Directories } from 'src/store/directories/directories.store';
import {
  TComboboxOptionsSorting,
  TDirectoryItem,
  TOption,
  TRefQuery,
  TRefQueryVariable,
} from 'src/store/directories/types';

import { IRestrictions } from '../../types';
import { ValidatableItem } from '../abstract-entities';
import { TControlValueType, TEnableIf } from '../types';

type TMultiComboboxData = {
  formElementRefId: string;
  directories: Directories;
  objectName?: string;
  defaultValueLink?: string;
  refObjectType?: string;
  refObjectAttr?: string;
  restrictions: IRestrictions;
  requiredIf?: TRequiredIf[];
  enableIf?: TEnableIf[];
  type: TControlValueType;
  refQuery?: TRefQuery;
  refQueryVariables?: TRefQueryVariable[];
  directory?: Record<string, string | number | boolean>[] | null;
  filterByControlValue?: TComboboxFilter;
  optionsSorting?: TComboboxOptionsSorting;
};

export class MultiCombobox extends ValidatableItem<(string | number)[] | null> {
  @observable valuesArray: (string | number)[] = [];
  @observable isFormSidebarOpened: boolean = false;
  @observable private _options: TOption[];

  readonly optionsSorting?: TComboboxOptionsSorting;
  readonly refQuery?: TRefQuery;
  readonly filterByControlValue?: TComboboxFilter;
  readonly refQueryVariables?: TRefQueryVariable[];
  readonly refObjectAttr?: string;
  readonly refObjectType?: string;
  readonly directories: Directories;

  constructor(data: TMultiComboboxData) {
    super(data);
    this.refQuery = data.refQuery;
    this.refQueryVariables = data.refQueryVariables;
    this.refObjectAttr = data.refObjectAttr;
    this.refObjectType = data.refObjectType;
    this.directories = data.directories;
    this.optionsSorting = data.optionsSorting;
    this.filterByControlValue = data.filterByControlValue;
    this._options = this.processOptionsDataToOptions(
      data.directories.getDirectory(this.refObjectType),
      this.refObjectAttr
    );

    makeObservable(this);
  }

  get value(): (string | number)[] | null {
    return !!this.valuesArray?.length ? this.valuesArray : null;
  }

  @computed
  get options(): TOption[] {
    const options = this._options;

    if (this.optionsSorting) {
      return options.sort((a, b) => {
        const aLabel = a.label.toLowerCase();
        const bLabel = b.label.toLowerCase();

        if (this.optionsSorting === 'ASC') {
          return aLabel.localeCompare(bLabel);
        }

        return bLabel.localeCompare(aLabel);
      });
    }

    return options;
  }

  @action.bound
  setOptions(options: TOption[]): void {
    this._options = options;
  }

  @action.bound
  resetDefaultOptions(): void {
    this._options = this.processOptionsDataToOptions(
      this.directories.getDirectory(this.refObjectType),
      this.refObjectAttr
    );
  }

  @action.bound
  setOptionsByJoinResponse(joinRes: Record<string, string | number | boolean>[]): void {
    this._options = joinsToOptions(this, joinRes);
  }

  private processOptionsDataToOptions(data: TDirectoryItem[], refObjectAttr: string | undefined): TOption[] {
    if (!refObjectAttr) return [];

    const options: TOption[] = [];

    for (const item of data) {
      const label = item.data[refObjectAttr];

      if (
        typeof label !== 'string' &&
        (typeof label !== 'number' || (typeof label === 'number' && Number.isNaN(Number(label))))
      ) {
        continue;
      }

      options.push({
        label: label.toString(),
        value: item.id,
      });
    }

    return options;
  }

  @action.bound
  openNewFormSidebar(): void {
    this.isFormSidebarOpened = true;
  }

  @action.bound
  closeNewFormSidebar(): void {
    this.isFormSidebarOpened = false;
  }

  @action.bound
  setValue(value: (string | number)[] | null | undefined): void {
    if (!hasValue(value)) {
      this.valuesArray = [];
      return;
    }
    this.valuesArray = value;
  }

  @action.bound
  resetControl(): void {
    this.valuesArray = [];
    this.clearError();
  }

  checkIsReady(): boolean {
    if (!!this.errorText) return false;
    return true;
  }

  @action.bound
  clearError(): void {
    this.errorText = undefined;
  }

  @action.bound
  validate(): void {
    if (!this.valuesArray.length && this.restrictions?.required) {
      this.errorText = 'directory:Errors.required';
    }
  }
}
