import { action, computed, flow, makeObservable, observable } from 'mobx';
import { v4 as uuid } from 'uuid';

import { sendForm, fetchDirectoryFormView, fetchObjectValidation } from 'src/api/directory/requests';
import { serializeFormForRequest } from 'src/api/directory/serializers/serialize-form-for-request';
import { TFormRaw } from 'src/api/directory/types';
import { storage } from 'src/api/storage';
import { ValidationError } from 'src/errors';
import { DirectoryStore } from 'src/features/directory/store/directory';
import { Control } from 'src/features/directory-form/entities/abstract-entities';
import { RegularComboBox } from 'src/features/directory-form/entities/control-entities';
import { FormStore } from 'src/features/directory-form/entities/form.entity';
import { DirectoryPageStore } from 'src/pages/directory/directory.store';
import { LOCAL_STORAGE } from 'src/shared/constants/local-storage';
import { getFormPluginsConstructors } from 'src/shared/utils/get-form-plugins-classes';
import { serializeFormDataIntoTableRowData } from 'src/shared/utils/serialize-form-data-into-table-row-data';
import { RootStore } from 'src/store';
import { Directories } from 'src/store/directories/directories.store';
import { TDirectoryItem } from 'src/store/directories/types';
import { I18NextStore } from 'src/store/i18next/i18next-store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

import { JoinComboBoxItem } from '../directory-form/entities/control-entities/join-combobox.entity';
import { MultiCombobox } from '../directory-form/entities/control-entities/multicombobox.entity';
import { PluginsManager } from '../directory-form/form-plugins/plugins-manager';

export class FormSidebarStore {
  readonly directories: Directories;
  readonly directoryTable: DirectoryStore;
  readonly i18n: I18NextStore;
  readonly notifications: NotificationsStore;
  readonly directoryName?: string;
  readonly pageStore: DirectoryPageStore;
  private readonly rootStore: RootStore;

  @observable editingDirectoryId: string | number | null = null;
  @observable form: FormStore | null = null;
  @observable formPluginsManager: PluginsManager | null = null;
  @observable isFormSent: boolean = false;

  constructor(rootStore: RootStore, pageStore: DirectoryPageStore, directoryName?: string) {
    this.directories = rootStore.directories;
    this.rootStore = rootStore;
    this.directoryTable = pageStore.directory;
    this.pageStore = pageStore;

    this.notifications = rootStore.notifications;
    this.i18n = rootStore.i18;
    this.directoryName = directoryName;

    makeObservable(this);
  }

  @action.bound
  private initForm(formView: TFormRaw, form: FormStore): void {
    this.form = form;
    const plugins = getFormPluginsConstructors(formView, form, this.rootStore);
    this.formPluginsManager = new PluginsManager(this.form, this.rootStore, plugins);
  }

  @computed
  get childSidebarObjectType(): string | undefined {
    let childSidebarRefObjectType: string | undefined;

    const getRecursiveSidebarObjectType = (item: Control) => {
      if (item instanceof RegularComboBox || item instanceof MultiCombobox || item instanceof JoinComboBoxItem) {
        if (item.isFormSidebarOpened) {
          if (item.refObjectType) {
            childSidebarRefObjectType = item.refObjectType;
          }
        }
      }
    };

    this.form?.processFormFields(getRecursiveSidebarObjectType);

    return childSidebarRefObjectType;
  }

  @action.bound
  closeChildSidebar() {
    const closeChildSidebar = (item: Control) => {
      if (item instanceof RegularComboBox || item instanceof MultiCombobox || item instanceof JoinComboBoxItem) {
        if (item.isFormSidebarOpened) {
          item.closeNewFormSidebar();
        }
      }
    };

    this.form?.processFormFields(closeChildSidebar);
  }

  @action.bound
  setEditingDirectory(editingDirectory: TDirectoryItem | null) {
    if (!this.form) return;
    if (editingDirectory && typeof editingDirectory['id'] !== 'boolean') {
      this.form.resetForm();
      this.editingDirectoryId = editingDirectory['id'] ?? null;
      this.form.setExistingDirectoryValues(editingDirectory.data);
    } else {
      this.editingDirectoryId = null;
      this.form.resetForm();
    }
  }

  @action.bound
  setCopyingDirectory(copyingDirectory: TDirectoryItem | null) {
    if (!this.form) return;
    if (copyingDirectory && typeof copyingDirectory['id'] !== 'boolean') {
      this.form.resetForm();
      this.form.setExistingDirectoryValues(copyingDirectory.data);
    } else {
      this.form.resetForm();
    }
  }

  @action.bound
  resetSidebar() {
    this.form?.resetForm();
    this.editingDirectoryId = null;
    this.isFormSent = false;
  }

  @flow.bound
  async *sendForm(type: string, isRecursive: boolean) {
    if (!this.form) return;

    try {
      if (isRecursive) {
        await sendForm(this.form.groups, type);
        yield;
      } else {
        const newDirectoryItemDraft = serializeFormForRequest(this.form.groups);

        await fetchObjectValidation({
          type,
          draftData: this.pageStore.draftDirectory,
          objectData: newDirectoryItemDraft,
          editingDirectoryId: this.editingDirectoryId,
        });

        const serializedData = serializeFormDataIntoTableRowData(newDirectoryItemDraft, this.directories, type);

        if (!this.editingDirectoryId) {
          const newId = `local-${uuid()}`;
          newDirectoryItemDraft.id = newId;
          serializedData.id = newId;

          this.directoryTable.table.addDraftTableData(serializedData);
        } else {
          newDirectoryItemDraft.id = this.editingDirectoryId;
          serializedData.id = this.editingDirectoryId;
          serializedData.status = true;

          this.directoryTable.table.updateDraftTableData(this.editingDirectoryId, serializedData);
        }

        const { id, ...rest } = newDirectoryItemDraft;

        if (!!id && (typeof id === 'string' || typeof id === 'number')) {
          const newDirectoryItem: TDirectoryItem = {
            id: id,
            data: rest,
            status: 'ACTIVE',
            objectType: type,
          };

          this.pageStore.setDraftDirectory(newDirectoryItem);

          storage.SET(LOCAL_STORAGE.draftTableData, this.directoryTable.table.draftTableData);
          storage.SET(LOCAL_STORAGE.draftDirectoryData, this.pageStore.draftDirectory);
          storage.SET(LOCAL_STORAGE.lastDirectoryType, type);
        }
      }

      this.form.resetForm();
      isRecursive && this.directories.refreshDirectory(type);
      this.isFormSent = true;
    } catch (error) {
      yield;
      console.error(error);

      if (error instanceof ValidationError && !!error.violations && type) {
        const errorsForPopup: string[] = [];
        for (const violation of error.violations) {
          const errorString = `${this.directories.getAttribute(`${type}.${violation.attrName}`)?.defaultLabel}: ${
            violation.message
          }`;
          errorsForPopup.push(errorString);
        }
        this.form.setServerErrors(error.violations);
        errorsForPopup.forEach((errorString) => this.notifications.showNotificationMessage(errorString));
      }
    }
  }

  @flow.bound
  async *fetchDirectoryView(view: string) {
    try {
      const { formInstance, formView } = await fetchDirectoryFormView(this.rootStore, view);
      yield;

      this.initForm(formView, formInstance);
    } catch (error) {
      yield;
      console.error(error);
      this.notifications.showNotificationMessage(this.i18n.t('directory:Errors.fetchView'));
    }
  }

  effect = () => {
    return () => {
      this.formPluginsManager?.disposePlugins();
    };
  };
}
