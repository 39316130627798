import { observer } from 'mobx-react-lite';
import { ChangeEvent } from 'react';

import { FormInput } from 'src/shared/components/form-components';

import { TextField } from '../../entities/control-entities';

type Props = {
  className?: string;
  label?: string;
  item: TextField;
  isDisabled: boolean;
};

export const TextFormField = observer(function TextFormField({ className, label, item, isDisabled }: Props) {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    item.setValue(event.target.value);
  };

  return (
    <FormInput
      value={item.value}
      label={label}
      className={className}
      isError={!!item.errorText}
      errorText={item.errorText}
      placeholder={item.placeholder}
      isDisabled={isDisabled}
      onChange={onChange}
      onFocus={item.clearError}
      onBlur={item.validate}
    />
  );
});
