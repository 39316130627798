import { makeObservable, observable, action } from 'mobx';

import { TRequiredIf } from 'src/api/directory/types';

import { INumberRestrictions } from '../../types';
import { NumberValidation } from '../../validation/number-validation';
import { Field } from '../abstract-entities';
import { TControlValueType, TEnableIf } from '../types';

export type TNumberFieldData = {
  formElementRefId: string;
  fieldId?: string;
  objectName?: string;
  unit?: string;
  defaultValueLink?: string;
  enableIf?: TEnableIf[];
  restrictions: INumberRestrictions;
  requiredIf?: TRequiredIf[];
  initialValue?: number;
  type: TControlValueType;
};

export class NumberField extends Field<number | null> {
  @observable value: number | null;
  readonly validation: NumberValidation;

  constructor(data: TNumberFieldData) {
    super(data);
    this.value = data.initialValue || null;
    this.validation = new NumberValidation(this, data.restrictions);

    if (this.value !== null) this.validate();
    makeObservable(this);
  }

  checkIsReady(): boolean {
    if (!!this.errorText) return false;
    return this.value !== null;
  }

  @action.bound
  clearError(): void {
    this.errorText = undefined;
  }

  @action.bound
  validate = () => {
    const errorText = this.validation.validate(this.value);
    if (!!errorText) this.setError(errorText);
  };

  @action
  setValue(value: number | null) {
    if (value === null) {
      this.value = null;
      return;
    }
    this.value = value;
  }

  @action.bound
  resetControl(): void {
    this.value = null;
    this.clearError();
  }
}
