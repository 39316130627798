import { i18n as Ii18 } from 'i18next';
import { TFunction } from 'react-i18next';

export class I18NextStore {
  readonly t: TFunction;

  constructor(i18: Ii18) {
    this.t = i18.t;
  }
}
