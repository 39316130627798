import clsx from 'clsx';
import CSS from 'csstype';
import { observer } from 'mobx-react-lite';
import { Children, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowDownIcon } from 'src/shared/assets/icons/arrow-down.svg';
import { ReactComponent as EllipsisIcon } from 'src/shared/assets/icons/ellipsis.svg';
import { ReactComponent as FilterIcon } from 'src/shared/assets/icons/filter.svg';
import { Checkbox } from 'src/shared/components/checkbox';
import { Tooltip } from 'src/shared/components/tooltip';
import { useOutsideClick } from 'src/shared/hooks/use-outside-click';
import { hasValue } from 'src/shared/lib/common';
import { TOrder } from 'src/store/directories/types';
import { ColumnType, TFilters, TSorting, TTableFilter, TTableSettings } from 'src/store/table/types';

import styles from './resizable-table-title.module.scss';
import { SelectFilter } from './select-filter';
import { getSortingKeyByColumnName } from './utils';

export interface Props {
  children: ReactNode;
  width: number;
  onResizeStart: (columnName: string, columnWidth: number, minColumnWidth: number, startCoordinates: number) => void;
  onResizeStop: () => void;
  minColumnWidth: number;
  setXCoordinates: (coordinates: number) => void;
  columnName: string;
  filterValues: TFilters;
  onTableFiltersChanged: (attr: string, values: (string | number)[], isArray: boolean) => TTableFilter[];
  onTableSortingChanged: (columnName: string, value: TSorting) => void;
  setSelectedRows: (nums: Record<string, boolean>) => void;
  onTableFetch: (settings: TTableSettings) => void;
  order?: TOrder;
  filters?: TTableFilter[];
  selectedRows?: Record<string, boolean>;
  isResizable?: boolean;
  isSortable?: boolean;
  leftOffset: number;
  style?: CSS.Properties;
  isParent?: boolean;
  isChild?: boolean;
  isCopyMode?: boolean;
  columnType?: 'string' | 'date' | 'select' | 'progress';
  unit?: string;
  className: string;
  setTableHeaderHeight?: (height: number) => void;
  setColumns?: (columnsData: ColumnType[]) => void;
  columns?: ColumnType[];
}

export const ResizableTableTitle = observer(function ResizableTitle({
  width,
  onResizeStart,
  onResizeStop,
  children,
  setXCoordinates,
  minColumnWidth,
  columnName,
  unit,
  isResizable,
  isSortable,
  leftOffset,
  style,
  isParent,
  className,
  isChild,
  onTableSortingChanged,
  order,
  onTableFiltersChanged,
  setTableHeaderHeight,
  columnType,
  setColumns,
  filterValues,
  filters,
  columns,
  onTableFetch,
  selectedRows,
  setSelectedRows,
  isCopyMode,
  ...rest
}: Props) {
  const [sorting, setSorting] = useState<TSorting>('DEFAULT');
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);
  const refRight = useRef<HTMLDivElement>(null);
  const headerCellRef = useRef<HTMLTableHeaderCellElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useOutsideClick(containerRef, () => setOpen(false), [buttonRef]);

  const onSortingChanged = (newSorting: TSorting) => {
    const value = sorting === newSorting ? 'DEFAULT' : newSorting;

    setSorting(value);

    if (columnName) {
      onTableSortingChanged(columnName, value);
    }
  };

  const sortingKey = useMemo(
    () => (columns ? getSortingKeyByColumnName(columns, columnName) : undefined),
    [columns, columnName]
  );

  const getButtonStyle = () => {
    if (!sortingKey) {
      return styles.optionButton;
    }

    const [type, attr] = sortingKey.split('.');

    const isFiltered = filters?.find((filter) => filter.attr === columnName);
    const isSorting = attr === order?.attr && order.direction !== 'DEFAULT' && type === order.joinedAlias;

    return clsx(styles.optionButton, {
      [styles.optionButtonActive]: isSorting || !!isFiltered,
      [styles.optionButtonSorting]: isSorting,
      [styles.optionButtonFiltered]: isFiltered,
    });
  };

  useEffect(() => {
    let prevScrollLeft = 0;

    const handleScroll = (e: Event) => {
      const target = e.target;

      if (!target || !('scrollLeft' in target)) {
        return;
      }

      const { scrollLeft } = target;

      if (prevScrollLeft !== scrollLeft) {
        setOpen(false);
      }

      prevScrollLeft = scrollLeft as number;
    };

    document.addEventListener('scroll', handleScroll, { capture: true });

    return () => {
      document.removeEventListener('scroll', handleScroll, { capture: true });
    };
  }, []);

  function getFilterComponent(): React.ReactNode {
    if (isSortable && !isParent) {
      return (
        <Tooltip
          trigger="click"
          visible={isOpen}
          text={
            <SelectFilter
              onSortingChanged={onSortingChanged}
              filters={filterValues[columnName]}
              onTableFiltersChanged={onTableFiltersChanged}
              attr={columnName}
              order={order}
              sorting={sorting}
              sortingKey={sortingKey}
              containerRef={containerRef}
            />
          }
          placement="bottom"
          overlayInnerStyle={{ width: 'fit-content' }}
        >
          <button className={getButtonStyle()} onClick={() => setOpen(true)} ref={buttonRef}>
            <FilterIcon className={styles.filterIcon} />
            <ArrowDownIcon className={clsx(styles.arrowIcon, order?.direction === 'ASC' && styles.arrowIconAsc)} />
            <EllipsisIcon />
          </button>
        </Tooltip>
      );
    }

    if (columnName === 'settingsColumn' && selectedRows && isCopyMode) {
      const rowsId = Object.keys(selectedRows);
      const isChecked = Object.values(selectedRows).every((item) => item);

      const selectedRowEntries = Object.fromEntries(rowsId.map((key) => [key, !isChecked]));

      return (
        <Checkbox
          onChange={() => setSelectedRows(selectedRowEntries)}
          isChecked={isChecked}
          className={styles.settingsCheckboxWrapper}
        />
      );
    }

    return null;
  }

  useEffect(() => {
    const refRightCurrent = refRight.current;
    if (refRightCurrent) {
      const onMouseDownRightResize = (event: PointerEvent) => {
        if (!event.isPrimary) {
          return;
        }

        onResizeStart(columnName, width, minColumnWidth, event.clientX);

        document.addEventListener('pointermove', onMouseMoveRightResize);
        document.addEventListener('pointerup', onMouseUpRightResize);
      };

      const onMouseMoveRightResize = (event: PointerEvent) => {
        setXCoordinates(event.clientX);
      };

      const onMouseUpRightResize = () => {
        onResizeStop();
        document.removeEventListener('pointermove', onMouseMoveRightResize);
      };

      refRightCurrent.addEventListener('pointerdown', onMouseDownRightResize);

      return () => {
        refRightCurrent?.removeEventListener('pointerdown', onMouseDownRightResize);
      };
    }
  }, [minColumnWidth, columnName, onResizeStart, onResizeStop, setXCoordinates, width]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      // использовано для избежания ошибки 'ResizeObserver loop completed with undelivered notifications.' https://stackoverflow.com/a/58701523
      window.requestAnimationFrame(() => {
        headerCellRef.current &&
          setTableHeaderHeight &&
          setTableHeaderHeight(headerCellRef.current.getBoundingClientRect().height);
      });
    });

    if (headerCellRef.current) {
      resizeObserver.observe(headerCellRef.current);
    }

    return () => resizeObserver.disconnect();
  }, [setTableHeaderHeight]);

  return (
    <th
      {...rest}
      style={{ ...style, ...(hasValue(leftOffset) && { left: leftOffset }) }}
      className={clsx(className, isParent && 'ParentColumn')}
      ref={headerCellRef}
    >
      <div
        className={clsx(
          styles.titleContainer,
          isChild && styles.titleContainerChild,
          columnName === 'select' && styles.titleContainerSelect
        )}
      >
        <p className={clsx(styles.title, isChild && styles.titleChild)}>
          {Children.map(children, (child) => (typeof child === 'string' ? t(child) : undefined))}
        </p>
        {getFilterComponent()}
      </div>
      <p className={styles.unit}>{unit}</p>
      {isResizable && (
        <div ref={refRight} className={clsx(styles.resizeHandler, isParent && styles.resizeHandlerDouble)} />
      )}
    </th>
  );
});
