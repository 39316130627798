import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowIcon } from 'src/shared/assets/icons/arrow-with-tail.svg';

import { ColumnHeader } from '../components/column-header';
import { MatchingRow } from '../components/matching-row';
import { MatchingImportStore } from '../import-matching-page.store';

import styles from './matching-column.module.scss';

type Props = {
  matchingImport: MatchingImportStore;
};

export const MatchingColumn = observer(function MatchingColumn({ matchingImport }: Props) {
  const { t } = useTranslation('matching');

  return (
    <div className={styles.wrapper}>
      <div className={styles.headersWrapper}>
        <ColumnHeader className={styles.systemItem} title={t('columns.system')}>
          <ArrowIcon className={styles.arrow} />
        </ColumnHeader>
        <ColumnHeader className={styles.userItem} title={t('columns.user')} />
      </div>
      <div className={styles.contentWrapper}>
        {matchingImport.data.map((item) => (
          <MatchingRow key={item.name} item={item} matchingImport={matchingImport} />
        ))}
      </div>
    </div>
  );
});
