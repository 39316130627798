import { useDndContext } from '@dnd-kit/core';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

import { UnmountWithTransition } from 'src/shared/components/core/unmount-with-transition/unmount-with-transition';

import styles from './matching-item.module.scss';

type Props = {
  isHovered?: boolean;
  isEmpty?: boolean;
  isRequired: boolean;
  className?: string;
  name: string;
  icon?: ReactNode;
};

export const MatchingItem = observer(function MatchingItem({
  isHovered,
  isEmpty,
  isRequired,
  className,
  name,
  icon,
}: Props) {
  const { active } = useDndContext();

  return (
    <div
      className={clsx(
        styles.wrapper,
        isEmpty && !isHovered && isRequired && styles.wrapper__empty,
        !!className && className
      )}
    >
      <UnmountWithTransition
        mountDelay={100}
        isShown={!isHovered}
        mountStylesObj={{ opacity: 1 }}
        unmountStylesObj={{ opacity: 0 }}
      >
        <p
          className={clsx(
            styles.text,
            isEmpty && (isRequired ? styles.text__emptyRequired : styles.text__emptyNonRequired)
          )}
        >
          {name}
        </p>
      </UnmountWithTransition>
      <UnmountWithTransition
        mountDelay={100}
        className={styles.newTextWrapper}
        isShown={!!isHovered}
        mountStylesObj={{ opacity: 1 }}
        unmountStylesObj={{ opacity: 0 }}
      >
        <p className={clsx(styles.text, styles.text__hovered)}>{active?.data.current?.header?.title}</p>
      </UnmountWithTransition>
      {icon}
    </div>
  );
});
