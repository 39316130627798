import { action, flow, makeObservable, observable } from 'mobx';

import { fetchDirectoryFormView } from 'src/api/directory/requests';
import { TFieldItem } from 'src/api/directory/types';
import { matchExcelHeaders, parseExcelData } from 'src/api/import';
import { Validation400ApiErrorWithCause } from 'src/errors';
import { ValidatableItem } from 'src/features/directory-form/entities/abstract-entities';
import { FormStore } from 'src/features/directory-form/entities/form.entity';
import { ErrorWithTranslation } from 'src/shared/utils/error-with-translation';
import { RootStore } from 'src/store';
import { Directories } from 'src/store/directories/directories.store';
import { I18NextStore } from 'src/store/i18next/i18next-store';
import { ImportStore } from 'src/store/import/import-store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';
import { RouterStore } from 'src/store/router/router-store';

export class MatchingImportStore {
  private readonly i18: I18NextStore;
  private readonly importStore: ImportStore;
  private readonly notifications: NotificationsStore;
  private readonly router: RouterStore;
  private readonly fileId: number;
  private readonly sheetName: string;
  private readonly directories: Directories;
  private readonly rootStore: RootStore;
  private formView?: FormStore;
  readonly directoryName: string;

  @observable isLoading: boolean = false;
  @observable unmatchedHeaders: HeaderType[] = [];
  @observable data: TMatchingItemType[] = [];
  @observable conflicts: TConflict[] = [];
  @observable errorMessage?: string;

  constructor(rootStore: RootStore, fileId: number, sheetName: string, directoryName: string) {
    this.fileId = fileId;
    this.sheetName = sheetName;
    this.directoryName = directoryName;
    this.importStore = rootStore.importStore;
    this.i18 = rootStore.i18;
    this.rootStore = rootStore;
    this.router = rootStore.router;
    this.notifications = rootStore.notifications;
    this.directories = rootStore.directories;

    makeObservable(this);
  }

  private async init() {
    this.isLoading = true;

    try {
      await Promise.all([this.matchExcelHeaders(), this.loadView()]);
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  effect() {
    this.init();
  }

  @action.bound
  addUnmatchedHeader(header: HeaderType): void {
    this.unmatchedHeaders.push(header);
  }

  @action.bound
  removeUnmatchedHeader(value: HeaderType): void {
    this.unmatchedHeaders = this.unmatchedHeaders.filter((item) => item.columnIndex !== value.columnIndex);
  }

  @action.bound
  addDataItem(attribute: string, header: HeaderType): void {
    this.data = this.data.map((item) => {
      if (item.name === attribute) {
        return { ...item, value: header };
      }

      return item;
    });
  }

  @action.bound
  getDataItem(attribute: string): TMatchingItemType | null {
    return this.data.find((item) => item.name === attribute) || null;
  }

  @action.bound
  removeDataItem(attrName: string): void {
    this.data = this.data.map((item) => {
      if (item.name === attrName) {
        return { ...item, value: null };
      }

      return item;
    });
  }

  @action.bound
  setData(data: TMatchingItemType[]) {
    this.data = data;
  }

  @action.bound
  clearConflicts() {
    this.conflicts = [];
  }

  getIsItemRequired(item: TMatchingItemType): boolean {
    if (!this.formView) {
      return false;
    }

    const control = this.formView.fields[item.name];

    if (control instanceof ValidatableItem) {
      return !!control.restrictions?.required;
    }

    return false;
  }

  @flow.bound
  async *loadView() {
    try {
      const { formInstance } = await fetchDirectoryFormView(this.rootStore, this.directoryName);
      yield;

      this.formView = formInstance;
    } catch (e) {
      yield;

      console.error(e);
      this.notifications.showNotificationMessage(this.i18.t('drillingCarpet:UploadPlanModal.uploadError'));
    }
  }

  @flow.bound
  async *matchExcelHeaders() {
    try {
      const match = await matchExcelHeaders(this.fileId, this.directoryName, this.sheetName);

      yield;

      this.unmatchedHeaders = match.unmatchedHeaders;
      this.data = [...match.matched, ...match.unmatchedAttrs];
    } catch (error) {
      yield;
      console.error(error);

      if (error instanceof ErrorWithTranslation) {
        this.notifications.showNotificationMessage(error.translateError(this.i18.t));
        return;
      }
      this.notifications.showNotificationMessage(this.i18.t('drillingCarpet:UploadPlanModal.uploadError'));
    }
  }

  @flow.bound
  async *parseExcelData() {
    this.isLoading = true;

    try {
      const res = await parseExcelData(this.fileId, this.sheetName, this.directoryName, this.data);
      yield;

      this.importStore.setImportedDirectoryTableData(res.objects, this.directoryName);
      const errors = res.errors;
      const rows = res.rows;

      this.notifications.showNotificationMessage(
        this.i18.t('matching:SubheaderFormModule.importResult', { rows, errors })
      );
      this.router.push(`/dicts/${this.directoryName}?importMode=true`);
    } catch (error) {
      yield;
      console.error(error);
      if (error instanceof Validation400ApiErrorWithCause) {
        if (typeof error.reason === 'string') {
          this.notifications.showNotificationMessage(error.reason);
        }
        if (Array.isArray(error.reason)) {
          error.reason.forEach((errorReason) => {
            if ('message' in errorReason && 'attr') {
              const label = this.directories.getLabelByObjectTypeAndFieldId(
                `${this.directoryName}.${errorReason.attr}`
              );

              this.notifications.showNotificationMessage(`${label}: ${errorReason.message}`);
            }

            if (typeof errorReason === 'string') {
              this.notifications.showNotificationMessage(errorReason);
            }
          });
        }
        return;
      }

      this.notifications.showNotificationMessage(this.i18.t('matching:saveError'));
    } finally {
      this.isLoading = false;
    }
  }
}

export const validateData = (data: TData) => {
  for (let prop in data) {
    if (data[prop] === null) {
      const error = new ErrorWithTranslation();
      error.errorTranslation = 'matching:notAllDataIsFilledInError';
      throw error;
    }
  }
};

export type HeaderType = {
  columnIndex: number;
  title: string;
};

export type TData = Record<string, HeaderType | null>;

export type TConflict = {
  name: string;
  value: string | number;
  options: (string | number)[];
};

export const isValidConflicts = (res: any): res is TConflict[] => {
  if (Array.isArray(res)) return true;
  return false;
};

export type TMatchingItemType = {
  name: string;
  value: HeaderType | null;
};

export type MatchHeadersType = {
  fileId: number;
  unmatchedAttrs: TMatchingItemType[];
  unmatchedHeaders: HeaderType[];
  matched: TMatchingItemType[];
};

export type TDirectoryValueIdsList = {
  errors: string | number;
  rows: string | number;
  objects: Record<string, TFieldItem>[];
};

export type TTriple = {
  id: number;
  status: string;
  objectType: string;
  createdAt: number;
  data: {
    $validation?: {
      fieldId: string;
      message: string;
      rawValue: string | number | boolean;
      dataValue: null | string | number | boolean;
    }[];
    planVersionId: number;
    rigOperationId: number;
    wellPlacementId: number;
    geologicalTaskId: number;
  };
};
