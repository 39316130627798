import { observer } from 'mobx-react-lite';

import { FormNumberInput } from 'src/shared/components/form-components';

import { NumberField as Field } from '../../entities/control-entities';

type Props = {
  className?: string;
  label?: string;
  item: Field;
  isDisabled?: boolean;
  isInteger?: boolean;
};

export const NumberFormField = observer(function NumberFormField({
  className,
  label,
  item,
  isInteger,
  isDisabled,
}: Props) {
  const onChange = (value: number | null) => {
    item.setValue(value);
  };

  return (
    <FormNumberInput
      value={item.value}
      label={label}
      isInteger={isInteger}
      className={className}
      isError={!!item.errorText}
      errorText={item.errorText}
      placeholder={item.placeholder}
      unit={item.unit}
      isDisabled={isDisabled}
      onChange={onChange}
      onFocus={item.clearError}
      onBlur={item.validate}
    />
  );
});
