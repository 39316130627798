import { YearField } from 'src/features/directory-form/entities/control-entities/year-field.entity';
import { TDirectoryTypeAttribute } from 'src/store/directories/types';

import { TYearRaw } from '../types';

export const mapYearItem = (item: TYearRaw, attribute: TDirectoryTypeAttribute | undefined): YearField => {
  const dateProps = {
    formElementRefId: item.attrName,
    unit: attribute?.unit,
    fieldId: attribute?.name,
    enableIf: item.enableIf,
    label: attribute?.defaultLabel,
    showIf: item.showIf,
    defaultValueLink: item.defaultValueLink,
    requiredIf: item.requiredIf,
    restrictions: { required: !!attribute?.required, ...attribute?.restrictions },
    type: item.type,
  };

  return new YearField(dateProps);
};
