import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormInput } from 'src/shared/components/form-components';

import { StringsList } from '../../entities/control-entities/strings-list.entity';

import { Collapse } from './collapse/collapse';
import styles from './strings-list-control.module.scss';

type Props = {
  item: StringsList;
  label?: string;
};

export const StringsListControl = observer(function StringsListControl({ item, label }: Props) {
  const { t } = useTranslation();
  const [isCollapseOpened, setIsCollapseOpened] = useState(false);

  const { inputValue, errorText, addValue, setInputValue, deleteValue, validate } = item;

  const handleOpenCollapse = () => {
    setIsCollapseOpened(true);
  };

  const handleCloseCollapse = () => {
    setIsCollapseOpened(false);
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleBlur = () => {
    addValue();
    validate();
  };

  return (
    <div className={clsx(styles.wrapper, !!errorText && styles.wrapperError)}>
      <FormInput
        value={inputValue}
        label={label}
        placeholder={t('directory:Controls.enterValue')}
        isError={!!errorText}
        errorText={errorText}
        onChange={handleChangeInput}
        onPressEnter={handleBlur}
        onBlur={handleBlur}
      />
      <Collapse
        value={item.value}
        isOpened={isCollapseOpened}
        onClose={handleCloseCollapse}
        onOpen={handleOpenCollapse}
        onDelete={deleteValue}
      />
    </div>
  );
});
