export const safelyParseJSON = <T = unknown>(json: unknown): T | unknown => {
  if (typeof json !== 'string') {
    return json;
  }
  try {
    const value = JSON.parse(json);
    return value;
  } catch {
    return json;
  }
};
