import moment from 'moment';

import { checkIsNumberArray } from 'src/shared/utils/check-is-number-array';
import { checkIsStringArray } from 'src/shared/utils/check-is-string-arrayt';
import { isStringNumberOrBoolean } from 'src/shared/utils/is-string-number-or-boolean';
import { Directories } from 'src/store/directories/directories.store';
import {
  DictionaryStatus,
  TDirectoryItem,
  TDirectoryTypeAttribute,
  TTableColumnRaw,
  TTableData,
} from 'src/store/directories/types';
import { ColumnType, TableRow, TFilters, TFilterValue } from 'src/store/table/types';

type TValuePaths = Record<string, string>;

export const getFilterData = (
  data: Record<string, unknown[]>,
  columns: TTableColumnRaw[],
  directories: Directories,
  directory: string
): TFilters => {
  const directoriesValues: TFilters = {};
  const directoryAttributesMap = new Map<string, TDirectoryTypeAttribute>(
    directories.getTypeAttributes(directory).map((attr) => [attr.name, attr])
  );

  for (const fieldId in data) {
    const rawFilterValues = data[fieldId];
    const dirAttribute = directoryAttributesMap.get(fieldId);

    if (!dirAttribute?.refObjectType || !dirAttribute?.refObjectAttr) {
      const filterValues: TFilterValue[] = [];

      rawFilterValues.forEach((filterValue) => {
        if (isStringNumberOrBoolean(filterValue)) {
          if (dirAttribute?.type === 'DateTime' && typeof filterValue === 'number') {
            filterValues.push({
              label: moment.unix(filterValue).format('DD.MM.YYYY'),
              value: filterValue,
            });
          } else {
            filterValues.push({
              label: filterValue.toString(),
              value: typeof filterValue === 'boolean' ? filterValue.toString() : filterValue,
            });
          }
        }
      });

      directoriesValues[fieldId] = filterValues;
    } else {
      const filterValues: TFilterValue[] = [];
      const filterDirectories = directories.getDirectory(dirAttribute.refObjectType);
      const filterDirectoriesMap = new Map<number | string, TDirectoryItem>(filterDirectories.map((fd) => [fd.id, fd]));

      if (!filterDirectories) {
        continue;
      }

      for (const dirId of rawFilterValues) {
        if (typeof dirId !== 'number') {
          continue;
        }

        const directory = filterDirectoriesMap.get(dirId);

        if (!directory) {
          continue;
        }

        const dirValue = directory.data[dirAttribute.refObjectAttr];

        if (!isStringNumberOrBoolean(dirValue)) {
          continue;
        }

        filterValues.push({
          label: dirValue.toString(),
          value: dirId,
        });
      }

      directoriesValues[fieldId] = filterValues;
    }
  }

  return directoriesValues;
};

export const getParsedData = (
  data: Record<string, TTableData | TTableData[]>[],
  columns: TTableColumnRaw[],
  attributes: TDirectoryTypeAttribute[]
): TableRow[] => {
  const paths = getValuePaths(columns);
  const directoryName = (columns[0].attrName ?? columns[0].valuePath)?.split('.')[0];

  const newData = data.map((item) => {
    return Object.entries(paths).reduce<Record<string, string | number | boolean>>((acc, [key, path], index) => {
      const splittedPath = path.split('.');
      let [dir, attr, idAttr, valueAttr] = splittedPath;

      const attribute = attributes.find((item) => item.name === attr);
      const isNested = attribute && attribute.type === 'Object' && attribute.isArray && idAttr && valueAttr;
      const attrName = attr;

      if (isNested) {
        attr = `${attr}.${idAttr}.${valueAttr}`;
      }

      const currentDir = item[dir];
      const currentDirItem = directoryName ? item[directoryName] : void 0;

      if (!currentDir || !currentDirItem) return acc;

      if (!Array.isArray(currentDirItem)) {
        acc.id = currentDirItem.id;
        acc.status = currentDirItem.status === DictionaryStatus.active;
      }

      let value: string | number;
      if (isNested) {
        const attrWithId = attribute.attributes?.find((nestedAttr) => nestedAttr.isIdAttr);
        const attrWithValue = attribute.attributes?.find((nestedAttr) => nestedAttr.isValueAttr);

        if (attrWithId && attrWithValue && !Array.isArray(currentDir)) {
          const idKey = attrWithId.name;
          const valueKey = attrWithValue.name;
          const attrArr = currentDir.data[attrName];
          const newVal = Array.isArray(attrArr)
            ? attrArr?.find((item) => item[idKey] === Number(idAttr))?.[valueKey]
            : undefined;
          value = newVal ?? '-';
        } else {
          value = '-';
        }
      } else if (Array.isArray(currentDir)) {
        value = currentDir.map((nestedItem) => nestedItem.data[attr]).join(', ');
      } else if (attribute?.isArray && ['Number', 'String', 'Integer'].includes(attribute.type)) {
        const newVal = attrName === 'id' ? currentDir[attrName] : currentDir?.data?.[attrName];
        value = newVal && (checkIsNumberArray(newVal) || checkIsStringArray(newVal)) ? newVal.join(', ') : '-';
      } else {
        const newVal = attrName === 'id' ? currentDir[attrName] : currentDir?.data?.[attrName];
        value = !Array.isArray(newVal) ? newVal : '';
      }

      acc[key] = value;

      return acc;
    }, {});
  });

  return newData.filter(
    (item) =>
      !Object.entries(item).every(([key, value]) => typeof value === 'undefined' || ['status', 'id'].includes(key))
  );
};

export const getTableColumns = (
  columns: TTableColumnRaw[],
  attributes: TDirectoryTypeAttribute[],
  dictionary: string
): ColumnType[] => {
  const statusColumn: ColumnType = {
    name: 'status',
    label: 'directory:activity',
    sortingKey: `${dictionary}.status`,
    type: 'switcher',
  };

  const rawColumns = columns.map((col) => {
    const newCol: ColumnType = {
      name: '',
      label: '',
      sortingKey: col.valuePath,
    };

    if (col.innerColumns) {
      newCol.children = col.innerColumns.map((child) => {
        const attribute = attributes.find((attr) => attr.name === child.attrName?.split('.')[1]);
        const newChildrenCol: ColumnType = {
          name: child.attrName?.split('.')[1] || '',
          label: child.label || attribute?.defaultLabel || '',
          unit: attribute?.unit ?? '',
          sortingKey: child.valuePath,
        };

        if (attribute?.type === 'DateTime') {
          newChildrenCol.type = 'date';
        }

        if (attribute?.type === 'Boolean') {
          newChildrenCol.type = 'checkbox';
        }

        return newChildrenCol;
      });
    }

    const colName = col.attrName?.split('.')[1] ?? col.valuePath?.split('.')[1];

    const attribute = attributes.find((attr) => attr.name === colName);

    newCol.label = col.label || attribute?.defaultLabel || '';
    newCol.unit = attribute?.unit || attribute?.attributes?.find((item) => item.isValueAttr)?.unit || '';

    if (attribute && attribute.type === 'Object' && attribute.isArray && col.valuePath) {
      const firstDotIndex = col.valuePath.indexOf('.');
      newCol.name = col.valuePath.substring(firstDotIndex + 1);
    } else {
      newCol.name = colName || col.valuePath?.split('.')[1] || '';
    }

    if (attribute?.type === 'Boolean') {
      newCol.type = 'checkbox';
    }

    if (attribute?.type === 'DateTime') {
      newCol.type = 'date';
    }

    return newCol;
  });

  return [statusColumn, ...rawColumns];
};

export const getValuePaths = (columns: TTableColumnRaw[]): TValuePaths => {
  return columns.reduce<TValuePaths>((acc, next) => {
    if (next.valuePath) {
      const firstDotIndex = next.valuePath.indexOf('.');
      acc[next?.attrName?.split('.')[1] || next.valuePath.substring(firstDotIndex + 1)] = next.valuePath;
    } else if (next.innerColumns) {
      next.innerColumns.forEach((innerCol) => {
        if (innerCol.attrName && innerCol.valuePath) {
          acc[innerCol.attrName.split('.')[1]] = innerCol.valuePath;
        }
      });
    }

    return acc;
  }, {});
};

export const getValidationList = (
  data: Record<string, TTableData | TTableData[]>[],
  dictionary: string
): TTableData[] => {
  const selectedData: TTableData[] = [];

  for (let dataItem of data) {
    const item = dataItem[dictionary];

    if (Array.isArray(item)) {
      continue;
    }

    selectedData.push(item);
  }

  return selectedData;
};
