import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

import styles from './column-header.module.scss';

type Props = {
  className?: string;
  title: string;
  children?: ReactNode;
};

export const ColumnHeader = observer(function ColumnHeader({ className, title, children }: Props) {
  return (
    <div className={clsx(styles.wrapper, !!className && className)}>
      <p className={styles.title}>{title}</p>
      {children}
    </div>
  );
});
