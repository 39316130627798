import { computed, makeObservable } from 'mobx';
import moment, { Moment } from 'moment';

import { DateField } from '../entities/control-entities/date-field.entity';
import { YearField } from '../entities/control-entities/year-field.entity';
import { IDateRestrictions } from '../types';

import { Validation } from './validation';

export class DateValidation extends Validation<number | null> {
  readonly restrictions: IDateRestrictions;

  constructor(control: DateField | YearField, restrictions: IDateRestrictions) {
    super(control);
    this.restrictions = restrictions;

    makeObservable(this);
  }

  validate(value: Moment | null): string | undefined {
    if (value === null) {
      if (this.restrictions.required) return 'directory:Errors.required';
      return;
    }
  }

  @computed
  get minAvailableDate(): Moment | null {
    if (!this.restrictions.min) {
      return null;
    }

    const rawDate = this.processMinMaxRestrictrions(this.restrictions.min, (left, right) => left <= right);

    return !!rawDate ? moment.unix(rawDate) : null;
  }

  @computed
  get maxAvailableDate(): Moment | null {
    if (!this.restrictions.max) {
      return null;
    }

    const rawDate = this.processMinMaxRestrictrions(this.restrictions.max, (left, right) => left >= right);

    return !!rawDate ? moment.unix(rawDate) : null;
  }
}
