import { Directories } from 'src/store/directories/directories.store';
import { TDirectoryItem } from 'src/store/directories/types';

import { hasValue } from '../lib/common';

import { checkIsJoinResponseDirectory } from './check-is-join-response-directory';
import { checkIsRegularDirectory } from './check-is-regular-directory';
import { isStringNumberOrBoolean } from './is-string-number-or-boolean';
import { isStringifiedNull } from './is-stringified-null';

export type TConcatinationView = {
  delimiter?: string;
  attrConcat?: string[];
  attrConcatRefs?: TAttrConcatRefs;
};

export type TAttrConcatRefs = {
  [attrName: string]: {
    valueSourceAttrName?: string;
    refObjectAttr: string;
    refObjectType: string;
  };
};

export const getConcatedName = (
  directoriesStore: Directories,
  view: TConcatinationView,
  data: Record<string, unknown>
): string | null => {
  // if оставлен, т.к. возможно потрбуется расширение этой утилки
  if (view.attrConcat) {
    const values = view.attrConcat.reduce((values: string[], fullAttr) => {
      // Обрабатывает строки типа {Common_Rig.name || ${Common_Rig.modelId}}
      if (fullAttr.includes('||')) {
        const attrs = fullAttr.split('||').map((attr) => attr.trim());

        for (const attr of attrs) {
          if (attr.includes('$')) {
            const dirValue = getAttrValueFromDirectory(attr, directoriesStore, view, data);

            if (hasValue(dirValue) && !isStringifiedNull(dirValue)) {
              values.push(dirValue.toString());
              break;
            }
          }

          let value: unknown = null;

          if (checkIsJoinResponseDirectory(data)) {
            const [dirName, attrName] = attr.split('.');
            const directory = data[dirName];
            if (checkIsRegularDirectory(directory)) {
              const dirValue = directory.data[attrName];
              if (hasValue(dirValue)) {
                value = dirValue;
              }
            }
          } else {
            value = data[attr];
          }

          if (hasValue(value) && !isStringifiedNull(value) && isStringNumberOrBoolean(value)) {
            values.push(value.toString());
            break;
          }
        }

        return values;
      }

      if (fullAttr.includes('$')) {
        const dirValue = getAttrValueFromDirectory(fullAttr, directoriesStore, view, data);

        if (hasValue(dirValue) && !isStringifiedNull(dirValue)) {
          values.push(dirValue.toString());
        }

        return values;
      }

      let value: unknown = null;

      if (checkIsJoinResponseDirectory(data)) {
        const [dirName, attrName] = fullAttr.split('.');
        const directory = data[dirName];
        if (checkIsRegularDirectory(directory)) {
          const dirValue = directory.data[attrName];
          if (hasValue(dirValue)) {
            value = dirValue;
          }
        }
      } else {
        value = data[fullAttr];
      }

      if (hasValue(value) && isStringNumberOrBoolean(value)) {
        values.push(value.toString());
      }

      return values;
    }, []);
    return values.filter((value) => value !== '').join(view.delimiter ?? ' ');
  }

  return null;
};

function checkIsTreeDirectoryList(list: Record<string, unknown>): list is Record<string, TDirectoryItem> {
  return Object.values(list).every((dir) => checkIsRegularDirectory(dir));
}

const getAttrValueFromDirectory = (
  attr: string,
  directoriesStore: Directories,
  view: TConcatinationView,
  data: Record<string, unknown>
): string | number | boolean | null => {
  // Убираем ${} из имени атрибута, работает быстрее чем replaceAll
  const cleanAttr = attr.slice(2, -1);
  const refObjectType = view.attrConcatRefs?.[cleanAttr]?.refObjectType;
  const refObjectAttr = view.attrConcatRefs?.[cleanAttr]?.refObjectAttr;
  const valueSourceAttrName = view.attrConcatRefs?.[cleanAttr]?.valueSourceAttrName;

  let direcoryId: unknown = null;

  if (checkIsTreeDirectoryList(data)) {
    const [cleanAttrObjectType, cleanAttrAttrName] = valueSourceAttrName?.split('.') || cleanAttr.split('.');
    const dir = data[cleanAttrObjectType];

    direcoryId = dir.data?.[cleanAttrAttrName];
  } else {
    direcoryId = data[valueSourceAttrName || cleanAttr];
  }

  if (!refObjectType || !refObjectAttr || !direcoryId) {
    return null;
  }

  const directories = directoriesStore.getDirectoryMap(refObjectType);

  if (!directories || !hasValue(direcoryId) || (typeof direcoryId !== 'number' && typeof direcoryId !== 'string'))
    return null;

  const directory = directories.get(direcoryId);

  if (!directory) return null;

  const dirValue = directory.data[refObjectAttr];

  if (hasValue(dirValue) && isStringNumberOrBoolean(dirValue)) {
    return dirValue;
  }

  return null;
};
