import { useCallback, useState } from 'react';

interface UseModalOptions {
  initialIsOpen?: boolean;
}

export const useModal = (options?: UseModalOptions) => {
  const [isOpen, setIsOpen] = useState(options?.initialIsOpen || false);

  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  return { isOpen, openModal, closeModal };
};
