import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { MenuItem } from '../menu-item/menu-item';

import { support } from './consts';
import styles from './styles.module.scss';

type Props = {
  isOpened: boolean;
};

export const Support: React.FC<Props> = observer(function Support({ isOpened }) {
  return (
    <div className={clsx(styles.wrapper, isOpened && styles.wrapper__opened)}>
      {support.map((sup) => {
        return <MenuItem key={sup.name} isOpened={isOpened} item={sup} />;
      })}
    </div>
  );
});
