import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { FormDatePicker } from 'src/shared/components/form-components';

import { DateField } from '../../entities/control-entities/date-field.entity';

type Props = {
  item: DateField;
  label?: string;
  isDisabled?: boolean;
};

export const DatePickerControl = observer(function DatePickerControl({ item, label, isDisabled }: Props) {
  const { t } = useTranslation();

  return (
    <div>
      <FormDatePicker
        disabled={isDisabled}
        value={item.date}
        label={label}
        errorText={item.errorText}
        placeholder={t('directory:Controls.chooseDate')}
        ghostLabel
        allowClear
        disabledDate={item.getDisabledDates}
        onChange={item.changeValue}
        onFocus={item.clearError}
        onBlur={item.validate}
      />
    </div>
  );
});
