import { action, autorun, comparer, computed, makeObservable, observable, reaction } from 'mobx';
import { computedFn } from 'mobx-utils';

import { TFieldItem, TFilterRowsFilterItem } from 'src/api/directory/types';
import { TViolation } from 'src/errors';
import { hasValue } from 'src/shared/lib/common';
import { assert } from 'src/shared/utils/assert';
import { getDynamicJoinDependencies } from 'src/shared/utils/get-dynamic-join-dependencies';
import { isDefined } from 'src/shared/utils/is-defined';
import { isDynamicJoin } from 'src/shared/utils/is-dynamic-join';
import { processReQueryVariablesToObject } from 'src/shared/utils/process-ref-query-variables-to-object';
import { RootStore } from 'src/store';
import { Directories } from 'src/store/directories/directories.store';

import { Control, ValidatableItem } from './abstract-entities';
import { RegularComboBox } from './control-entities';
import { JoinComboBoxItem } from './control-entities/join-combobox.entity';
import { MultiCombobox } from './control-entities/multicombobox.entity';
import { RemovableRow, RemovableRowRow } from './control-entities/removable-row.entity';
import { Group } from './group.entity';
import { TForm } from './types';

export class FormStore {
  @observable groups: Group[];
  readonly fields: Record<string, Control>;
  readonly directories: Directories;

  constructor(data: TForm, rootStore: RootStore) {
    this.groups = data.groups;
    this.directories = rootStore.directories;
    this.fields = this.collectFields();

    makeObservable(this);
  }

  processFormFields(callback: (item: Control) => void): void {
    for (let group of this.groups) {
      for (let column of group.columns) {
        for (let item of column.rows) {
          callback(item);
          if (item instanceof RemovableRow) {
            item.rows.forEach((row) => {
              row.fieldsList.forEach((field) => callback(field));
            });
          }
        }
      }
    }
  }

  getControlsIterator(): Iterable<Control<unknown>> {
    const self = this;
    return {
      *[Symbol.iterator](): Iterator<Control<unknown>> {
        for (let group of self.groups) {
          for (let column of group.columns) {
            for (let item of column.rows) {
              yield item;

              if (item instanceof RemovableRow) {
                for (const row of item.rows) {
                  for (const field of row.fieldsList) {
                    yield field;
                  }
                }
              }
            }
          }
        }
      },
    };
  }

  checkIsFormComponentDisabled = computedFn((item: Control): boolean => {
    if (!item.enableIf) {
      return false;
    }
    for (const controlingItem of this.getControlsIterator()) {
      for (let enableIf of item.enableIf) {
        if (!('control' in enableIf) && controlingItem.formElementRefId === enableIf.attr) {
          if (controlingItem.value !== enableIf.value) {
            return true;
          }
        }
        if ('control' in enableIf && controlingItem.formElementRefId === enableIf.control) {
          const [objectType, property] = enableIf.attr.split('.');
          const directory = this.directories.getDirectory(objectType);
          const directoryValue = directory.find((directoryValue) => directoryValue.id === controlingItem.value);
          if (directoryValue) {
            if (directoryValue.data[property] !== enableIf.value) {
              return true;
            }
          } else {
            return true;
          }
        }
      }
    }

    return false;
  });

  checkIsFormComponentVisible = computedFn((item: Control): boolean => {
    if (!item.showIf) {
      return true;
    }
    for (const controlingItem of this.getControlsIterator()) {
      for (let showIf of item.showIf) {
        if (controlingItem.formElementRefId === showIf.attr) {
          if (controlingItem.value !== showIf.value) {
            return false;
          }
        }
      }
    }
    return true;
  });

  collectFields() {
    const fields: Record<string, Control> = {};
    const _collectFields = (field: Control) => {
      if (field.formElementRefId) {
        fields[field.formElementRefId] = field;
      }
      if (field.fieldId) {
        fields[field.fieldId] = field;
      }
    };

    this.processFormFields(_collectFields);

    return fields;
  }

  //TODO: возможно сюда можно будет включить все обновления филдов, зависящих от компутед свойств
  initializeFields() {
    const startTime = performance.now();
    const funcsForAutorun: (() => void)[] = [];

    const fields = Object.values(this.fields);

    fields.forEach((field) => {
      if (field instanceof RemovableRow) {
        field.rows.forEach((row) => {
          row.fieldsList.forEach((rowField) => fields.push(rowField));
        });
      }
    });

    fields.forEach((field) => {
      if (field.defaultValueLink) {
        const [objectType, valueLink] = field.defaultValueLink.split('.');

        const controlingField = Object.values(this.fields).find((controlField) => {
          if (controlField instanceof RegularComboBox && controlField.refObjectType === objectType) {
            return controlField;
          }
          return undefined;
        });

        if (controlingField) {
          funcsForAutorun.push(() => {
            if (hasValue(controlingField.value) && typeof controlingField.value === 'number') {
              const directoryValue = this.directories.getDirectoryMap(objectType)?.get(controlingField.value);

              if (directoryValue) {
                if (this.checkIsFormComponentDisabled(field)) {
                  field.setValue(directoryValue.data[valueLink]);
                }
              }
            }
          });
        }
      }
      if (field instanceof ValidatableItem && field.requiredOr) {
        const connectControlsWithRequiredOr = () => {
          if (
            field.requiredOr?.some(
              (requiredFieldId) =>
                this.fields[requiredFieldId]?.value !== undefined && this.fields[requiredFieldId]?.value !== null
            )
          ) {
            field.clearError();
            field.setIsRequired(false);
          } else {
            field.setIsRequired(true);
          }
        };
        funcsForAutorun.push(connectControlsWithRequiredOr);
      }

      if ((field instanceof JoinComboBoxItem || field instanceof MultiCombobox) && field.refQuery) {
        const refQuery = field.refQuery;

        if (isDynamicJoin(refQuery)) {
          const dependencies = getDynamicJoinDependencies(refQuery);
          const variablesFromDirectories = processReQueryVariablesToObject(field.refQueryVariables);
          const connectDynamicJoinWithValues = async () => {
            const dependenciesObject: Record<string, string | number | boolean> = {};
            let breakRequest = false;

            for (const dep of dependencies.attrs) {
              if (Object.prototype.hasOwnProperty.call(variablesFromDirectories, dep)) {
                const value = this.fields[variablesFromDirectories[dep]]?.value;
                const [objectType, property] = dep.split('.');
                const directoryValue = this.directories
                  .getDirectory(objectType)
                  ?.find((dirValue) => dirValue.id === value);
                const depValue = directoryValue?.data[property];
                if (typeof depValue === 'string' || typeof depValue === 'number' || typeof depValue === 'boolean') {
                  dependenciesObject[dep] = depValue;
                }
                continue;
              }
              const value = this.fields[dep]?.value;
              if (value === null || value === undefined) {
                breakRequest = true;
                break;
              }
              if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
                dependenciesObject[dep] = value;
              }
            }

            for (const dep of dependencies.fieldId) {
              const value = this.fields[dep].value;
              if (value === null || value === undefined) {
                breakRequest = true;
                break;
              }
              if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
                dependenciesObject[dep] = value;
              }
            }
            if (breakRequest) {
              field.resetDefaultOptions();
              return;
            }
            const joinRes = await this.directories.fetchDymanicJoinObject(refQuery, dependenciesObject);
            field.setOptionsByJoinResponse(joinRes);
          };
          funcsForAutorun.push(connectDynamicJoinWithValues);
        } else {
          const connectJoin = async () => {
            const joinRes = await this.directories.fetchDymanicJoinObject(refQuery, {});

            if (joinRes) {
              field.setOptionsByJoinResponse(joinRes);
            }
          };

          funcsForAutorun.push(connectJoin);
        }
      }
      if (field instanceof RegularComboBox && field.filterByControlValue) {
        const filter = field.filterByControlValue;

        const filterOptionsByControl = (): void => {
          const filterValue: unknown | null = (() => {
            const filterSource = filter.filterValueSource;
            const filterValueisDirectoryValueProp = filterSource.includes('$') ? false : true;
            const cleanFilterAttrName = filterSource.slice(2, -1);

            if (filterValueisDirectoryValueProp) {
              // TODO: здесь в будущем может находится поиск свойства справочного значения
              return null;
            } else {
              return this.fields[cleanFilterAttrName]?.value || null;
            }
          })();

          const optionsThatShouldBeExcluded = field.options.filter((option) => {
            const optionValue = option.value;

            const directory = this.directories.getDirectoryMap(field.refObjectType)?.get(optionValue);

            if (!directory) {
              return true;
            }

            const foundValue =
              filter.checkingAttrName === 'id' ? directory.id : directory.data[filter.checkingAttrName] || null;

            switch (filter.condition) {
              case 'equal': {
                return foundValue !== filterValue;
              }
              case 'includes': {
                return !Array.isArray(foundValue) || !foundValue.includes(filterValue);
              }

              default:
                return false;
            }
          });

          const valuesThatShouldBeExcluded = [
            ...field.valuesThatShouldBeExcluded,
            ...optionsThatShouldBeExcluded.map((option) => option.value),
          ];

          if (comparer.structural(valuesThatShouldBeExcluded, field.valuesThatShouldBeExcluded)) {
            return;
          }

          field.setValuesThatShouldBeExcluded(valuesThatShouldBeExcluded);
        };

        funcsForAutorun.push(filterOptionsByControl);
      }
      if (field.formElementRefId === 'Common_CoreSamplingInterval.runs') {
        field.setIsDisabled(true);
        const extractionVolumeControl = this.fields['Common_CoreSamplingInterval.extractionVolume'];
        const geologicalLoadConst = this.directories.getDirectory('Common_GeologicalLoad')?.[0];
        const autocalculateRunsCount = () => {
          const extVolume = extractionVolumeControl.value;
          const extVolumePerRun = geologicalLoadConst?.data['coreSamplingExtractionAmountPerRun'];
          if (
            extVolume &&
            typeof extVolume === 'number' &&
            extVolumePerRun &&
            typeof extVolumePerRun === 'number' &&
            !Number.isNaN(extVolume) &&
            !Number.isNaN(extVolumePerRun)
          ) {
            field.setValue(Math.ceil(extVolume / extVolumePerRun));
          } else {
            field.setValue(null);
          }
        };
        funcsForAutorun.push(autocalculateRunsCount);
      }
      if (field.formElementRefId === 'Common_CoreSamplingInterval.coreSamplingDayAmount') {
        field.setIsDisabled(true);
        const extVolumePerRunControl = this.fields['Common_CoreSamplingInterval.runs'];
        const geologicalLoadConst = this.directories.getDirectory('Common_GeologicalLoad')?.[0];
        const autocalculateDayAmount = () => {
          const runs = extVolumePerRunControl.value;
          const dayAmountPerRun = geologicalLoadConst?.data['coreSamplingDayAmountPerRun'];
          if (
            runs &&
            typeof runs === 'number' &&
            dayAmountPerRun &&
            typeof dayAmountPerRun === 'number' &&
            !Number.isNaN(runs) &&
            !Number.isNaN(dayAmountPerRun)
          ) {
            field.setValue(runs * dayAmountPerRun);
          } else {
            field.setValue(null);
          }
        };
        funcsForAutorun.push(autocalculateDayAmount);
      }
    });

    return funcsForAutorun;
  }

  @action.bound
  setExistingDirectoryValues(editDirectory: Record<string, TFieldItem | undefined>) {
    for (const group of this.groups) {
      for (const column of group.columns) {
        for (const item of column.rows) {
          if (item.fieldId) {
            item.setValue(editDirectory[item.fieldId]);
          }
        }
      }
    }
  }

  @computed
  get isAllNecessaryFieldsAreFilled(): boolean {
    for (const group of this.groups) {
      for (const column of group.columns) {
        for (const item of column.rows) {
          if (!this.checkIsFormComponentDisabled(item)) {
            if (item instanceof ValidatableItem) {
              if (item.restrictions?.required) {
                if (!item.isReady) {
                  return false;
                }
              }
            }
          }
        }
      }
    }

    return true;
  }

  @action
  setServerErrors(errors?: TViolation[]) {
    const setError = (item: Control) => {
      if (!this.checkIsFormComponentDisabled(item)) {
        if (item instanceof ValidatableItem && item.fieldId && !!errors) {
          const fieldViolation = errors.find(
            (violation) => violation.attrName === item.formElementRefId?.split('.')?.[1]
          );
          if (fieldViolation) {
            item.setError(fieldViolation.message);
          }
        }
      }
    };

    this.processFormFields(setError);
  }

  @action.bound
  validateAllFields() {
    const validateField = (item: Control) => {
      if (!this.checkIsFormComponentDisabled(item)) {
        if (item instanceof ValidatableItem) {
          item.validate();
        }
      }
    };

    this.processFormFields(validateField);
  }

  @action.bound
  resetForm() {
    const resetValue = (item: Control) => {
      item.resetControl();
    };

    this.processFormFields(resetValue);
  }

  getFieldName = (field?: Control): string | undefined => {
    if (!field) return;
    if (field.label) return field.label;
    if (field.formElementRefId) {
      return this.directories.getAttribute(field.formElementRefId)?.defaultLabel;
    }
  };

  // При приведении форм ковра и справочников к одному виду этот метод обособится в отдельный плагин (filterSectionTypesByChosenWellType), фильтрующий опции на основе данных пришедших с бэка.
  removableRowFiltrationByMainComboboxValues(): VoidFunction {
    return autorun(() => {
      const processItem = (item: Control) => {
        if (item instanceof RemovableRow && item.relatedToButtonComboboxAttrName) {
          const hiddenValues: (string | number)[] = [];
          const chosedValues: (string | number)[] = [];

          const comboboxAttrName = item.relatedToButtonComboboxAttrName;

          item.rows.forEach((row) => {
            const relatedCombobox = row.fieldsList.find((field) => field.formElementRefId === comboboxAttrName);

            if (relatedCombobox && isDefined<string | number>(relatedCombobox.value)) {
              chosedValues.push(relatedCombobox.value);
            }
          });

          item.filters.forEach((filter) => {
            for (const condition of filter.showIf) {
              const controllingControl = this.fields[condition.control ?? condition.attr];

              if (controllingControl) {
                const [directory, property] = condition.attr.split('.');
                const directoryItem = this.directories
                  .getDirectory(directory)
                  ?.find((directory) => directory.id === controllingControl.value);
                const directoryItemValue = directoryItem?.data[property];

                if (directoryItem && condition.value && directoryItemValue !== condition.value) {
                  hiddenValues.push(filter.value);
                  return;
                }
              }
            }
          });

          item.setButtonOptions(
            item.initialButtonOptions.filter(
              (option) => !chosedValues.includes(option.value) && !hiddenValues.includes(option.value)
            )
          );

          item.rows.forEach((row) => {
            const mainCombobox = row.fieldsList.find((field) => field.formElementRefId === comboboxAttrName);

            if (mainCombobox instanceof RegularComboBox) {
              const copiedChosedValues = chosedValues.filter((value) => value !== mainCombobox.value);

              mainCombobox.setValuesThatShouldBeExcluded([...hiddenValues, ...copiedChosedValues]);
            }
          });
        }
      };

      for (let group of this.groups) {
        for (let column of group.columns) {
          for (let item of column.rows) {
            //TODO: полностью переделается при рефакторинге проходки по всем контролам и рефакторинге контрола RemovableRow
            processItem(item);
          }
        }
      }
    });
  }

  // TODO: необходим рефакторинг формы  целом и RemovableRow в частности
  // TODO: Мб подумать над единой  вьюхой фильтров на форме, алгоритм которой можно было бы перенть и на ковре
  // Будет вынесено в отдельный плагин. Метод фильтрует строки контрола по заданным условиям
  filterRemovableRowRows(): VoidFunction {
    const disposers: VoidFunction[] = [];

    const processItem = (field: Control) => {
      if (field instanceof RemovableRow && field.filterRows) {
        let rowsDisposers: VoidFunction[] = [];

        const disposer = reaction(
          () => field.rows,
          () => {
            rowsDisposers.forEach((disp) => disp());
            rowsDisposers = [];

            const filter = field.filterRows;

            assert(filter, 'filterRows is not presented');

            const rowDisposer = reaction(
              () => {
                const rowsWithFiltersAndValues: {
                  row: RemovableRowRow;
                  filtersData: {
                    filterItem: TFilterRowsFilterItem;
                    filterIfControlValue: unknown | undefined;
                    controlledControlValue: unknown;
                  }[];
                }[] = [];

                for (const row of field.rows) {
                  rowsWithFiltersAndValues.push({
                    row,
                    filtersData: filter.filters.map((filterItem) => ({
                      filterItem: filterItem,
                      filterIfControlValue: (() => {
                        if (!filterItem.filtrateIf) {
                          return undefined;
                        }
                        return this.fields[filterItem.filtrateIf.attrName]?.value;
                      })(),
                      controlledControlValue: row.fields[filterItem.attrName]?.value,
                    })),
                  });
                }
                return rowsWithFiltersAndValues;
              },
              (rowsWithFiltersAndValues) => {
                let filteredRows = [...field.rows];
                const filterOperator = filter.operator;

                const removeRow = (row: RemovableRowRow): void => {
                  filteredRows = filteredRows.filter((filterRow) => filterRow.id !== row.id);
                };

                for (const rowWithFiltersAndValues of rowsWithFiltersAndValues) {
                  const { row, filtersData } = rowWithFiltersAndValues;
                  const votesForRemove: TFilterRowsFilterItem[] = [];

                  for (const filterData of filtersData) {
                    const { filterItem, filterIfControlValue, controlledControlValue } = filterData;
                    if (
                      !filterItem.filtrateIf ||
                      filterIfControlValue === undefined ||
                      filterItem.filtrateIf.value !== filterIfControlValue
                    ) {
                      continue;
                    }
                    switch (filterItem.condition) {
                      case 'equal': {
                        if (controlledControlValue !== filterItem.value) {
                          votesForRemove.push(filterItem);
                        }
                        break;
                      }
                      case 'not-equal': {
                        if (controlledControlValue === filterItem.value) {
                          votesForRemove.push(filterItem);
                        }
                        break;
                      }
                      case 'contains': {
                        if (
                          Array.isArray(controlledControlValue) &&
                          !controlledControlValue.includes(filterItem.value)
                        ) {
                          votesForRemove.push(filterItem);
                        }
                        break;
                      }
                    }
                  }

                  switch (filterOperator) {
                    case 'AND': {
                      if (votesForRemove.length > 0) {
                        removeRow(row);
                      }
                      break;
                    }
                    case 'OR': {
                      if (votesForRemove.length === filtersData.length) {
                        removeRow(row);
                      }
                    }
                  }
                }

                field.setFilteredRows(filteredRows);
              },
              { fireImmediately: true }
            );

            rowsDisposers.push(() => {
              'controls disposer';
              rowDisposer();
            });
          },
          { fireImmediately: true }
        );

        disposers.push(disposer);
      }
    };

    for (let group of this.groups) {
      for (let column of group.columns) {
        for (let item of column.rows) {
          //TODO: полностью переделается при рефакторинге проходки по всем контролам и рефакторинге контрола RemovableRow
          processItem(item);
        }
      }
    }

    return () => disposers.forEach((disp) => disp());
  }

  // При приведении форм ковра и справочников к одному виду этот метод обособится в отдельный плагин (dynamicControlsRestrictions), устанавливающий в валидацию контролов связанные контролы.
  dynamicControlsRestrictions(): void {
    const processControl = (dependentControl: Control) => {
      if (dependentControl instanceof ValidatableItem) {
        if (dependentControl.validation && (dependentControl.restrictions?.max || dependentControl.restrictions?.min)) {
          [...(dependentControl.restrictions?.max || []), ...(dependentControl.restrictions?.min || [])].forEach(
            (restriction) => {
              if (restriction.type === 'ATTR') {
                const control = this.fields[restriction.value];

                if (control) {
                  dependentControl.validation?.addRefControl(restriction.value, control);
                }
              }
            }
          );
        }
      }
    };

    for (let group of this.groups) {
      for (let column of group.columns) {
        for (let item of column.rows) {
          //TODO: полностью переделается при рефакторинге проходки по всем контролам и рефакторинге контрола RemovableRow
          processControl(item);
        }
      }
    }
  }

  @action.bound
  effect() {
    const fields = Object.values(this.fields);

    fields.forEach((field) => {
      if (field instanceof RemovableRow) {
        field.rows.forEach((row) => {
          row.fieldsList.forEach((rowField) => fields.push(rowField));
        });
      }
    });

    let filterSectionsDisposer: VoidFunction | null = null;
    let disposers: VoidFunction[] = [];
    const removableRowFiltrationDisposer = this.filterRemovableRowRows();

    // TODO: необходим рефакторинг. Нужно, чтобы функции обработки контролов реагировали на добавление и удаление каонтролов как на ковре
    const controlsDisposer = reaction(
      () => {
        const fields = Object.values(this.fields);

        fields.forEach((field) => {
          if (field instanceof RemovableRow) {
            field.rows.forEach((row) => {
              row.fieldsList.forEach((rowField) => fields.push(rowField));
            });
          }
        });

        return fields;
      },
      () => {
        filterSectionsDisposer?.();
        disposers.forEach((disp) => disp());

        const autorunFuncs = this.initializeFields();
        disposers = autorunFuncs.map((func) => autorun(func));
        filterSectionsDisposer = this.removableRowFiltrationByMainComboboxValues();
        this.dynamicControlsRestrictions();
      },
      { fireImmediately: true }
    );

    return () => {
      controlsDisposer();
      filterSectionsDisposer?.();
      disposers.forEach((disp) => disp());
      removableRowFiltrationDisposer();
    };
  }
}
