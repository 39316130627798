import { Checkbox as AntdCheckbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren, ReactNode } from 'react';

import styles from './checkbox.module.scss';

import './overwrite.scss';

type Props = PropsWithChildren<{
  onChange: (event?: CheckboxChangeEvent) => void;
  id?: string;
  label?: ReactNode;
  isChecked?: boolean;
  isDisabled?: boolean;
  className?: string;
  labelClassName?: string;
}>;

export const Checkbox = observer(function Checkbox({
  isChecked,
  label,
  id,
  isDisabled,
  className,
  children,
  onChange,
  labelClassName,
}: Props) {
  return (
    <div className={clsx(className, styles.wrapper)}>
      <AntdCheckbox id={id} checked={isChecked} disabled={isDisabled} onChange={onChange}>
        {children}
      </AntdCheckbox>

      {label && (
        <label htmlFor={id} className={clsx(labelClassName, styles.label)}>
          {label}
        </label>
      )}
    </div>
  );
});
