import { Field } from 'src/features/directory-form/entities/abstract-entities';
import { TDirectoryTypeAttribute } from 'src/store/directories/types';

import { TFieldRaw } from '../../types';

import { mapIntegerFieldItem } from './map-integer-field-item';
import { mapNumberFieldItem } from './map-number-field-item';
import { mapTextFieldItem } from './map-text-field-item';

export const mapFieldItem = (
  item: TFieldRaw,
  attribute: TDirectoryTypeAttribute | undefined
): Field<string | number | null> => {
  switch (item.type) {
    case 'String':
      return mapTextFieldItem(item, attribute);
    case 'Number':
      return mapNumberFieldItem(item, attribute);
    case 'Integer':
      return mapIntegerFieldItem(item, attribute);
    default: {
      console.error('Unable to recognize the item', item);
      throw new Error('Unable to recognize the item');
    }
  }
};
