import { ColumnType } from 'src/store/table/types';

export function getSortingKeyByColumnName(columns: ColumnType[], name: string): string | undefined {
  let column: ColumnType | Omit<ColumnType, 'width'> | undefined;

  for (let item of columns) {
    if (item.children && item.name === '') {
      const childColumn = item.children.find((childrenItem) => {
        return childrenItem.name === name;
      });

      if (childColumn) {
        column = childColumn;
        break;
      }
    } else if (item.name === name) {
      column = item;
      break;
    }
  }

  return column?.sortingKey;
}
