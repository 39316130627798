import { observer } from 'mobx-react-lite';
import { JSXElementConstructor, ReactElement, useState } from 'react';

import { FormMultiSelect } from 'src/shared/components/form-components/form-multiselect/form-multiselect';

import { MultiCombobox } from '../../entities/control-entities/multicombobox.entity';
import { ComboboxDropdown } from '../combobox-dropdown/combobox-dropdown';

type Props = {
  item: MultiCombobox;
  isDisabled?: boolean;
  label?: string;
};

export const FormMultiCombobox = observer(function FormMultiCombobox({ item, isDisabled, label }: Props) {
  const [isOpened, setIsOpened] = useState(false);

  const handleAddNewValue = () => {
    item.openNewFormSidebar();
    setIsOpened(false);
  };

  const renderDropdown = (origin: ReactElement<unknown, string | JSXElementConstructor<unknown>>) => {
    return <ComboboxDropdown onButtonClick={handleAddNewValue}>{origin}</ComboboxDropdown>;
  };

  const onChange = (value: (string | number)[]) => {
    item.setValue(value);
    item.clearError();
  };

  const onDropdownVisibleChange = (open: boolean) => {
    setIsOpened(open);
  };

  return (
    <FormMultiSelect
      open={isOpened}
      value={item.value}
      label={label}
      errorText={item.errorText}
      options={item.options}
      isDisabled={isDisabled}
      dataTestId={`combobox-${item.fieldId}`}
      showSearch
      optionFilterProp="label"
      dropdownStyle={{
        zIndex: 25,
      }}
      dropdownRender={renderDropdown}
      onBlur={item.validate}
      onChange={onChange}
      onDropdownVisibleChange={onDropdownVisibleChange}
    />
  );
});
