import { useDraggable } from '@dnd-kit/core';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { HeaderType } from '../../import-matching-page.store';

import styles from './draggable-matching-item.module.scss';
import { MatchingItem } from './matching-item';

type Props = {
  isOver: boolean;
  isRequired: boolean;
  name: string;
  header: HeaderType | null;
  isEmpty: boolean;
};

export const DraggableMatchingItem = observer(function DraggableMatchingItem({
  isOver,
  name,
  isEmpty,
  isRequired,
  header,
}: Props) {
  const { t } = useTranslation('matching');
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: header?.columnIndex.toString() || '',
    data: {
      from: name,
      header,
    },
  });

  const draggableAttributes = isEmpty ? {} : attributes;
  const draggableListeners = isEmpty ? {} : listeners;

  return (
    <div
      ref={setNodeRef}
      {...draggableListeners}
      {...draggableAttributes}
      className={clsx(
        styles.dataFieldWrapper,
        styles.userDataFieldWrapper,
        !isEmpty && styles.userDataFieldWrapper__grabbable
      )}
      test-id={`draggable-item-${name}`}
    >
      <MatchingItem
        isHovered={isOver}
        isRequired={isRequired}
        className={clsx(styles.userItem, isOver && styles.userItem__hovered)}
        isEmpty={isEmpty}
        name={header?.title || t('noMatch')}
      />
      {isOver && <div className={styles.dragAreaContainer} />}
    </div>
  );
});
