import { TableCell } from 'src/shared/components/table-cell';
import { MultiCell } from 'src/shared/components/table-cell/multi-cell';
import { hasValue } from 'src/shared/lib/common';
import { AntdColumnsType, ColumnType, TableRow } from 'src/store/table/types';

import { ContextMenu } from '../context-menu';

import { DEFAULT_COLUMN_WIDTH, MIN_COLUMN_WIDTH } from './constansts';

export function getViewingColumns(columns: ColumnType[]): AntdColumnsType[] {
  return columns.map((column, index) => {
    const defaultWidth = DEFAULT_COLUMN_WIDTH * (hasValue(column.children) ? column.children.length : 1);
    const width = column.width || defaultWidth;

    return {
      index: index,
      title: column.label,
      unit: column.unit,
      dataIndex: column.name || column.label,
      key: column.name || column.label,
      width,
      ellipsis: true,
      columnType: column.type,
      ...(hasValue(column.children) && {
        children: [
          ...column?.children.map((childColumn, idx) => {
            return {
              index: idx,
              title: childColumn.label,
              unit: childColumn.unit,
              dataIndex: childColumn.name,
              key: childColumn.name,
              width: column.children?.length ? width / column.children.length : MIN_COLUMN_WIDTH,
              ellipsis: true,
            };
          }),
        ],
      }),
    };
  });
}

export function getTableData(
  handleSelectRow: (newSelectedRowId: number) => void,
  onEditCell: (currentRowIndex: number, column: string, value: string | number | boolean) => void,
  onEditRow: (row: TableRow) => void,
  onCopyRow: (row: TableRow) => void,
  columnsDataSource: ColumnType[],
  tableDataSource: TableRow[],
  toggleRow: (id: number) => void,
  selectedRows?: Record<string, boolean>,
  type?: string,
  isCopyMode?: boolean
): Record<string, React.ReactNode>[] {
  const columns = columnsDataSource.map((item) => {
    return item.name || item.label;
  });
  return tableDataSource.map((row, index): Record<string, React.ReactNode> => {
    const handleEditRow = () => onEditRow(row);
    const handleCopyRow = () => onCopyRow(row);
    const handleSelect = () => toggleRow(Number(row.id));

    return {
      key: index,
      settingsColumn: (
        <ContextMenu
          onToggle={handleSelect}
          onEditClick={handleEditRow}
          onCopyClick={handleCopyRow}
          isSelected={selectedRows?.[Number(row.id)]}
          isCheckable={isCopyMode}
        />
      ),
      ...columns.reduce(function (target, columnName) {
        const columnsSourceItem = columnsDataSource.find((column) => (column.name || column.label) === columnName);

        target[columnName] = columnsSourceItem?.children ? (
          <MultiCell
            row={row}
            rowIndex={index}
            onSelectRow={handleSelectRow}
            onEditCell={onEditCell}
            childColumns={columnsSourceItem?.children}
          />
        ) : (
          <TableCell
            type={type}
            row={row}
            rowIndex={index}
            columnsDataSource={columnsDataSource}
            columnName={columnName}
            handleSelectRow={handleSelectRow}
            onEditCell={onEditCell}
            disabled={typeof row.id === 'string'}
            cellType={columnsDataSource.find((item) => item.name === columnName)?.type}
          />
        );
        return target;
      }, {} as Record<string, React.ReactNode>),
    };
  });
}

export function getTableWidth(columns: AntdColumnsType[]): string {
  const sumColumnWidth = columns.reduce((sum, currentColumn) => sum + currentColumn.width, 0);

  if (window.innerWidth > sumColumnWidth) {
    return sumColumnWidth ? `${sumColumnWidth + 20}px` : '100%';
  }
  return '100%';
}

const scrollbarHeight = 12;

export function getTableHeight(
  numberOfRows: number,
  cellHeight: number,
  tableHeaderHeight: number,
  availableSpaceHeight?: number
): number | undefined {
  if (availableSpaceHeight) {
    if (numberOfRows * cellHeight + tableHeaderHeight < availableSpaceHeight) {
      return numberOfRows * cellHeight + scrollbarHeight;
    }

    return availableSpaceHeight - (tableHeaderHeight + 36);
  }
}

export function getLeftColumnOffset(columnIndex: number, columns: AntdColumnsType[], fixed?: boolean | string) {
  if (!fixed) {
    return;
  }

  return columns.slice(0, columnIndex).reduce((sum, { width }) => sum + width, 0);
}

export function getLeftChildColumnOffset(
  columnIndex: number,
  childColumnIndex: number,
  columns: AntdColumnsType[],
  childColumns?: AntdColumnsType[],
  fixed?: boolean | string
) {
  if (!fixed || !childColumns) return;

  const parentOffset = columns.slice(0, columnIndex).reduce((sum, { width }) => sum + width, 0);
  const childOffset = childColumns.slice(0, childColumnIndex).reduce((sum, { width }) => sum + width, 0);

  return parentOffset + childOffset;
}
