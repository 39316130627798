import { ReactComponent as MoonIcon } from 'src/shared/assets/icons/moon.svg';
import { ReactComponent as SunIcon } from 'src/shared/assets/icons/sun.svg';
import { TSwitcherItems } from 'src/shared/components/switcher/switcher';

export const themeItems: TSwitcherItems = [
  {
    text: 'common:Theme.dark',
    renderCompactIcon: (className) => <MoonIcon className={className} />,
  },
  {
    text: 'common:Theme.light',
    renderCompactIcon: (className) => <SunIcon className={className} />,
  },
];
