import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren, ReactNode } from 'react';

import { Header } from 'src/features/header/header';
import { Sidebar } from 'src/features/sidebar/sidebar';
import { checkIsPortableDevice } from 'src/shared/utils/check-is-portable-device';

import styles from './styles.module.scss';

type Props = PropsWithChildren<{
  title: string;
  headerCustomComponent?: ReactNode;
}>;

export const MainLayout: React.FC<Props> = observer(function MainLayout({ title, headerCustomComponent, children }) {
  return (
    <div className={clsx(styles.wrapper, checkIsPortableDevice() && styles.wrapper_portable)}>
      <Sidebar />
      <div className={styles.contentWrapper}>
        <Header title={title}>{headerCustomComponent}</Header>
        {children}
      </div>
    </div>
  );
});
