import { NumberField } from 'src/features/directory-form/entities/control-entities';
import { TDirectoryTypeAttribute } from 'src/store/directories/types';

import { TNumberFieldRaw } from '../../types';

export const mapNumberFieldItem = (
  item: TNumberFieldRaw,
  attribute: TDirectoryTypeAttribute | undefined
): NumberField => {
  const fieldProps = {
    formElementRefId: item.attrName,
    fieldId: attribute?.name,
    unit: attribute?.unit,
    label: attribute?.defaultLabel,
    defaultValueLink: item.defaultValueLink,
    enableIf: item.enableIf,
    showIf: item.showIf,
    restrictions: { required: !!attribute?.required, ...attribute?.restrictions },
    type: item.type,
  };

  return new NumberField(fieldProps);
};
