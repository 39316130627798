import { action, makeObservable, observable, toJS } from 'mobx';

import { TRequiredIf } from 'src/api/directory/types';

import { IRestrictions } from '../../types';
import { ValidatableItem } from '../abstract-entities';
import { TControlValueType, TEnableIf, TShowIfRaw } from '../types';

type TStringsListProps = {
  formElementRefId: string;
  type: TControlValueType;
  restrictions: IRestrictions;
  enableIf?: TEnableIf[];
  showIf?: TShowIfRaw[];
  requiredIf?: TRequiredIf[];
  defaultValueLink?: string;
};

export class StringsList extends ValidatableItem<string[] | number[]> {
  @observable value: string[] | number[] = [];
  @observable inputValue: string = '';

  constructor(data: TStringsListProps) {
    super(data);

    makeObservable(this);
  }

  private checkIsNumberArray(value: unknown[]): value is number[] {
    return value.every((itemValue) => !Number.isNaN(Number(itemValue))) && ['Number', 'Integer'].includes(this.type);
  }

  @action.bound
  setValue(value: string[] | number[] | null | undefined): void {
    if (!value) {
      this.value = [];
      return;
    }

    this.value = value;
  }

  @action.bound
  setInputValue(value: string): void {
    this.clearError();

    if (value === '') {
      this.inputValue = '';
      return;
    }

    if (this.type === 'Integer' || this.type === 'Number') {
      if (value.match(/^-?[0-9]{0,12}(((?<=[0-9])(,|(, )))?((?<=(,|(, )))(-?[0-9]{0,12}))?)*$/g)) {
        this.inputValue = value;
      }
      return;
    }
    this.inputValue = value;
  }

  @action.bound
  addValue(): void {
    if (this.inputValue === '') {
      return;
    }

    const proccesedInputValueIntoArray = this.inputValue
      .split(',')
      .map((itemValue) => itemValue.trim())
      .filter((itemValue) =>
        itemValue !== '' && (this.type === 'Integer' || this.type === 'Number')
          ? !Number.isNaN(Number(itemValue))
          : true
      );

    if (this.checkIsNumberArray(this.value) && this.checkIsNumberArray(proccesedInputValueIntoArray)) {
      const value = proccesedInputValueIntoArray.map((itemValue) => Number(itemValue));

      for (const itemValue of value) {
        if (this.value.includes(itemValue)) {
          continue;
        }

        this.value.push(itemValue);
      }
    } else if (!this.checkIsNumberArray(this.value)) {
      for (const itemValue of proccesedInputValueIntoArray) {
        if (this.value.includes(itemValue)) {
          continue;
        }

        this.value.push(itemValue);
      }
    }

    this.inputValue = '';
  }

  @action.bound
  deleteValue(value: string | number): void {
    const copiedValue: string[] | number[] = toJS(this.value);
    const index = copiedValue.findIndex((itemValue) => itemValue === value);

    copiedValue.splice(index, 1);
    this.value = copiedValue;
  }

  @action.bound
  resetControl(): void {
    this.clearError();
    this.value = [];
  }

  @action.bound
  clearError(): void {
    this.errorText = undefined;
  }

  @action.bound
  validate(): void {
    if (!this.value.length && this.restrictions?.required) {
      this.errorText = 'directory:Errors.required';
    }
  }

  checkIsReady(): boolean {
    return !this.errorText;
  }
}
