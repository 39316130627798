import { TRefQuery } from 'src/store/directories/types';

export const isDynamicJoin = (refQuery?: TRefQuery): boolean => {
  if (!refQuery) {
    return false;
  }

  let isDynamic = false;

  function changeIsDynamic(is: boolean) {
    isDynamic = is;
  }

  if (refQuery.join) {
    for (const join of refQuery.join) {
      if (!join.where) {
        continue;
      }
      join.where.forEach((where) => {
        for (const key in where) {
          const whereValue = where[key];
          if (
            whereValue &&
            typeof whereValue === 'string' &&
            (whereValue.includes('%{') || whereValue.includes('${'))
          ) {
            changeIsDynamic(true);
          }
        }
      });
    }
  }
  if (refQuery.where) {
    refQuery.where.forEach((where) => {
      for (const key in where) {
        const whereValue = where[key];
        if (whereValue && typeof whereValue === 'string' && (whereValue.includes('%{') || whereValue.includes('${'))) {
          changeIsDynamic(true);
        }
      }
    });
  }

  return isDynamic;
};
