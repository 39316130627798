import { makeObservable, action, observable, flow } from 'mobx';

import { saveChanges, saveYearChanges } from 'src/api/directory/requests';
import { storage } from 'src/api/storage';
import { DirectoryStore } from 'src/features/directory/store/directory';
import { LOCAL_STORAGE } from 'src/shared/constants/local-storage';
import { RootStore } from 'src/store';
import { Directories } from 'src/store/directories/directories.store';
import { TDirectoryItem } from 'src/store/directories/types';
import { I18NextStore } from 'src/store/i18next/i18next-store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';
import { RouterStore } from 'src/store/router/router-store';
import { TableRow } from 'src/store/table/types';

export class DirectoryPageStore {
  @observable availableSpaceHeight?: number;
  @observable isFormSidebarOpened: boolean = false;
  @observable editingDirectory: TDirectoryItem | null = null;
  @observable copyingDirectory: TDirectoryItem | null = null;
  @observable directoryType: string | null;
  @observable isLoading = false;

  @observable draftDirectory: TDirectoryItem[] = [];
  readonly directories: Directories;
  readonly directory: DirectoryStore;
  readonly notifications: NotificationsStore;
  readonly i18n: I18NextStore;
  readonly router: RouterStore;

  constructor(store: RootStore, directoryType?: string) {
    this.notifications = store.notifications;
    this.i18n = store.i18;
    this.router = store.router;
    this.directory = new DirectoryStore(store);
    this.directories = store.directories;
    this.directoryType = directoryType ?? null;

    makeObservable(this);
  }

  @action.bound
  setDefaultDraftDirectory(data: TDirectoryItem[]) {
    this.draftDirectory = data;
  }

  @action.bound
  setDraftDirectory(directoryItem: TDirectoryItem) {
    const index = this.draftDirectory.findIndex((item) => item.id === directoryItem.id);

    if (index !== -1) {
      this.draftDirectory[index] = directoryItem;
    } else {
      this.draftDirectory.push(directoryItem);
    }
  }

  @action.bound
  clearDraftDirectory() {
    this.draftDirectory.clear();
  }

  @action.bound
  setAvailableSpaceHeight(height: number) {
    this.availableSpaceHeight = height;
  }

  @action.bound
  openSidebar() {
    this.isFormSidebarOpened = true;
  }

  @action.bound
  closeSidebar() {
    this.isFormSidebarOpened = false;
    this.editingDirectory = null;
    this.copyingDirectory = null;
    this.directory.table.setCreatingFormType();
  }

  @action.bound
  setEditingDirectory(directory: TableRow) {
    if (!this.directoryType || !directory.status) return;

    const draftDir = this.draftDirectory;
    const currentDirectory = this.directory.table.rawTableData;
    const newDraftData = draftDir.filter((item) => typeof item.id === 'string');
    const editedDraftData = draftDir.filter((item) => typeof item.id === 'number');

    const editedDraftDataIds = editedDraftData.map(({ id }) => id);
    const collapsedDirectory = currentDirectory.map((item) => {
      if (editedDraftDataIds.includes(item.id)) {
        return editedDraftData.find((draftItem) => draftItem.id === item.id);
      }

      return item;
    });

    const joinedData = [...collapsedDirectory, ...newDraftData];
    const editingDirectory = joinedData?.find((item) => item?.id === directory.id);

    if (editingDirectory) {
      this.isFormSidebarOpened = true;
      this.editingDirectory = editingDirectory;
      this.directory.table.setEditFormType();
    }
  }

  @action.bound
  setCopyDirectory(directory: TableRow) {
    if (!this.directoryType || !directory.status) return;

    const copyingDirectory = this.directory.table.rawTableData?.find((item) => item.id === directory.id);
    if (copyingDirectory) {
      this.isFormSidebarOpened = true;
      this.copyingDirectory = copyingDirectory;
      this.directory.table.setCreatingFormType();
    }
  }

  @action.bound
  resetDraft() {
    this.clearDraftDirectory();
    this.directory.table.clearDraftTableData();
    this.directory.table.setEditMode(false);

    storage.DELETE(LOCAL_STORAGE.draftTableData);
    storage.DELETE(LOCAL_STORAGE.draftDirectoryData);
    storage.DELETE(LOCAL_STORAGE.lastDirectoryType);
  }

  @action.bound
  setDirectoryType(type: string) {
    this.directoryType = type;
  }

  clearStoredDraftEffect = () => {
    const localLastDirectoryName = storage.GET<string>(LOCAL_STORAGE.lastDirectoryType);

    if (localLastDirectoryName === this.directoryType) {
      const localDraftTableData = storage.GET<TableRow[]>(LOCAL_STORAGE.draftTableData);
      if (localDraftTableData !== null) {
        this.directory.table.setDraftTableData(localDraftTableData);
      }

      const localDraftDirectoryData = storage.GET<TDirectoryItem[]>(LOCAL_STORAGE.draftDirectoryData);

      if (localDraftDirectoryData !== null) {
        this.setDefaultDraftDirectory(localDraftDirectoryData);
      }

      this.directory.table.setEditMode(true);
    }

    storage.DELETE(LOCAL_STORAGE.lastDirectoryType);
    storage.DELETE(LOCAL_STORAGE.draftTableData);
    storage.DELETE(LOCAL_STORAGE.draftDirectoryData);
  };

  @flow.bound
  async *saveYearChanges(year: string) {
    const selectedRows = Object.entries(this.directory.table.selectedRows)
      .filter(([, selected]) => selected)
      .map(([key]) => Number(key));

    if (selectedRows.length === 0 || !this.directoryType) {
      return;
    }

    const data = this.directories.getDirectory(this.directoryType);

    try {
      await saveYearChanges(data, selectedRows, this.directoryType, year);
      yield;

      this.directory.fetchTableView(
        this.directoryType,
        this.directories.getTypeAttributes(this.directoryType),
        this.directories
      );
      this.directories.refreshDirectory(this.directoryType);
    } catch (error) {
      yield;

      console.error(error);
    }
  }

  @flow.bound
  async *saveChanges() {
    const data = this.draftDirectory;

    if (data.length === 0 || !this.directoryType) {
      return this.directory.table.setEditMode(false);
    }

    this.isLoading = true;

    try {
      await saveChanges(data, this.directoryType);
      yield;

      this.directory.table.resetTableData();
      this.directory.fetchTableView(
        this.directoryType,
        this.directories.getTypeAttributes(this.directoryType),
        this.directories
      );
      this.directories.refreshDirectory(this.directoryType);
      this.clearDraftDirectory();
      this.directory.table.clearDraftTableData();
      this.directory.table.setEditMode(false);

      storage.DELETE(LOCAL_STORAGE.draftTableData);
      storage.DELETE(LOCAL_STORAGE.draftDirectoryData);
      storage.DELETE(LOCAL_STORAGE.lastDirectoryType);
    } catch (error) {
      yield;
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }
}
