import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';

import { EllipsisTableCell } from 'src/shared/components/table-cell/ellipsis-table-cell';

import styles from './text-table-cell.module.scss';

interface Props {
  fieldValue: string | number | boolean;
  currentRowIndex: number;
  column: string;
  defaultActive?: boolean;
  onEditCell: (currentRowIndex: number, column: string, value: string | number) => void;
  editing?: boolean;
}

export const TextTableCell = observer(function TextTableCell({
  fieldValue,
  currentRowIndex,
  onEditCell,
  column,
  defaultActive,
  editing,
}: Props) {
  const [edit, setEdit] = useState<boolean>(!!defaultActive);

  const [value, setValue] = useState<string | number | undefined>(
    typeof fieldValue !== 'boolean' ? fieldValue : undefined
  );

  const inputRef = useRef<HTMLInputElement>(null);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    onEditCell(currentRowIndex, column, e.target.value);
  }

  function onSaveValue(): void {
    if (value) {
      onEditCell(currentRowIndex, column, value);
    }

    setEdit(false);
  }

  function onEnableEditingMode(): void {
    editing && setEdit(true);
  }

  useEffect(() => {
    if (edit) {
      inputRef.current!.focus();
    }
  }, [edit]);

  useEffect(() => {
    if (typeof fieldValue !== 'boolean') {
      setValue(fieldValue);
    }
  }, [fieldValue]);

  return (
    <form className={styles.container}>
      {edit ? (
        <input value={value} onChange={handleChange} onBlur={onSaveValue} ref={inputRef} />
      ) : (
        <EllipsisTableCell value={value} onClick={onEnableEditingMode} />
      )}
    </form>
  );
});
