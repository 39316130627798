import { observer } from 'mobx-react-lite';

import { ReactComponent as CopyIcon } from 'src/shared/assets/icons/copy-icon.svg';
import { ReactComponent as EditIcon } from 'src/shared/assets/icons/pencil-filled.svg';
import { Button } from 'src/shared/components/button';
import { Checkbox } from 'src/shared/components/checkbox';

import styles from './context-menu.module.scss';

type Props = {
  onEditClick: () => void;
  onCopyClick: () => void;
  onToggle: () => void;
  isCheckable?: boolean;
  isSelected?: boolean;
};

export const ContextMenu = observer(function ContextMenu({
  onEditClick,
  onCopyClick,
  onToggle,
  isCheckable = false,
  isSelected = false,
}: Props) {
  return (
    <div className={styles.wrapper}>
      <Button
        variant="default"
        icon={<EditIcon className={styles.icon} />}
        className={styles.menuItem}
        onClick={onEditClick}
      />

      <Button
        variant="default"
        icon={<CopyIcon className={styles.icon} />}
        className={styles.menuItem}
        onClick={onCopyClick}
      />

      {isCheckable && <Checkbox onChange={onToggle} isChecked={isSelected} />}
    </div>
  );
});
