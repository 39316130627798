import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { useStore } from 'src/store';

import styles from './styles.module.scss';

type Props = {
  isOpened: boolean;
};

function getLogoSrc(theme: 'light' | 'dark'): string {
  if (theme === 'light') {
    return window._env_.REACT_APP_LOGO_LIGHT_SRC || process.env.REACT_APP_LOGO_LIGHT_SRC || '';
  }

  return window._env_.REACT_APP_LOGO_DARK_SRC || process.env.REACT_APP_LOGO_DARK_SRC || '';
}
function getFullLogoSrc(theme: 'light' | 'dark'): string {
  if (theme === 'light') {
    return window._env_.REACT_APP_LOGO_FULL_LIGHT_SRC || process.env.REACT_APP_LOGO_FULL_LIGHT_SRC || '';
  }

  return window._env_.REACT_APP_LOGO_FULL_DARK_SRC || process.env.REACT_APP_LOGO_FULL_DARK_SRC || '';
}

export const Logo: FC<Props> = observer(function Logo({ isOpened }) {
  const {
    theme: { theme },
  } = useStore();

  return (
    <div className={styles.logoWrapper}>
      {isOpened ? (
        <img src={getFullLogoSrc(theme)} loading="eager" alt="Logo" className={styles.logoSvg} />
      ) : (
        <img src={getLogoSrc(theme)} loading="eager" alt="Logo" className={styles.logoSvg} />
      )}
    </div>
  );
});
