import { observer } from 'mobx-react-lite';

import { useStore } from 'src/store';

import styles from './directories-list.module.scss';
import { DirectoryCard } from './directory-card';

export const DirectoriesList = observer(function DirectoriesList() {
  const { directories } = useStore();

  if (directories.isLoading) return null;

  return (
    <div className={styles.cardsWrapper}>
      {directories.typesList.map((dictionary) => (
        <DirectoryCard key={dictionary.label} directory={dictionary} />
      ))}
    </div>
  );
});
