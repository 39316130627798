import { AxiosError } from 'axios';

import {
  BaseApiError,
  NetworkApiError,
  Server500ApiError,
  Unauthorized401ApiError,
  Validation400ApiErrorWithCause,
  NotFoundApiError,
  UnknownApiError,
  TValidationErrorData,
  ValidationError,
} from 'src/errors';

import { checkIsObjectWithKeys } from './checkIsObjectWithKeys';

function checkIsValidationErrorData(data: unknown): data is TValidationErrorData {
  if (checkIsObjectWithKeys(data)) {
    if (data['code'] === 'validation') {
      return true;
    }
  }
  return false;
}

export function throwApiError(e: unknown): never {
  if (e instanceof BaseApiError) {
    throw e;
  }

  if (e instanceof AxiosError) {
    if (e.response?.status && e.response.status >= 400 && e.response.status < 500) {
      if (checkIsValidationErrorData(e.response.data)) {
        throw new ValidationError({ ...e.response.data, error: e });
      }
    }
    if (!e.response) {
      throw new NetworkApiError(e);
    }
    if (e.response.status >= 500) {
      throw new Server500ApiError(e);
    }
    if (e.response.status === 401) {
      throw new Unauthorized401ApiError(e);
    }
    if (e.response.status === 400) {
      const messageRaw = e.response.data?.message;
      let parsedMessage = undefined;
      if (Array.isArray(messageRaw)) {
        if (typeof messageRaw[0] === 'string') {
          parsedMessage = messageRaw[0];
        }
      }
      if (typeof messageRaw === 'string') {
        parsedMessage = messageRaw;
      }
      throw new Validation400ApiErrorWithCause<unknown>(e, e.response.data, parsedMessage);
    }
    if (e.response.status === 404) {
      throw new NotFoundApiError(e);
    }
    throw new UnknownApiError(e);
  }
  throw e;
}
