import { default as KeycloakJS, KeycloakConfig, KeycloakTokenParsed } from 'keycloak-js';

import { agent } from 'src/api/user-agent';
import { AUTH_API_URL } from 'src/shared/constants/api';
import { KEYCLOAK_CLIENT_ID, KEYCLOAK_REALM_NAME } from 'src/shared/constants/auth';
import { getAccessToken, getRefreshToken, removeTokens, setTokens } from 'src/shared/lib/auth';

export namespace UserService {
  const keycloakConfig: KeycloakConfig = {
    realm: KEYCLOAK_REALM_NAME,
    clientId: KEYCLOAK_CLIENT_ID,
    url: AUTH_API_URL,
  };

  const keycloakAgent = new KeycloakJS(keycloakConfig);

  export async function initUserService(): Promise<void> {
    await keycloakAgent.init({
      flow: 'standard',
      scope: 'openid offline_access',
      token: getAccessToken() || undefined,
      refreshToken: getRefreshToken() || undefined,
    });
  }

  export async function initUserServiceWithCheck(): Promise<boolean> {
    await keycloakAgent.init({
      flow: 'standard',
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      pkceMethod: 'S256',
      scope: 'openid offline_access',
      refreshToken: getRefreshToken() || undefined,
    });

    if (keycloakAgent.authenticated && keycloakAgent.token) {
      setTokens(keycloakAgent.token, keycloakAgent.refreshToken);

      return keycloakAgent.authenticated;
    }

    if (keycloakAgent.refreshToken) {
      const accessToken = await updateToken();

      setTokens(accessToken);

      return keycloakAgent.authenticated || false;
    }

    await login();

    return false;
  }

  export async function updateToken(): Promise<string | undefined> {
    try {
      const refreshed = await keycloakAgent.updateToken(-1);

      const { token, refreshToken } = keycloakAgent;

      if (refreshed && token) {
        setTokens(token, refreshToken);

        return token;
      } else {
        throw new Error('Failed to refresh token or new token is undefined.');
      }
    } catch (e) {
      console.error(e);

      await login();
    }
  }

  export async function login(): Promise<void> {
    removeTokens();
    await keycloakAgent.login();
  }

  export async function getUserData(): Promise<{} | undefined> {
    const { data } = await agent.get(`/realms/${KEYCLOAK_REALM_NAME}/protocol/openid-connect/userinfo`);

    return data;
  }

  export async function logout(): Promise<void> {
    removeTokens();
    await keycloakAgent.logout();
  }

  export function getParsedToken(): KeycloakTokenParsed | undefined {
    return keycloakAgent.tokenParsed;
  }
}
