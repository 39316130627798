import { TRefQuery } from 'src/store/directories/types';

type TDeps = {
  fieldId: string[];
  attrs: string[];
};

export const getDynamicJoinDependencies = (refQuery: TRefQuery): TDeps => {
  const dependencies: TDeps = {
    fieldId: [],
    attrs: [],
  };

  const stringifiedRefQuery = JSON.stringify(refQuery);

  const values = stringifiedRefQuery.matchAll(/([$%]){([0-9A-Za-z.-_]+)}/g);
  for (const value of values) {
    const type = value[1];
    const valueKey = value[2];
    switch (type) {
      case '$': {
        dependencies.attrs.push(valueKey);
        break;
      }
      case '%': {
        dependencies.fieldId.push(valueKey);
        break;
      }
    }
  }

  return dependencies;
};
