import { makeObservable, observable, action } from 'mobx';

import { TRequiredIf } from 'src/api/directory/types';

import { IRestrictions } from '../../types';
import { TextValidation } from '../../validation/text-validation';
import { Field } from '../abstract-entities';
import { TControlValueType, TEnableIf } from '../types';

export type TTextFieldData = {
  formElementRefId: string;
  fieldId?: string;
  required: boolean;
  objectName?: string;
  unit?: string;
  defaultValueLink?: string;
  enableIf?: TEnableIf[];
  initialValue?: string;
  restrictions: IRestrictions;
  requiredIf?: TRequiredIf[];
  type: TControlValueType;
};

export class TextField extends Field<string> {
  @observable value: string;
  readonly validation: TextValidation;

  constructor(data: TTextFieldData) {
    super(data);
    this.value = data.initialValue || '';
    this.validation = new TextValidation(this, data.restrictions);

    makeObservable(this);
  }

  checkIsReady(): boolean {
    if (!!this.errorText) return false;
    return this.value !== '';
  }

  @action.bound
  clearError() {
    this.errorText = undefined;
  }

  @action.bound
  validate() {
    this.value = this.value.trim();
    this.errorText = this.validation.validate(this.value.trim());
  }

  @action
  setValue(value: string) {
    if (!value) {
      this.value = '';
      return;
    }
    this.value = value;
  }

  @action.bound
  resetControl(): void {
    this.value = '';
    this.clearError();
  }
}
