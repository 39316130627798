import { action, makeObservable, observable } from 'mobx';

import { RootStore } from 'src/store';

import { FormStore } from '../entities/form.entity';

import { IFormPlugin } from './abstract-form-plugin.entity';

export class PluginsManager {
  readonly target: FormStore;
  private readonly rootStore: RootStore;
  private pluginsDisposers: Map<number, VoidFunction> = observable.map();

  constructor(target: FormStore, rootStore: RootStore, plugins: IFormPlugin[] = []) {
    this.target = target;
    this.rootStore = rootStore;
    this.connectPluginsWithTarget(plugins);

    makeObservable(this);
  }

  @action.bound
  private connectPluginsWithTarget(plugins: IFormPlugin[] = []) {
    plugins.forEach((plugin) => {
      const disposer = plugin.connect();

      if (disposer) {
        this.pluginsDisposers.set(plugin.pluginId, disposer);
      }
    });
  }

  @action.bound
  disposePlugins(): void {
    this.pluginsDisposers.forEach((disposer) => disposer());
    this.pluginsDisposers.clear();
  }
}
