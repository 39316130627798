import { action, makeObservable, observable } from 'mobx';

import { TCompareFn } from 'src/shared/utils/compare-values';
import { TRestriction } from 'src/store/directories/types';

import { Control, ValidatableItem } from '../entities/abstract-entities';

export class Validation<T> {
  readonly control: ValidatableItem<T>;

  refControls = observable.map<string, Control>();

  constructor(control: ValidatableItem<T>) {
    this.control = control;

    makeObservable(this);
  }

  @action.bound
  addRefControl(fieldId: string, control: Control) {
    this.refControls.set(fieldId, control);
  }

  protected processMinMaxRestrictrions(restrictions: TRestriction[], compareFn: TCompareFn<number>): number | null {
    let value: number | null = null;

    restrictions.forEach((restriction) => {
      if (restriction.type === 'CONST') {
        if (value === null || compareFn(value, restriction.value)) {
          value = restriction.value;
        }
      }

      if (restriction.type === 'ATTR') {
        const refControl = this.refControls.get(restriction.value);
        if (refControl && (typeof refControl.value === 'number' || refControl.value === null)) {
          if (value === null || (refControl.value && compareFn(value, refControl.value))) {
            value = refControl.value;
          }
        }
      }
    });

    return value;
  }
}
