import { ReactComponent as BookIcon } from 'src/shared/assets/icons/book-checked.svg';
import { ROUTES } from 'src/shared/constants/routes';

export const navProps = [
  {
    name: 'Nav.dicts',
    to: ROUTES.dicts,
    renderIcon: (className: string) => <BookIcon className={className} />,
  },
];
