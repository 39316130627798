import { reaction } from 'mobx';

import { getRandomNumber } from 'src/shared/utils/get-random-number';

import { Control, ValidatableItem } from '../../entities/abstract-entities';
import { FormStore } from '../../entities/form.entity';
import { IFormPlugin } from '../abstract-form-plugin.entity';

export class RequiredIfPlugin implements IFormPlugin {
  pluginId: number;
  form: FormStore;

  constructor(formStore: FormStore) {
    this.form = formStore;
    this.pluginId = getRandomNumber();
  }

  connect(): VoidFunction {
    const disposers: VoidFunction[] = [];

    const processItem = (item: Control) => {
      if (item instanceof ValidatableItem && item.requiredIf) {
        const disposer = reaction(
          () => {
            return item.requiredIf?.map((requiredIf) => {
              const control = this.form.fields[requiredIf.control];
              return [control.value, requiredIf.value];
            });
          },
          (values) => {
            item.setIsRequired(!!values?.some(([controlValue, requiredIfValue]) => controlValue === requiredIfValue));
          },
          { fireImmediately: true }
        );

        disposers.push(disposer);
      }
    };

    this.form.processFormFields(processItem);

    return () => {
      disposers.forEach((disposer) => disposer());
    };
  }
}
