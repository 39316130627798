import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren, CSSProperties, useEffect, useRef, useState } from 'react';

import styles from './unmount-with-transition.module.scss';

type Props = PropsWithChildren<{
  isShown: boolean;
  className?: string;
  noAnimationDelayOnFirstRender?: boolean;
  unmountStylesObj?: CSSProperties;
  mountStylesObj?: CSSProperties;
  unmountStylesClass?: string;
  mountStylesClass?: string;
  mountDelay?: number;
  unmountDelay?: number;
}>;

export const UnmountWithTransition = observer(function UnmountWithTransition({
  isShown,
  className,
  noAnimationDelayOnFirstRender = false,
  unmountStylesObj,
  mountStylesObj,
  unmountStylesClass,
  mountStylesClass,
  mountDelay,
  unmountDelay = 200,
  children,
}: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isMounted, setIsMounted] = useState(false);
  const [inlineStyles, setInlineStyles] = useState(unmountStylesObj);
  const [isFirstRender, setIsFirstRender] = useState(noAnimationDelayOnFirstRender);

  useEffect(() => {
    let timer: number;

    if (isShown && !isMounted) {
      if (mountDelay === undefined || isFirstRender) {
        window.requestAnimationFrame(() => {
          isFirstRender && setIsFirstRender(false);
          setIsMounted(true);
        });
      }
      if (mountDelay) {
        timer = window.setTimeout(() => window.requestAnimationFrame(() => setIsMounted(true)), mountDelay);
      }
    }

    if (!isShown && isMounted) {
      if (unmountDelay === undefined) setIsMounted(false);
      if (unmountDelay) {
        timer = window.setTimeout(() => setIsMounted(false), unmountDelay);
      }
    }

    return () => clearTimeout(timer);
  }, [isMounted, isFirstRender, isShown, mountDelay, unmountDelay]);

  useEffect(() => {
    if (mountStylesClass) {
      const styles = mountStylesClass.split(' ');
      if (isShown && isMounted) {
        styles.forEach((className) => containerRef.current?.classList.add(className));
      }

      if (!isShown && isMounted) {
        styles.forEach((className) => containerRef.current?.classList.remove(className));
      }
    }

    if (mountStylesObj) {
      if (isShown && isMounted) {
        setInlineStyles(mountStylesObj);
      }

      if (!isShown && isMounted) {
        setInlineStyles(unmountStylesObj);
      }
    }
  }, [mountStylesObj, unmountStylesObj, mountStylesClass, isShown, isMounted]);

  if (!isMounted) return null;

  return (
    <div
      ref={containerRef}
      className={clsx(styles.container, className, !!unmountStylesClass && unmountStylesClass)}
      style={inlineStyles}
    >
      {children}
    </div>
  );
});
