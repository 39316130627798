import { observer } from 'mobx-react-lite';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';

import { FormDatePicker } from 'src/shared/components/form-components';

import { YearField } from '../../entities/control-entities/year-field.entity';

type Props = {
  item: YearField;
  label?: string;
  onChange?: (val: Moment) => void;
  isDisabled?: boolean;
  className?: string;
};

export const YearPickerControl = observer(function YearPickerControl({
  item,
  label,
  isDisabled,
  className,
  onChange,
}: Props) {
  const { t } = useTranslation();

  const onYearChange = (val: Moment) => {
    item.changeValue(val);
    onChange?.(val);
  };
  return (
    <div>
      <FormDatePicker
        disabled={isDisabled}
        value={item.year}
        label={label}
        placeholder={t('directory:Controls.chooseYear')}
        picker="year"
        format="YYYY"
        ghostLabel
        allowClear
        onChange={onYearChange}
        inputClassName={className}
      />
    </div>
  );
});
