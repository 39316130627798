import { Tooltip as AntdTooltip } from 'antd';
import { RenderFunction, TooltipPlacement } from 'antd/lib/tooltip';
import { observer } from 'mobx-react-lite';
import React, { CSSProperties, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  text: ReactNode | RenderFunction;
  visible?: boolean;
  onVisibleChange?(visible: boolean): void;
  trigger?: 'hover' | 'focus' | 'click' | 'contextMenu' | Array<'hover' | 'focus' | 'click' | 'contextMenu'>;
  placement?: TooltipPlacement;
  destroyTooltipOnHide?: boolean | { keepParent?: boolean };
  className?: string;
  overlayStyle?: CSSProperties;
  overlayInnerStyle?: CSSProperties;
}

export const Tooltip = observer(function Tooltip({
  trigger,
  text,
  placement,
  children,
  visible,
  className,
  destroyTooltipOnHide,
  onVisibleChange,
  overlayInnerStyle,
  overlayStyle,
}: Props) {
  return (
    <AntdTooltip
      visible={visible}
      onVisibleChange={onVisibleChange}
      trigger={trigger}
      overlayStyle={overlayStyle}
      overlayInnerStyle={overlayInnerStyle}
      title={text}
      placement={placement}
      destroyTooltipOnHide={destroyTooltipOnHide}
      className={className}
    >
      {children}
    </AntdTooltip>
  );
});
