import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Modal } from 'src/shared/components/modal/modal';

import { DragArea } from './drag-area/drag-area';
import styles from './import-modal.module.scss';

import './import-bg.scss';

type Props = {
  isOpened: boolean;
  isLoading: boolean;
  onClose: () => void;
  onUpload: (file: File, directoryName: string) => void;
};

export const ImportModal = observer(function ImportModal({ isOpened, isLoading, onClose, onUpload }: Props) {
  const { t } = useTranslation();
  const { directory } = useParams();

  function handleUpload(file: File): void {
    if (!directory) {
      return;
    }

    onUpload(file, directory);
  }

  return (
    <Modal title={t('directory:ImportModal.title')} isOpened={isOpened} handleClose={onClose}>
      <p className={styles.text}>{t('directory:ImportModal.description')}</p>
      <DragArea isLoading={isLoading} onFile={handleUpload} />
    </Modal>
  );
});
