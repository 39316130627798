import { observer } from 'mobx-react-lite';

import { ReactComponent as ArrowIcon } from 'src/shared/assets/icons/arrow-with-tail.svg';
import { ReactComponent as AttentionIcon } from 'src/shared/assets/icons/attention.svg';
import { ReactComponent as PlusIcon } from 'src/shared/assets/icons/plus.svg';
import { ReactComponent as ReplaceIcon } from 'src/shared/assets/icons/replace-arrows.svg';
import { UnmountWithTransition } from 'src/shared/components/core/unmount-with-transition/unmount-with-transition';

import styles from './matching-row.module.scss';

type Props = {
  isOver: boolean;
  isEmpty: boolean;
  isRequired: boolean;
};

export const Icons = observer(function Icons({ isOver, isEmpty, isRequired }: Props) {
  return (
    <>
      <UnmountWithTransition
        isShown={!isOver && isEmpty && isRequired}
        mountDelay={200}
        mountStylesObj={{ opacity: 1 }}
        unmountStylesObj={{ opacity: 0 }}
      >
        <AttentionIcon />
      </UnmountWithTransition>
      <UnmountWithTransition
        isShown={(!isOver && !isEmpty && isRequired) || (!isRequired && !isOver)}
        mountDelay={200}
        mountStylesObj={{ opacity: 1 }}
        unmountStylesObj={{ opacity: 0 }}
      >
        <ArrowIcon className={styles.icon} />
      </UnmountWithTransition>
      <UnmountWithTransition
        className={styles.plusIcon}
        isShown={isOver && !isEmpty}
        mountDelay={200}
        mountStylesObj={{ opacity: 1 }}
        unmountStylesObj={{ opacity: 0 }}
      >
        <ReplaceIcon className={styles.icon} />
      </UnmountWithTransition>
      <UnmountWithTransition
        className={styles.plusIcon}
        isShown={isOver && isEmpty}
        mountDelay={200}
        mountStylesObj={{ opacity: 1 }}
        unmountStylesObj={{ opacity: 0 }}
      >
        <PlusIcon className={styles.plusIcon} width={24} height={24} />
      </UnmountWithTransition>
    </>
  );
});
