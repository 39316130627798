import { MultiCombobox } from 'src/features/directory-form/entities/control-entities/multicombobox.entity';
import { Directories } from 'src/store/directories/directories.store';
import { TDirectoryTypeAttribute } from 'src/store/directories/types';

import { TMultiComboboxRaw } from '../types';

export const mapMultiComboboxItem = (
  item: TMultiComboboxRaw,
  attribute: TDirectoryTypeAttribute | undefined,
  directories: Directories
): MultiCombobox => {
  const data = {
    formElementRefId: item.attrName,
    directories,
    label: attribute?.defaultLabel,
    fieldId: attribute?.name,
    refObjectAttr: attribute?.refObjectAttr,
    refObjectType: attribute?.refObjectType,
    enableIf: item.enableIf,
    showIf: item.showIf,
    defaultValueLink: item.defaultValueLink,
    restrictions: { required: !!attribute?.required, ...attribute?.restrictions },
    refQuery: item.refQuery,
    refQueryVariables: item.refQueryVariables,
    directory: item.refQuery ? directories.getJoinedObject(item.refQuery) : undefined,
    filterByControlValue: item.filterByControlValue,
    requiredIf: item.requiredIf,
    type: item.type,
    optionsSorting: attribute?.optionsSorting,
  };

  return new MultiCombobox(data);
};
