import { Label } from 'src/features/directory-form/entities/control-entities/label.entity';
import { Directories } from 'src/store/directories/directories.store';
import { TDirectoryTypeAttribute } from 'src/store/directories/types';

import { TLabelRaw } from '../types';

export const mapLabelItem = (
  item: TLabelRaw,
  attribute: TDirectoryTypeAttribute | undefined,
  directories: Directories
) => {
  return new Label({
    label: item.label || attribute?.defaultLabel,
    type: item.type,
    formElementRefId: item.attrName,
    fieldId: attribute?.name,
    requiredIf: item.requiredIf,
    delimiter: item.delimiter,
    attrConcat: item.attrConcat,
    attrConcatRefs: item.attrConcatRefs,
    refObjectAttr: attribute?.refObjectAttr,
    refObjectType: attribute?.refObjectType,
    directories,
  });
};
