import { observer } from 'mobx-react-lite';
import React, { PropsWithChildren } from 'react';

import { RootStore } from './root-store';
import { RootStoreContext } from './root-store-context';

interface Props extends PropsWithChildren<{}> {
  store: RootStore;
}

export const RootStoreProvider = observer(function RootStoreProvider({ children, store }: Props) {
  return <RootStoreContext.Provider value={store}>{children}</RootStoreContext.Provider>;
});
