import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';

import { checkIsNode } from 'src/shared/utils/check-is-node';
import { checkIsPortableDevice } from 'src/shared/utils/check-is-portable-device';
import { useStore } from 'src/store';

import { Logo } from './logo/logo';
import { Navigation } from './navigation/navigation';
import styles from './styles.module.scss';
import { Support } from './support/support';
import { Switchers } from './switchers/switchers';
import { UserSection } from './user-section/user-section';

export const Sidebar: React.FC = observer(function Sidebar() {
  const { sidebar, auth } = useStore();
  const isOpened = sidebar.isOpened;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!checkIsNode(event.target)) return;
      if (ref.current!.contains(event.target)) return;
      sidebar.close();
    };

    document.body.addEventListener('pointerdown', handleClickOutside);

    return () => {
      document.body.removeEventListener('pointerdown', handleClickOutside);
    };
  }, [sidebar]);

  return (
    <div
      ref={ref}
      className={clsx(
        styles.wrapper,
        !isOpened && styles.wrapper__closed,
        !isOpened && checkIsPortableDevice() && styles.wrapper__closed_portable
      )}
    >
      <div>
        <Logo isOpened={isOpened} />
        <Navigation isOpened={isOpened} />
      </div>
      <div>
        <Support isOpened={isOpened} />
        <Switchers isOpened={isOpened} />
        <UserSection isOpened={isOpened} userInfo={auth.userInfo} onLogout={auth.logout} />
      </div>
      <button className={clsx(styles.closeButton, isOpened && styles.closeButton__opened)} onClick={sidebar.close} />
    </div>
  );
});
