import { action, flow, makeObservable, observable } from 'mobx';

import { getFiltersData, getTableView, getTypesList } from 'src/api/directory/requests';
import { getFilterData, getTableColumns } from 'src/api/directory/utils';
import { RootStore } from 'src/store';
import { Directories } from 'src/store/directories/directories.store';
import { TDirectoryTypeAttribute, TDirectoryTypeItem, TTableColumnRaw } from 'src/store/directories/types';
import { I18NextStore } from 'src/store/i18next/i18next-store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';
import { TableStore as Table } from 'src/store/table/';

export class DirectoryStore {
  private readonly directories: Directories;
  private readonly notifications: NotificationsStore;
  private readonly i18: I18NextStore;

  @observable table: Table;
  @observable loading: boolean = false;
  @observable relatedDirectories: TDirectoryTypeItem[] = [];

  constructor(rootStore: RootStore) {
    this.directories = rootStore.directories;
    this.notifications = rootStore.notifications;
    this.i18 = rootStore.i18;
    this.table = new Table(rootStore);
    makeObservable(this);
  }

  @flow.bound
  private async *fetchFiltersData(directory: string, view: TTableColumnRaw[]) {
    try {
      const rawFilterData = await getFiltersData(directory, { includeInactive: true });
      yield;

      const filterData = getFilterData(rawFilterData, view, this.directories, directory);
      this.table.setTableFilterValues(filterData);
    } catch (e) {
      yield;
      this.notifications.showNotificationMessage(this.i18.t('directory:Errors.fetchFilters'));
      console.error(e);
    }
  }

  @action.bound
  setRelatedDirectories(directories: TDirectoryTypeItem[]) {
    this.relatedDirectories = directories;
  }

  @flow.bound
  async *fetchTableView(dictionary: string, attributes: TDirectoryTypeAttribute[], directories: Directories) {
    if (this.loading) return;

    this.loading = true;

    try {
      const _relatedDirectories = await getTypesList(dictionary);
      const view = await getTableView(dictionary);
      yield;

      await this.fetchFiltersData(dictionary, view.columns);
      yield;

      const tableColumns = getTableColumns(view.columns, attributes, dictionary);

      this.setRelatedDirectories(_relatedDirectories);
      this.table.setDirectoryType(dictionary);
      this.table.setTableView(view);
      this.table.setAttributes(attributes);
      this.table.setColumnsData(tableColumns);
      await this.table.fetchTableData();
      yield;
    } catch (error) {
      yield;

      console.error(error);
    } finally {
      this.loading = false;
    }
  }
}
