import { action, makeObservable, observable } from 'mobx';

import i18n from 'src/i18n';
import { LANGUAGES, LOCKED_LANG } from 'src/shared/constants/languages';

export class LanguageStore {
  @observable language: string;
  @observable isLanguageSwitcherHidden = false;

  constructor() {
    this.language = i18n.language;

    makeObservable(this);
  }

  private _setLanguage(lan: string) {
    this.language = lan;
  }

  @action.bound
  toggle() {
    this._setLanguage(this.language === LANGUAGES.ru ? LANGUAGES.en : LANGUAGES.ru);
    i18n.changeLanguage(this.language);
  }

  @action.bound
  init() {
    if (LOCKED_LANG) {
      i18n.changeLanguage(LOCKED_LANG);
      this._setLanguage(LOCKED_LANG);
      this.isLanguageSwitcherHidden = true;
    }
  }
}
