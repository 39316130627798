import { reaction } from 'mobx';

import { hasValue } from 'src/shared/lib/common';
import { assert } from 'src/shared/utils/assert';
import { getConcatedName } from 'src/shared/utils/get-concated-name';
import { getRandomNumber } from 'src/shared/utils/get-random-number';
import { Directories } from 'src/store/directories/directories.store';

import { Control } from '../../entities/abstract-entities';
import { Label } from '../../entities/control-entities/label.entity';
import { FormStore } from '../../entities/form.entity';
import { DynamicFormControllsManager, IFormPlugin } from '../abstract-form-plugin.entity';

export class LabelConcatedValue implements IFormPlugin {
  readonly pluginId = getRandomNumber();
  readonly form: FormStore;
  readonly directories: Directories;
  private readonly controllsManager: DynamicFormControllsManager;

  constructor(form: FormStore, directories: Directories) {
    this.form = form;
    this.controllsManager = new DynamicFormControllsManager(form);
    this.directories = directories;
  }

  connect(): VoidFunction {
    const processItem = (item: Control): VoidFunction | void => {
      if (item instanceof Label && item.attrConcat) {
        const controllingControlsAttrNames = item.attrConcat.map((attrName) => {
          if (attrName.includes('$')) {
            const cleanAttr = attrName.slice(2, -1);

            if (item.attrConcatRefs) {
              const refs = item.attrConcatRefs[cleanAttr];

              assert(refs, `concat refs for ${cleanAttr} are not presetned`);

              if (refs.valueSourceAttrName) {
                return refs.valueSourceAttrName;
              }
            }

            return cleanAttr;
          }

          return attrName;
        });

        const controls: Control[] = [];

        controllingControlsAttrNames.forEach((attrName) => {
          const control = (() => {
            if (item.parentControl) {
              const control = item.parentControl.fields[attrName];

              if (control) {
                return control;
              }
            }

            return this.form.fields[attrName];
          })();
          if (control) {
            controls.push(control);
          }
        });

        const disposer = reaction(
          () => {
            const data: Record<string, unknown> = {};

            controls.forEach((control) => {
              const controlAttrName = control.formElementRefId;

              assert(hasValue(controlAttrName), 'control attrName is not presented');

              data[controlAttrName] = control.value;
            });

            return data;
          },
          (data) => {
            const concatedValue = getConcatedName(this.directories, item, data);

            if (hasValue(concatedValue)) {
              if (item.refObjectType && item.refObjectAttr) {
                assert(hasValue(item.value), 'item value is not presented');

                item.setValueWithLabel({
                  label: concatedValue,
                  value: item.value,
                });
              } else {
                item.setValueWithLabel({
                  label: concatedValue,
                  value: concatedValue,
                });
              }
            } else {
              item.setValue(null);
            }
          },
          { fireImmediately: true }
        );

        return disposer;
      }
    };

    const disposer = this.controllsManager.processItems(processItem);

    return () => {
      disposer();
    };
  }
}
