import { Column } from './column.entity';

type TGroupOptions = {
  id: number;
  label?: string;
  columns: Column[];
};

export class Group {
  readonly label?: string;
  readonly id: number;
  readonly columns: readonly Column[];

  constructor(data: TGroupOptions) {
    this.id = data.id;
    this.label = data.label;
    this.columns = data.columns;
  }
}
