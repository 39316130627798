import { makeObservable, observable, action, computed, autorun } from 'mobx';

import { TComboboxFilter, TRequiredIf } from 'src/api/directory/types';
import { hasValue } from 'src/shared/lib/common';
import { Directories } from 'src/store/directories/directories.store';
import { TComboboxOptionsSorting, TDirectoryItem, TOption } from 'src/store/directories/types';

import { IRestrictions } from '../../types';
import { ValidatableItem } from '../abstract-entities';
import { TControlValueType, TEnableIf } from '../types';

type TRegularComboBoxData = {
  formElementRefId: string;
  directories: Directories;
  fieldId?: string;
  objectName?: string;
  defaultValueLink?: string;
  refExternalSource?: string;
  refObjectType?: string;
  refObjectAttr?: string;
  refObjectFilter?: Record<string, string | number | boolean>;
  restrictions: IRestrictions;
  enableIf?: TEnableIf[];
  type: TControlValueType;
  requiredIf?: TRequiredIf[];
  filterByControlValue?: TComboboxFilter;
  optionsSorting?: TComboboxOptionsSorting;
};

export class RegularComboBox extends ValidatableItem<string | number | null> {
  refExternalSource?: string;
  refObjectType?: string;
  refObjectAttr?: string;
  refObjectFilter?: Record<string, string | number | boolean>;
  readonly optionsSorting?: TComboboxOptionsSorting;
  readonly directories: Directories;
  readonly filterByControlValue?: TComboboxFilter;

  @observable value: string | number | null;
  @observable isFormSidebarOpened: boolean = false;
  @observable valuesThatShouldBeExcluded: (string | number)[] = [];

  constructor(data: TRegularComboBoxData) {
    super(data);
    this.refExternalSource = data?.refExternalSource;
    this.directories = data.directories;
    this.filterByControlValue = data.filterByControlValue;
    this.refObjectAttr = data.refObjectAttr;
    this.refObjectType = data.refObjectType;
    this.refObjectFilter = data.refObjectFilter;
    this.optionsSorting = data.optionsSorting;

    this.value = null;

    makeObservable(this);
  }

  @computed
  get options(): TOption[] {
    const options = this.processOptionsDataToOptions(
      this.directories.getDirectory(this.refObjectType),
      this.refObjectAttr,
      this.refObjectFilter
    ).filter((option) => !this.valuesThatShouldBeExcluded.includes(option.value));

    if (this.optionsSorting) {
      return options.sort((a, b) => {
        const aLabel = a.label.toLowerCase();
        const bLabel = b.label.toLowerCase();

        if (this.optionsSorting === 'ASC') {
          return aLabel.localeCompare(bLabel);
        }

        return bLabel.localeCompare(aLabel);
      });
    }

    return options;
  }

  @action.bound
  openNewFormSidebar() {
    this.isFormSidebarOpened = true;
  }

  @action.bound
  closeNewFormSidebar() {
    this.isFormSidebarOpened = false;
  }

  @action.bound
  setValuesThatShouldBeExcluded(values: (string | number)[]) {
    this.valuesThatShouldBeExcluded = values;
  }

  private processOptionsDataToOptions(
    data: TDirectoryItem[],
    refObjectAttr: string | undefined,
    filter?: Record<string, string | number | boolean>
  ): TOption[] {
    if (!refObjectAttr) return [];

    const filteredData = filter
      ? data.filter((item) => {
          for (const key in filter) {
            const value = item.data[key];
            if (!value || typeof value === 'object') {
              return false;
            }
            if (value.toString() !== filter[key].toString()) {
              return false;
            }
          }

          return true;
        })
      : data;

    const options: TOption[] = [];

    for (const item of filteredData) {
      const label = item.data[refObjectAttr];

      if (
        typeof label !== 'string' &&
        (typeof label !== 'number' || (typeof label === 'number' && Number.isNaN(Number(label))))
      ) {
        continue;
      }

      options.push({
        label: label.toString(),
        value: item.id,
      });
    }

    return options;
  }

  @action.bound
  setValue(value: string | number | null | undefined) {
    if (!hasValue(value)) {
      this.value = null;
      return;
    }
    this.value = value;
  }

  @action.bound
  resetControl(): void {
    this.value = null;
    this.clearError();
  }

  checkIsReady(): boolean {
    if (!!this.errorText) return false;
    return !!this.value;
  }

  @action.bound
  clearError(): void {
    this.errorText = undefined;
  }

  @action.bound
  validate = () => {
    if (!this.value && this.restrictions?.required) {
      this.errorText = 'directory:Errors.required';
    }
  };

  private checkIsCurrentValuePresentedInOptionsList(): VoidFunction {
    return autorun(() => {
      if (this.value && !this.options.find((option) => option.value === this.value)) {
        this.resetControl();
      }
    });
  }

  effect = () => {
    const checkIsCurrentValuePresentedInOptionsListDisposer = this.checkIsCurrentValuePresentedInOptionsList();

    return () => {
      checkIsCurrentValuePresentedInOptionsListDisposer();
    };
  };
}
