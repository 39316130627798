import { observer } from 'mobx-react-lite';

import { Switcher } from 'src/shared/components/switcher/switcher';

import styles from './switcher-table-cell.module.scss';

interface Props {
  isToggled: boolean;
  onEditCell: (currentRowIndex: number, column: string, value: boolean, id?: number, type?: string) => void;
  currentRowIndex: number;
  column: string;
  id?: number;
  type?: string;
  disabled?: boolean;
}

export const SwitcherTableCell = observer(function EditableTextField({
  id,
  type,
  isToggled,
  onEditCell,
  disabled,
  currentRowIndex,
  column,
}: Props) {
  const handleChange = () => {
    onEditCell(currentRowIndex, column, !isToggled, id, type);
  };

  return (
    <div className={styles.container}>
      <Switcher isActive={isToggled} onToggle={handleChange} isDisabled={disabled} />
    </div>
  );
});
