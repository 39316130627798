import { makeObservable, observable } from 'mobx';

import { Control } from './abstract-entities';
import { TColumnOptions } from './types';

export class Column {
  @observable rows: Control[];
  id: number;

  constructor({ id, rows }: TColumnOptions) {
    this.rows = rows;
    this.id = id;

    makeObservable(this);
  }
}
