import { Select, Spin } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { CSSProperties, JSXElementConstructor, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowIcon } from 'src/shared/assets/icons/arrow-drop-icon.svg';

import { InputError } from '../input-error';
import { InputLabel } from '../input-label';

import { DropdownPreloader } from './dropdown-preloader';
import styles from './form-select.module.scss';

type Props = {
  open?: boolean;
  allowClear?: boolean;
  className?: string;
  placeholder?: string;
  searchValue?: string;
  value?: string | number | null;
  options?: DefaultOptionType[];
  label?: string;
  loading?: boolean;
  showSearch?: boolean;
  filterOption?: boolean;
  errorText?: string | [string, { [locKey: string]: string | number }];
  isDisabled?: boolean;
  dropdownLoading?: boolean;
  customDropdown?: ReactElement<any, string | JSXElementConstructor<any>>;
  dataTestId?: string;
  optionFilterProp?: string;
  dropdownStyle?: CSSProperties;
  onBlur?: () => void;
  onClear?: () => void;
  onFocus?: () => void;
  onSearch?: (value: string) => void;
  onChange?: (value: string | number) => void;
  onSelect?: (value: string | number) => void;
  onClick?: () => void;
  onDropdownVisibleChange?: (open: boolean) => void;
  dropdownRender?: (
    origin: ReactElement<any, string | JSXElementConstructor<any>>
  ) => ReactElement<any, string | JSXElementConstructor<any>>;
  getPopupContainer?(props: any): HTMLElement;
};

/** Will only be correctly styled if wrapped Form component. */
export const FormSelect = observer(function FormSelect({
  open,
  allowClear = true,
  className,
  placeholder,
  searchValue,
  value,
  label,
  options,
  errorText,
  loading,
  showSearch,
  isDisabled,
  filterOption,
  dropdownLoading,
  customDropdown,
  dropdownStyle,
  optionFilterProp,
  dropdownRender: dropdownRenderProp,
  dataTestId,
  onBlur,
  onSearch,
  onFocus,
  onSelect,
  onClear,
  onChange,
  onClick,
  onDropdownVisibleChange,
  getPopupContainer,
}: Props) {
  const { t } = useTranslation();

  function dropdownRender(
    origin: ReactElement<any, string | JSXElementConstructor<any>>
  ): ReactElement<any, string | JSXElementConstructor<any>> {
    if (dropdownLoading) return <DropdownPreloader />;
    if (!!dropdownRenderProp) return dropdownRenderProp(origin);
    if (customDropdown) return customDropdown;
    return origin;
  }

  return (
    <div className={clsx(styles.wrapper, className)}>
      {!!label && <InputLabel text={label} />}
      <Select
        open={open}
        value={value}
        data-testid={dataTestId}
        allowClear={allowClear}
        searchValue={searchValue}
        placeholder={loading ? 'Подождите, данные загружаются' : placeholder || t('directory:Controls.chooseValue')}
        className={clsx(styles.select, !!errorText && styles.select__error)}
        bordered={false}
        suffixIcon={
          loading ? (
            <div className={styles.loaderWrapper}>
              <Spin size="small" spinning />
            </div>
          ) : (
            <ArrowIcon className={styles.icon} fill="var(--contrast)" transform="rotate(90)" />
          )
        }
        showSearch={showSearch}
        disabled={isDisabled || loading}
        filterOption={filterOption}
        dropdownStyle={dropdownStyle}
        optionFilterProp={optionFilterProp}
        dropdownRender={dropdownRender}
        options={options}
        onClear={onClear}
        onSelect={onSelect}
        onFocus={onFocus}
        onBlur={onBlur}
        onSearch={onSearch}
        onChange={onChange}
        onClick={onClick}
        onDropdownVisibleChange={onDropdownVisibleChange}
        getPopupContainer={getPopupContainer}
      />
      {!!errorText && <InputError errorTextRaw={errorText} />}
    </div>
  );
});
