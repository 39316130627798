import { useDroppable } from '@dnd-kit/core';

import { MatchingImportStore } from '../import-matching-page.store';

export const useInvalidValuesModel = (matchingImport: MatchingImportStore) => {
  const { active, isOver, setNodeRef } = useDroppable({ id: 'heap' });
  const isFromHeap = !active?.data.current?.from;

  return {
    values: matchingImport.unmatchedHeaders,
    isFromHeap,
    isOver,
    setNodeRef,
  };
};
