import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { DirectoryPageStore } from 'src/pages/directory/directory.store';
import Collapse from 'src/shared/components/collapse/collapse';
import { Sidebar } from 'src/shared/components/sidebar/sidebar';
import { TDirectoryTypeItem } from 'src/store/directories/types';

import { DirectoryRowCard } from './directory-row-card';
import styles from './related-directories-sidebar.module.scss';

type Props = {
  isOpened: boolean;
  onClose: () => void;
  data: TDirectoryTypeItem[];
  pageStore: DirectoryPageStore;
  openWarningModal: () => void;
};

export const RelatedDirectoriesSidebar = observer(function RelatedDirectoriesSidebar({
  isOpened,
  onClose,
  data,
  pageStore,
  openWarningModal,
}: Props) {
  const { t } = useTranslation();

  const description = data.length > 0 ? t('directory:selectRelatedDirectory') : t('directory:directoriesNotFound');

  return (
    <Sidebar title={t('directory:relatedDirectories')} description={description} isOpened={isOpened} onClose={onClose}>
      <div className={styles.wrapper}>
        <Collapse>
          <div className={styles.groupWrapper}>
            {data.length > 0 &&
              data.map((dictionary) => (
                <DirectoryRowCard
                  key={dictionary.label}
                  directory={dictionary}
                  handleCloseSidebar={onClose}
                  isEditMode={pageStore.directory.table.isEditMode}
                  openWarningModal={openWarningModal}
                />
              ))}
          </div>
        </Collapse>
      </div>
    </Sidebar>
  );
});
