import { Collapse as AntdCollapse, CollapseProps } from 'antd';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import './overwrite.scss';

import { ReactComponent as ArrowIcon } from 'src/shared/assets/icons/arrow-drop-icon.svg';

import styles from './styles.module.scss';

export const { Panel } = AntdCollapse;
// TODO: ограничить набор пропсов компонента
const Collapse = observer(function Collapse({
  expandIcon = ({ isActive }) => <ArrowIcon className={clsx(styles.arrowIcon, isActive && styles.arrowIconUp)} />,
  bordered = false,
  className,
  ...restProps
}: CollapseProps) {
  return <AntdCollapse expandIcon={expandIcon} {...restProps} className={clsx(className, bordered && 'bordered')} />;
});

export default Collapse;
