import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ThinArrowIcon } from 'src/shared/assets/icons/arrow-thin.svg';
import { ReactComponent as BookOpenedIcon } from 'src/shared/assets/icons/book-opened.svg';
import { ROUTES } from 'src/shared/constants/routes';
import { TDirectoryTypeItem } from 'src/store/directories/types';

import styles from './directory-row-card.module.scss';

type Props = {
  directory: TDirectoryTypeItem;
  handleCloseSidebar: () => void;
  openWarningModal: () => void;
  isEditMode: boolean;
};

export const DirectoryRowCard = observer(function DirectoryRowCard({
  directory,
  handleCloseSidebar,
  openWarningModal,
  isEditMode,
}: Props) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (isEditMode) {
      openWarningModal();
    } else {
      navigate(`${ROUTES.dicts}${directory.name}`);
      handleCloseSidebar();
    }
  };

  return (
    <button className={styles.card} onClick={handleClick}>
      <BookOpenedIcon className={clsx(styles.icon, styles.bookIcon)} />
      <p className={styles.directoryTitle}>{directory.label}</p>
      <ThinArrowIcon className={clsx(styles.icon, styles.arrowIcon)} />
    </button>
  );
});
