import { reaction } from 'mobx';

import { RootStore } from 'src/store';

import { Control } from '../entities/abstract-entities';
import { RemovableRow } from '../entities/control-entities/removable-row.entity';
import { FormStore } from '../entities/form.entity';

export type TFormPluginConstructor = new (rootStore: RootStore, form: FormStore) => IFormPlugin;

export interface IFormPlugin {
  readonly pluginId: number;
  readonly form: FormStore;

  connect(): VoidFunction | void;
}

/**
 * Утилитарный класс, позволяющий подписаться на изменения состава контролов формы и применить к каждому контролу переданный колбэк
 */
export class DynamicFormControllsManager {
  form: FormStore;
  private disposers = new Map<Control, VoidFunction>();

  constructor(form: FormStore) {
    this.form = form;
  }

  /**
   * @param callback - колбэк, применяемый к вновь созданным контролам. Если в колбэке осуществляется подписка на изменение
   * состояяния контрола, то рекомендуется из колбэка возвращать диспозер,
   * в таком случае менеджер будет автоматически управлять подписками при добавлении и удалении контролов
   */
  processItems(callback: (item: Control) => void | VoidFunction): VoidFunction {
    const disposer = reaction(
      this.getAllFormFields,
      (currFields, prevFields) => {
        const { newFields, deletedFields } = this.getNewAndDeletedFields(currFields, prevFields);

        newFields.forEach((field) => {
          const disposer = callback(field);

          if (disposer) {
            this.disposers.set(field, disposer);
          }
        });

        deletedFields.forEach((field) => {
          const disposer = this.disposers.get(field);

          disposer?.();
        });
      },
      { fireImmediately: true }
    );

    return () => {
      disposer();
      for (const tuple of this.disposers) {
        tuple[1]?.();
      }
    };
  }

  private getAllFormFields = (): Control[] => {
    const fields: Control[] = [];

    for (const field of this.form.getControlsIterator()) {
      fields.push(field);

      if (field instanceof RemovableRow) {
        for (const row of field.rows) {
          for (const rowField of row.fieldsList) {
            fields.push(rowField);
          }
        }
      }
    }

    return fields;
  };

  /**
   * Сравнивает текущий состав контролов с предыдущим состоянием, чтобы выделить новые и удалённые контролы.
   * К новым контролам будет применён переданный колбэк, а у удалённых контролов будет удалена подписка на изменение состояния,
   * если таковая имела место быть в переданном колбэке
   */
  private getNewAndDeletedFields(
    currFields: Control[],
    prevFields: Control[] = []
  ): { newFields: Control[]; deletedFields: Control[] } {
    const deletedFields = [...prevFields];
    const newFields = currFields.filter((field) => {
      const foundField = deletedFields.find((prevField, index) => {
        const isFieldFound = prevField === field;

        if (isFieldFound) {
          deletedFields.splice(index, 1);
        }

        return isFieldFound;
      });

      return !foundField;
    });
    return { newFields, deletedFields };
  }
}
