import { Control } from 'src/features/directory-form/entities/abstract-entities';
import { Column } from 'src/features/directory-form/entities/column.entity';
import { FormStore } from 'src/features/directory-form/entities/form.entity';
import { Group } from 'src/features/directory-form/entities/group.entity';
import { assert } from 'src/shared/utils/assert';
import { RootStore } from 'src/store';
import { Directories } from 'src/store/directories/directories.store';
import { TDirectoryTypeAttribute } from 'src/store/directories/types';

import { TFormRaw, TGroupRaw, TColumnRaw, TItemRaw } from '../types';

import { mapFieldItem } from './field-serializers/map-field-item';
import { mapComboBoxItem } from './map-combobox';
import { mapDateItem } from './map-date-item';
import { mapLabelItem } from './map-label-item';
import { mapMultiComboboxItem } from './map-multicombobox';
import { mapRemovableRow } from './map-removable-row';
import { mapStringsList } from './map-strings-list';
import { mapSwitchItem } from './map-switch-item';
import { mapYearItem } from './map-year-item';

export const mapData = (data: TFormRaw, rootStore: RootStore): FormStore => {
  const formData = {
    groups: mapGroups(data.groups, rootStore.directories),
  };
  return new FormStore(formData, rootStore);
};

const mapGroups = (data: TGroupRaw[], directories: Directories): Group[] => {
  return data.map((group, index) => {
    return new Group({
      id: index,
      label: group.label,
      columns: mapColumn(group, directories),
    });
  });
};

const mapColumn = (group: TGroupRaw, directories: Directories): Column[] => {
  return group.columns.map((column: TColumnRaw, index: number) => {
    return new Column({ id: index + 1, rows: mapItems(column.rows, directories) });
  });
};

export const mapItems = (
  items: TItemRaw[],
  directories: Directories,
  additionalAttributes?: TDirectoryTypeAttribute[]
): Control[] => {
  return items.map((item) => {
    const additionalAttribute = additionalAttributes?.find((attribute) => attribute.name === item.attrName);
    return mapItem(item, directories, additionalAttribute);
  });
};

export const mapItem = (
  item: TItemRaw,
  directories: Directories,
  additionalAttribute?: TDirectoryTypeAttribute
): Control => {
  //TODO: добавить во все контролы выбрасывание ошибки при отсутсвтии каких-то необходимых данных
  try {
    switch (item.control) {
      case 'Field': {
        const attribute = directories.getAttribute(item.attrName);

        return mapFieldItem(item, additionalAttribute || attribute);
      }
      case 'ComboBox': {
        const attribute = directories.getAttribute(item.attrName);

        return mapComboBoxItem(item, additionalAttribute || attribute, directories);
      }
      case 'MultiComboBox': {
        const attribute = directories.getAttribute(item.attrName);

        return mapMultiComboboxItem(item, additionalAttribute || attribute, directories);
      }
      case 'DateOnlyPicker': {
        const attribute = directories.getAttribute(item.attrName);
        return mapDateItem(item, additionalAttribute || attribute);
      }
      case 'CheckBox': {
        const attribute = directories.getAttribute(item.attrName);

        return mapSwitchItem(item, additionalAttribute || attribute);
      }
      case 'YearOnlyPicker': {
        const attribute = directories.getAttribute(item.attrName);
        return mapYearItem(item, additionalAttribute || attribute);
      }
      case 'Label': {
        const attribute = directories.getAttribute(item.attrName);

        return mapLabelItem(item, additionalAttribute || attribute, directories);
      }
      case 'RemovableRow': {
        const attribute = directories.getAttribute(item.attrName);

        assert(attribute, 'attribute is not presented');

        return mapRemovableRow(directories, item, additionalAttribute || attribute);
      }
      case 'StringsList': {
        const attribute = directories.getAttribute(item.attrName);

        return mapStringsList(directories, item, additionalAttribute || attribute);
      }
      default: {
        console.error('Unable to recognize the item', item);
        throw new Error('Unable to recognize the item');
      }
    }
  } catch (e) {
    console.error('An error occurred while trying to generate the control', item);
    throw e;
  }
};
