import { TFormRaw, TItemRaw } from 'src/api/directory/types';
import { FormStore } from 'src/features/directory-form/entities/form.entity';
import { IFormPlugin } from 'src/features/directory-form/form-plugins/abstract-form-plugin.entity';
import { LabelConcatedValue, RequiredIfPlugin } from 'src/features/directory-form/form-plugins/plugins';
import { RootStore } from 'src/store';

export type TPluginsTypes = 'requiredIf' | 'labelConcatedValue';

export const getFormPluginsConstructors = (view: TFormRaw, form: FormStore, rootStore: RootStore): IFormPlugin[] => {
  const plugins = new Map<TPluginsTypes, IFormPlugin>();

  for (const group of view.groups) {
    for (const column of group.columns) {
      for (const field of column.rows) {
        // TODO: Вынести методы формы в плагины и добавить обработку здесь
        const fieldAttributes = rootStore.directories.getAttribute(field.attrName);

        const addPlugins = (field: TItemRaw): void => {
          if (field.requiredIf && !plugins.has('requiredIf')) {
            plugins.set('requiredIf', new RequiredIfPlugin(form));
          }
          if (field.control === 'Label' && field.attrConcat) {
            plugins.set('labelConcatedValue', new LabelConcatedValue(form, rootStore.directories));
          }
        };

        addPlugins(field);

        if (field.control === 'RemovableRow') {
          field.columns.forEach((column) => addPlugins(column));
        }
      }
    }
  }

  return Array.from(plugins.values());
};
