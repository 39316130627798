import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

import { storage } from 'src/api/storage';
import { DirectoryTable } from 'src/features/directory/views/directory-table';
import { DirectoryHeader } from 'src/features/directory-header';
import { EditDirectoryRecordSidebar } from 'src/features/edit-directory-record-sidebar/edit-directory-record-sidebar';
import { RelatedDirectoriesSidebar } from 'src/features/related-directories-sidebar/related-directories-sidebar';
import { ReactComponent as CheckIcon } from 'src/shared/assets/icons/checkmark.svg';
import { ReactComponent as CrossIcon } from 'src/shared/assets/icons/cross.svg';
import { Button } from 'src/shared/components/button';
import Modal from 'src/shared/components/modal';
import { LOCAL_STORAGE } from 'src/shared/constants/local-storage';
import { useModal } from 'src/shared/hooks/use-modal';
import { useStore } from 'src/store';

import styles from './directory.module.scss';
import { DirectoryPageStore } from './directory.store';

export const Directory = observer(function Directory() {
  const store = useStore();
  const [searchParams] = useSearchParams();

  const isImportMode = searchParams.has('importMode');

  const { directories, theme, auth, importStore } = store;
  const { t } = useTranslation();

  const {
    isOpen: isWarningModalOpen,
    openModal: openWarningModal,
    closeModal: closeWarningModal,
  } = useModal({ initialIsOpen: false });

  const { directory } = useParams();
  const [pageStore] = useState(() => new DirectoryPageStore(store, directory));
  const { clearStoredDraftEffect } = pageStore;

  const [isRelatedDirectoriesSidebarOpened, setIsRelatedDirectoriesSidebarOpened] = useState(false);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  useEffect(clearStoredDraftEffect, [clearStoredDraftEffect]);

  useEffect(() => {
    if (directory) {
      const attributes = pageStore.directories.getTypeAttributes(directory);
      pageStore.directory.fetchTableView(directory, attributes, directories);
      pageStore.setDirectoryType(directory);
    }
  }, [pageStore, directory, directories]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      tableContainerRef.current && pageStore.setAvailableSpaceHeight(tableContainerRef.current.clientHeight);
    });

    if (tableContainerRef.current) {
      resizeObserver.observe(tableContainerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, [pageStore]);

  useEffect(() => {
    if (isImportMode && !!importStore.tableRows?.length && !!importStore.directoryItems?.length) {
      pageStore.directory.table.setEditMode(true);
      importStore.tableRows.forEach((row) => pageStore.directory.table.addDraftTableData(row));
      importStore.directoryItems.forEach((value) => pageStore.setDraftDirectory(value));

      storage.SET(LOCAL_STORAGE.draftTableData, pageStore.directory.table.draftTableData);
      storage.SET(LOCAL_STORAGE.draftDirectoryData, pageStore.draftDirectory);
      storage.SET(LOCAL_STORAGE.lastDirectoryType, pageStore.directoryType);
    }
  }, [isImportMode, pageStore.directory.table, importStore, pageStore]);

  if (directories.isLoading) {
    return null;
  }

  const handleOpenRelatedDirectoriesSidebar = () => setIsRelatedDirectoriesSidebarOpened(true);
  const handleCloseRelatedDirectoriesSidebar = () => setIsRelatedDirectoriesSidebarOpened(false);
  const handleModalDiscardChanges = () => {
    pageStore.resetDraft();
    closeWarningModal();
  };
  const handleModalSaveChanges = () => {
    pageStore.saveChanges();
    closeWarningModal();
  };
  return (
    <>
      <DirectoryHeader
        pageStore={pageStore}
        directories={directories}
        theme={theme}
        allowEditing={auth.hasEditingPermission}
        onWarningModalOpen={openWarningModal}
        onRelatedDirectoriesSidebarOpen={handleOpenRelatedDirectoriesSidebar}
      />

      <div className={styles.tableContainer} ref={tableContainerRef}>
        <DirectoryTable pageStore={pageStore} />
      </div>

      <Modal
        isOpened={isWarningModalOpen}
        handleClose={closeWarningModal}
        title={t('directory:warningModalTitle')}
        closeOnClickOutside={false}
        showCloseCross={false}
      >
        <p className={styles.copyModalDescription}>{t('directory:warningModalDescription')}</p>
        <div className={styles.modalButtonsWrapper}>
          <Button
            className={clsx(styles.button, styles.modalButton)}
            prefixIcon={<CrossIcon />}
            variant="danger"
            onClick={handleModalDiscardChanges}
          >
            {t('directory:discardChangesButton')}
          </Button>
          <Button
            className={clsx(styles.button, styles.modalButton)}
            prefixIcon={<CheckIcon />}
            variant="success"
            onClick={handleModalSaveChanges}
          >
            {t('directory:saveChangesButton')}
          </Button>
        </div>
      </Modal>

      <EditDirectoryRecordSidebar
        directory={directory}
        editingDirectory={pageStore.editingDirectory}
        copyingDirectory={pageStore.copyingDirectory}
        isOpened={pageStore.isFormSidebarOpened}
        pageStore={pageStore}
        onClose={pageStore.closeSidebar}
      />
      <RelatedDirectoriesSidebar
        data={pageStore.directory.relatedDirectories}
        isOpened={isRelatedDirectoriesSidebarOpened}
        onClose={handleCloseRelatedDirectoriesSidebar}
        pageStore={pageStore}
        openWarningModal={openWarningModal}
      />
    </>
  );
});
