import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren, useState, useEffect, useLayoutEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import { checkIsNode } from 'src/shared/utils/check-is-node';

import styles from './styles.module.scss';
import { ESidebarSizes } from './types';

type Props = PropsWithChildren<{
  title?: string;
  description?: string;
  size?: keyof typeof ESidebarSizes;
  width?: number;
  isOpened: boolean;
  side?: 'left' | 'right';
  transitionDuration?: number;
  closeOnClickOutside?: boolean;
  onClose: () => void;
}>;

export const Sidebar: React.FC<Props> = observer(function Sidebar({
  title,
  description,
  size = 'm',
  isOpened,
  closeOnClickOutside = true,
  transitionDuration = 200,
  children,
  onClose,
}) {
  const [containerElement] = useState(() => document.createElement('div'));
  const [isContainerMounted, setIsContainerMounted] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (isOpened) {
      document.body.appendChild(containerElement);
      setIsContainerMounted(true);
      return;
    }

    if (!isContainerMounted) return;
    const timer = setTimeout(() => {
      containerElement.remove();
    }, transitionDuration);

    return () => clearTimeout(timer);
  }, [isContainerMounted, transitionDuration, containerElement, isOpened]);

  useEffect(() => {
    if (isOpened && isContainerMounted) {
      window.requestAnimationFrame(() => {
        containerRef!.current?.classList.add(styles[`wrapper__${size}`]);
        containerRef!.current?.classList.add(styles.wrapper__opened);
      });
      return;
    }

    if (!isOpened && isContainerMounted) {
      window.requestAnimationFrame(() => {
        containerRef!.current?.classList.remove(styles[`wrapper__${size}`]);
        containerRef!.current?.classList.remove(styles.wrapper__opened);
      });
    }
  }, [isOpened, size, isContainerMounted]);

  useEffect(() => {
    if (!closeOnClickOutside || !isOpened) return;

    const handleClickOutside = (event: MouseEvent) => {
      if (!checkIsNode(event.target)) return;
      if (containerRef.current!.contains(event.target)) return;
      onClose();
    };

    document.body.addEventListener('pointerdown', handleClickOutside);

    return () => document.body.removeEventListener('pointerdown', handleClickOutside);
  }, [isOpened, closeOnClickOutside, onClose]);

  useEffect(() => {
    return () => containerElement.remove();
  }, [containerElement]);

  return createPortal(
    <div ref={containerRef} className={styles.wrapper}>
      <div className={clsx(styles.contentWrapper, styles[`wrapper__${size}`])}>
        <p className={styles.title}>{title}</p>
        {!!description && <p className={styles.descriptionText}>{description}</p>}
        {isOpened && children}
        <button className={styles.closeButton} onClick={onClose} />
      </div>
    </div>,
    containerElement
  );
});
