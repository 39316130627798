import { observer } from 'mobx-react-lite';
import { JSXElementConstructor, ReactElement, useEffect, useState } from 'react';

import { FormSelect } from 'src/shared/components/form-components';

import { RegularComboBox } from '../../entities/control-entities';
import { JoinComboBoxItem } from '../../entities/control-entities/join-combobox.entity';
import { ComboboxDropdown } from '../combobox-dropdown/combobox-dropdown';

type Props = {
  className?: string;
  label?: string;
  item: RegularComboBox | JoinComboBoxItem;
  isDisabled?: boolean;
};

export const FormRegularComboBox = observer(function FormRegularComboBox({
  className,
  label,
  item,
  isDisabled,
}: Props) {
  const [isOpened, setIsOpened] = useState(false);

  const { effect } = item;

  const handleAddNewValue = () => {
    item.openNewFormSidebar();
    setIsOpened(false);
  };

  const renderDropdown = (origin: ReactElement<any, string | JSXElementConstructor<any>>) => {
    return <ComboboxDropdown onButtonClick={handleAddNewValue}>{origin}</ComboboxDropdown>;
  };

  const onChange = (value: string | number) => {
    item.setValue(value);
    item.clearError();
    setIsOpened(false);
  };

  const onDropdownVisibleChange = (open: boolean) => {
    setIsOpened(open);
  };

  useEffect(effect, [effect]);

  return (
    <FormSelect
      open={isOpened}
      value={item.value}
      label={label}
      className={className}
      errorText={item.errorText}
      options={item.options}
      isDisabled={isDisabled}
      dataTestId={`combobox-${item.fieldId}`}
      showSearch
      optionFilterProp="label"
      dropdownStyle={{
        zIndex: 25,
      }}
      dropdownRender={renderDropdown}
      onBlur={item.validate}
      onChange={onChange}
      onDropdownVisibleChange={onDropdownVisibleChange}
    />
  );
});
