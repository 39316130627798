import { IntegerField } from 'src/features/directory-form/entities/control-entities';
import { TDirectoryTypeAttribute } from 'src/store/directories/types';

import { TIntegerFieldRaw } from '../../types';

export const mapIntegerFieldItem = (
  item: TIntegerFieldRaw,
  attribute: TDirectoryTypeAttribute | undefined
): IntegerField => {
  const fieldProps = {
    formElementRefId: item.attrName,
    fieldId: attribute?.name,
    unit: attribute?.unit,
    label: attribute?.defaultLabel,
    defaultValueLink: item.defaultValueLink,
    restrictions: { required: !!attribute?.required, ...attribute?.restrictions },
    enableIf: item.enableIf,
    showIf: item.showIf,
    type: item.type,
  };

  return new IntegerField(fieldProps);
};
