import { action, computed, makeObservable, observable } from 'mobx';
import moment, { Moment } from 'moment';

import { TRequiredIf } from 'src/api/directory/types';

import { IDateRestrictions } from '../../types';
import { DateValidation } from '../../validation/date-validation';
import { ValidatableItem } from '../abstract-entities';
import { TControlValueType, TEnableIf } from '../types';

type TDateOptions = {
  formElementRefId: string;
  fieldId?: string;
  unit?: string;
  defaultValueLink?: string;
  enableIf?: TEnableIf[];
  restrictions: IDateRestrictions;
  type: TControlValueType;
  requiredIf?: TRequiredIf[];
};

export class DateField extends ValidatableItem<number | null> {
  @observable.ref date: Moment | null = null;

  readonly validation: DateValidation;

  constructor(data: TDateOptions) {
    super(data);
    this.validation = new DateValidation(this, data.restrictions);

    makeObservable(this);
  }

  @computed
  get value(): number | null {
    if (!this.date) return null;
    return this.date.unix();
  }

  checkIsReady(): boolean {
    if (!!this.errorText) return false;
    return this.value !== null;
  }

  @action.bound
  clearError(): void {
    this.errorText = undefined;
  }

  @action.bound
  validate = () => {
    const errorText = this.validation.validate(this.date);
    if (!!errorText) this.setError(errorText);
  };

  @action.bound
  setValue(value: number | null): void {
    if (!value) return;
    this.date = moment(value * 1000);
  }

  @action.bound
  resetControl(): void {
    this.date = null;
    this.clearError();
  }

  @action.bound
  changeValue(value: Moment | null) {
    this.date = value;
  }

  @action.bound
  getDisabledDates(currentDate: Moment): boolean {
    if (this.validation.maxAvailableDate && currentDate.unix() >= this.validation.maxAvailableDate?.unix()) {
      return true;
    }

    if (currentDate.unix() <= (this.validation.minAvailableDate?.unix() ?? 0)) {
      return true;
    }

    return false;
  }
}
