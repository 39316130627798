import { useDroppable } from '@dnd-kit/core';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { useStore } from 'src/store';

import { TMatchingItemType, MatchingImportStore } from '../../import-matching-page.store';
import { MatchingItem } from '../matching-item';
import { DraggableMatchingItem } from '../matching-item/draggable-matching-item';

import { Icons } from './icons';
import styles from './matching-row.module.scss';

type Props = {
  item: TMatchingItemType;
  matchingImport: MatchingImportStore;
};

export const MatchingRow = observer(function MatchingRow({ item, matchingImport }: Props) {
  const { directories } = useStore();

  const value = matchingImport.data.find((dataItem) => dataItem.name === item.name)?.value;

  const isItemRequired = matchingImport.getIsItemRequired(item);

  const { isOver, setNodeRef } = useDroppable({
    id: item.name,
    data: {
      header: value,
    },
  });

  return (
    <div ref={setNodeRef} className={clsx(styles.matchingRow)}>
      <div className={styles.systemDataFieldWrapper}>
        <MatchingItem
          className={styles.systemItem}
          isRequired={isItemRequired}
          name={directories.getLabelByObjectTypeAndFieldId(`${matchingImport.directoryName}.${item.name}`)}
          icon={<Icons isEmpty={!value} isOver={isOver} isRequired={isItemRequired} />}
        />
      </div>
      <DraggableMatchingItem
        isOver={isOver}
        isEmpty={!value}
        isRequired={isItemRequired}
        name={item.name}
        header={value || null}
      />
    </div>
  );
});
