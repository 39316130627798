import { action, makeObservable, observable } from 'mobx';

export class SidebarStore {
  @observable isOpened: boolean;

  constructor() {
    this.isOpened = false;

    makeObservable(this);
  }

  @action.bound
  open() {
    this.isOpened = true;
  }

  @action.bound
  close() {
    this.isOpened = false;
  }

  @action.bound
  toggle() {
    this.isOpened = !this.isOpened;
  }
}
