export const ACCESS_TOKEN_KEY = 'access_token';
export const REFRESH_TOKEN_KEY = 'refresh_token';

export const SUB_KEY = 'sub';

export const KEYCLOAK_CLIENT_ID =
  (window as any)._env_.REACT_APP_KEYCLOAK_CLIENT_ID || process.env.REACT_APP_KEYCLOAK_CLIENT_ID || 'golibrary';

export const KEYCLOAK_REALM_NAME =
  (window as any)._env_.REACT_APP_KEYCLOAK_REALM_NAME || process.env.REACT_APP_KEYCLOAK_REALM_NAME || 'GOplan';

export enum Roles {
  golibraryEditor = 'golibrary_editor',
  golibraryAdmin = 'golibrary_admin',
}
