import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from 'src/pages/directory/directory.module.scss';
import { Button } from 'src/shared/components/button';
import { useStore } from 'src/store';

import { ImportModal } from '../modals/import-modal';
import { SelectPageModal } from '../modals/select-page-modal';

import { ImportButtonStore } from './import-button.store';

export const ImportButton = observer(function ImportButton() {
  const { t } = useTranslation();

  const store = useStore();

  const [importButtonStore] = useState(() => new ImportButtonStore(store));

  const {
    isSelectPageModalOpened,
    isImportModalOpened,
    isLoading,
    sheets,
    openImportModal,
    closeImportModal,
    closeSelectPageModal,
    uploadFile,
    selectSheet,
  } = importButtonStore;

  return (
    <>
      <Button className={styles.addDrillingRig} variant="primary" onClick={openImportModal}>
        {t('directory:import')}
      </Button>
      <ImportModal
        isLoading={isLoading}
        isOpened={isImportModalOpened}
        onClose={closeImportModal}
        onUpload={uploadFile}
      />
      <SelectPageModal
        sheets={sheets ?? []}
        onSelectSheet={selectSheet}
        onClose={closeSelectPageModal}
        isOpen={isSelectPageModalOpened}
      />
    </>
  );
});
