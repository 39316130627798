import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';

import { MenuItem } from '../menu-item/menu-item';

import { navProps } from './consts';
import styles from './styles.module.scss';

type Props = {
  isOpened: boolean;
};

export const Navigation: React.FC<Props> = observer(function Navigation({ isOpened }) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className={clsx(styles.wrapper, isOpened && styles.wrapper__opened)}>
      {navProps.map((nav) => {
        const isActive = location.pathname.includes(nav.to.replaceAll(/[*/]/g, ''));
        const onClick = () => navigate(nav.to);

        return <MenuItem key={nav.to} isActive={isActive} isOpened={isOpened} item={nav} onClick={onClick} />;
      })}
    </div>
  );
});
