import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { MainLayout } from 'src/shared/layouts/main-layout/main-layout';

export const DictionariesPage = observer(function DictionariesPage() {
  const { t } = useTranslation();

  return (
    <MainLayout title={t('directories:title')}>
      <Outlet />
    </MainLayout>
  );
});
