import { Divider } from 'antd';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { ChangeEvent, RefObject, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

import { ReactComponent as SortDownIcon } from 'src/shared/assets/icons/sort-down.svg';
import { ReactComponent as SortUpIcon } from 'src/shared/assets/icons/sort-up.svg';
import { Button } from 'src/shared/components/button';
import { Checkbox } from 'src/shared/components/checkbox';
import { useStore } from 'src/store';
import { TOrder } from 'src/store/directories/types';
import { TFilterValue, TSorting, TTableFilter } from 'src/store/table/types';

import styles from './select-filter.module.scss';

const getFilterLabel = (label: string | number | boolean, type: string): string | number => {
  if (type === 'DateTime' && typeof label === 'number') {
    return moment(label * 1000).format('DD.MM.YYYY');
  }

  if (typeof label === 'boolean') {
    return Number(label);
  }

  return label;
};

type Props = {
  attr: string;
  filters: TFilterValue[];
  sorting: TSorting;
  containerRef: RefObject<HTMLDivElement> | null;
  order?: TOrder;
  sortingKey?: string;
  onTableFiltersChanged: (attr: string, values: (string | number)[], isArray: boolean) => TTableFilter[];
  onSortingChanged: (sorting: TSorting) => void;
};

type TCheckboxItem = {
  value: string | number;
  label: string | number;
  isChecked: boolean;
};

export const SelectFilter = observer(function SelectFilter({
  filters = [],
  attr,
  sorting,
  order,
  sortingKey,
  containerRef,
  onTableFiltersChanged,
  onSortingChanged,
}: Props) {
  const initialFilters = useMemo(
    () => filters.map((item) => ({ value: item.value, label: item.label, isChecked: false })),
    [filters]
  );
  const { directories } = useStore();
  const { directory } = useParams();

  const columnAttr = directories.getAttribute(`${directory}.${attr}`);

  const columnType = columnAttr?.type ?? 'String';

  const [chosenValues, setChosenValues] = useState<TCheckboxItem[]>([]);
  const [columnFilters] = useState<TCheckboxItem[]>(initialFilters);
  const [searchString, setSearchString] = useState('');

  const { t } = useTranslation();

  const onApply = () => {
    if (columnFilters.length === 0) return;

    onTableFiltersChanged(
      attr,
      chosenValues.map(({ value }) => value),
      columnAttr?.isArray || false
    );
  };

  const onCancel = () => {
    onTableFiltersChanged(attr, [], false);
    setChosenValues([]);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  };

  const displayedValues: TCheckboxItem[] = [];

  for (const filter of columnFilters) {
    if (filter.isChecked) {
      continue;
    } else {
      if (!searchString) {
        displayedValues.push(filter);
        continue;
      }
      const filterLabel = String(getFilterLabel(filter.label, columnType)).toLowerCase();

      if (filterLabel.includes(searchString.toLowerCase())) {
        displayedValues.push(filter);
      }
    }
  }

  const getButtonStyle = (direction: 'ASC' | 'DESC') => {
    if (!sortingKey) {
      return styles.tooltipButton;
    }

    const [type, attr] = sortingKey.split('.');
    const isSorting = attr === order?.attr && order.direction !== 'DEFAULT' && type === order.joinedAlias;

    return clsx(styles.tooltipButton, isSorting && sorting === direction && styles.tooltipButtonActive);
  };

  return (
    <div ref={containerRef}>
      {!columnAttr?.isArray && (
        <div className={styles.topButtonsContainer}>
          <Button
            onClick={() => onSortingChanged('ASC')}
            variant="secondary"
            icon={<SortUpIcon className={styles.icon} />}
            className={getButtonStyle('ASC')}
          />
          <Button
            onClick={() => onSortingChanged('DESC')}
            variant="secondary"
            icon={<SortDownIcon className={styles.icon} />}
            className={getButtonStyle('DESC')}
          />
        </div>
      )}

      <input className={styles.search} placeholder={t('common:searchByValue')} onChange={onChange} />

      {(displayedValues.length || chosenValues.length) && (
        <>
          <ul className={styles.chosenFiltersValues}>
            {chosenValues.map((filter) => (
              <li key={filter.value} className={styles.checkItem}>
                <Checkbox
                  isChecked={filter.isChecked}
                  onChange={() => {
                    const index = chosenValues.findIndex((v) => v.value === filter.value);
                    const copiedChosenValues = [...chosenValues];
                    copiedChosenValues.splice(index);
                    filter.isChecked = !filter.isChecked;

                    setChosenValues(copiedChosenValues);
                  }}
                />
                <p className={styles.checkItemText}>{getFilterLabel(filter.label, columnType)}</p>
              </li>
            ))}
          </ul>

          {!!displayedValues.length && !!chosenValues.length && <Divider className={styles.divider} />}

          <ul className={styles.filtersValues}>
            <AutoSizer>
              {({ width, height }: Size) => (
                <FixedSizeList itemCount={displayedValues.length} itemSize={40} width={width} height={height}>
                  {({ index, style }) => {
                    const filter = displayedValues[index];
                    const filterLabel = getFilterLabel(filter.label, columnType);

                    if (filter.isChecked) {
                      return null;
                    }

                    return (
                      <li key={filter.value} className={styles.checkItem} style={style}>
                        <Checkbox
                          isChecked={filter.isChecked}
                          onChange={() => {
                            filter.isChecked = !filter.isChecked;
                            setChosenValues([...chosenValues, filter]);
                          }}
                        />
                        <p className={styles.checkItemText}>{filterLabel}</p>
                      </li>
                    );
                  }}
                </FixedSizeList>
              )}
            </AutoSizer>
          </ul>
        </>
      )}

      <div className={styles.bottomButtonsContainer}>
        <Button variant="text" type="button" className={styles.button} onClick={onCancel}>
          {t('common:Buttons.reset')}
        </Button>

        <Button variant="success" type="button" className={clsx(styles.button, styles.applyButton)} onClick={onApply}>
          {t('common:Buttons.apply')}
        </Button>
      </div>
    </div>
  );
});
