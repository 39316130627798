import { RemovableRow } from 'src/features/directory-form/entities/control-entities/removable-row.entity';
import { Directories } from 'src/store/directories/directories.store';
import { TDirectoryTypeAttribute } from 'src/store/directories/types';

import { TComboboxRaw, TFilter, TRemovableRowRaw } from '../types';

export const mapRemovableRow = (
  directories: Directories,
  item: TRemovableRowRaw,
  attribute: TDirectoryTypeAttribute
): RemovableRow => {
  if (!attribute.attributes) {
    console.error('attributes or children attributes are not presented', attribute);
    throw new Error();
  }

  const relatedToButtonComboboxAttrName = item.relatedToButtonComboboxAttrName;

  let relatedComboboxFilters: TFilter[] = [];
  let buttonRefObjectType: string | undefined = undefined;

  if (relatedToButtonComboboxAttrName) {
    relatedComboboxFilters =
      item.columns.find((item): item is TComboboxRaw => {
        return item.control === 'ComboBox' && item.attrName === relatedToButtonComboboxAttrName;
      })?.filters || [];

    buttonRefObjectType = attribute.attributes?.find(
      (attribute) => attribute.name === relatedToButtonComboboxAttrName
    )?.refObjectType;
  }

  const data = {
    directories,
    filters: relatedComboboxFilters,
    attributes: attribute.attributes,
    fieldId: attribute.name,
    refObjectType: buttonRefObjectType,
    formElementRefId: item.attrName,
    enableIf: item.enableIf,
    showIf: item.showIf,
    restrictions: { required: true, ...attribute.restrictions },
    defaultValueLink: item.defaultValueLink,
    buttonLabel: item.buttonLabel,
    relatedToButtonComboboxAttrName,
    requiredIf: item.requiredIf,
    filterRows: item.filterRows,
    isStaticRows: !!item.isStaticRows,
    rowView: {
      items: item.columns,
      attributes: attribute.attributes || [],
    },
    type: item.type,
  };

  return new RemovableRow(data);
};
