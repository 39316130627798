import { Switch } from 'antd';
import { observer } from 'mobx-react-lite';

type Props = {
  isChecked: boolean;
  isDisabled?: boolean;
  onChange: (value: boolean) => void;
};

export const NewWellSwitcher = observer(function NewWellSwitcher({ isChecked, isDisabled, onChange }: Props) {
  return <Switch checked={isChecked} disabled={isDisabled} onChange={onChange} />;
});
