import { action, makeObservable } from 'mobx';
import { NavigateFunction } from 'react-router-dom';

export class RouterStore {
  private readonly navigate: NavigateFunction;

  constructor(navigate: NavigateFunction) {
    this.navigate = navigate;

    makeObservable(this);
  }

  @action.bound
  push(href: string) {
    this.navigate(href);
  }

  @action.bound
  replace(href: string) {
    this.navigate(href, { replace: true });
  }

  @action.bound
  back(delta?: number) {
    this.navigate(delta || -1);
  }

  @action.bound
  forward(delta?: number) {
    this.navigate(delta || 1);
  }
}
