import { observable, makeObservable, action, autorun, computed } from 'mobx';

import { TComboboxFilter, TRequiredIf } from 'src/api/directory/types';
import { joinsToOptions } from 'src/shared/utils/process-joins-to-options';
import { Directories } from 'src/store/directories/directories.store';
import {
  TComboboxOptionsSorting,
  TDirectoryItem,
  TOption,
  TRefQuery,
  TRefQueryVariable,
} from 'src/store/directories/types';

import { IRestrictions } from '../../types';
import { ValidatableItem } from '../abstract-entities';
import { TControlValueType, TEnableIf } from '../types';

type TJoinComboboxData = {
  formElementRefId: string;
  directories: Directories;
  fieldId?: string;
  objectName?: string;
  delimiter?: string;
  refExternalSource?: string;
  refObjectAttr?: string;
  refObjectType?: string;
  restrictions: IRestrictions;
  refQuery: TRefQuery;
  refQueryVariables?: TRefQueryVariable[];
  refObjectAttrConcat?: string[];
  enableIf?: TEnableIf[];
  directory: Record<string, string | number | boolean>[] | null;
  type: TControlValueType;
  requiredIf?: TRequiredIf[];
  filterByControlValue?: TComboboxFilter;
  optionsSorting?: TComboboxOptionsSorting;
};

export class JoinComboBoxItem extends ValidatableItem<string | number | null> {
  refExternalSource?: string;
  refObjectType?: string;
  refObjectAttr?: string;
  refQuery: TRefQuery;
  delimiter?: string;
  joinedAttributes: Record<string, string | number | boolean>[];
  readonly optionsSorting?: TComboboxOptionsSorting;
  readonly refQueryVariables?: TRefQueryVariable[];
  readonly refObjectAttrConcat?: string[];
  readonly filterByControlValue?: TComboboxFilter;
  readonly directory: Record<string, string | number | boolean>[] | null = null;
  readonly directories: Directories;

  @observable private _options: TOption[];
  @observable isLoading: boolean;
  @observable value: string | number | null;
  @observable isFormSidebarOpened: boolean = false;

  constructor(data: TJoinComboboxData) {
    super(data);

    this.refExternalSource = data?.refExternalSource;
    this.refObjectAttr = data.refObjectAttr;
    this.refObjectType = data?.refObjectType;
    this.directory = data.directory;
    this.filterByControlValue = data.filterByControlValue;
    this.directories = data.directories;
    this.delimiter = data.delimiter;
    this.joinedAttributes = data.directory || [];
    this.refObjectAttrConcat = data.refObjectAttrConcat;
    this.optionsSorting = data.optionsSorting;

    this.isLoading = false;
    this.value = null;
    this.refQueryVariables = data.refQueryVariables;
    this.refQuery = data.refQuery;
    this._options = this.getInitialOptions(data.directories.getDirectory(this.refObjectType), this.refObjectAttr);

    makeObservable(this);
  }

  @computed
  get options(): TOption[] {
    const options = this._options;

    if (this.optionsSorting) {
      return options.sort((a, b) => {
        const aLabel = a.label.toLowerCase();
        const bLabel = b.label.toLowerCase();

        if (this.optionsSorting === 'ASC') {
          return aLabel.localeCompare(bLabel);
        }

        return bLabel.localeCompare(aLabel);
      });
    }

    return options;
  }

  private getInitialOptions(
    data: TDirectoryItem[],
    refObjectAttr: string | undefined,
    filter?: { [key: string]: string | number | boolean }
  ): TOption[] {
    if (!refObjectAttr) return [];

    const filteredData = filter
      ? data.filter((item) => {
          for (const key in filter) {
            const value = item.data[key];
            if (!value || typeof value === 'object') {
              return false;
            }
            if (value.toString() !== filter[key].toString()) {
              return false;
            }
          }

          return true;
        })
      : data;

    const options: TOption[] = [];

    for (const item of filteredData) {
      const label = item.data[refObjectAttr];

      if (
        typeof label !== 'string' &&
        (typeof label !== 'number' || (typeof label === 'number' && Number.isNaN(Number(label))))
      ) {
        continue;
      }

      options.push({
        label: label.toString(),
        value: item.id,
      });
    }

    return options;
  }

  checkIsReady(): boolean {
    if (!!this.errorText) return false;
    return !!this.value;
  }

  @action.bound
  openNewFormSidebar() {
    this.isFormSidebarOpened = true;
  }

  @action.bound
  closeNewFormSidebar() {
    this.isFormSidebarOpened = false;
  }

  @action.bound
  setOptions(options: TOption[]): void {
    this._options = options;
  }

  @action.bound
  resetDefaultOptions() {
    this._options = this.getInitialOptions(this.directories.getDirectory(this.refObjectType), this.refObjectAttr);
  }

  @action.bound
  setOptionsByJoinResponse(joinRes: Record<string, string | number | boolean>[]) {
    this._options = joinsToOptions(this, joinRes);
  }

  @action.bound
  clearError(): void {
    this.errorText = undefined;
  }

  @action.bound
  clearItem(): void {
    this.clearError();
    this.value = null;
  }

  @action.bound
  validate(): void {
    if (!this.value && this.restrictions?.required) {
      this.errorText = 'directory:Errors.required';
    }
  }

  @action.bound
  setValue(value: string | number | null): void {
    this.value = value;
  }

  @action.bound
  tryToSetRawValue(value: unknown): boolean {
    if (typeof value === 'string' && !isNaN(Number(value))) {
      this.setValue(Number(value));
      return true;
    }
    if (typeof value === 'number' || value === null) {
      this.setValue(value);
      return true;
    }
    return false;
  }

  @action.bound
  resetControl(): void {
    this.value = null;
    this.clearError();
  }

  private checkIsCurrentValuePresentedInOptionsList(): VoidFunction {
    return autorun(() => {
      if (this.value && !this._options.find((option) => option.value === this.value)) {
        this.resetControl();
      }
    });
  }

  effect = () => {
    const checkIsCurrentValuePresentedInOptionsListDisposer = this.checkIsCurrentValuePresentedInOptionsList();

    return () => {
      checkIsCurrentValuePresentedInOptionsListDisposer();
    };
  };
}
