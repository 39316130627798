import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, ReactElement, ReactNode } from 'react';

import { ReactComponent as CrossIcon } from 'src/shared/assets/icons/cross.svg';
import { Input } from 'src/shared/components/input/input';

import { InputError } from '../input-error';
import { InputLabel } from '../input-label';

import styles from './form-input.module.scss';

type Props = {
  className?: string;
  value?: number | string | null;
  label?: string | ReactElement;
  isError?: boolean;
  errorText?: string | [string, { [locKey: string]: string | number }];
  prefix?: ReactNode;
  unit?: string;
  placeholder?: string;
  isDisabled?: boolean;
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: VoidFunction;
  onBlur?: VoidFunction;
  onPressEnter?: VoidFunction;
};

export const FormInput = observer(function FormInput({
  className,
  value,
  label,
  isError,
  errorText,
  prefix,
  unit,
  placeholder,
  isDisabled,
  onChange,
  onPressEnter,
  onFocus,
  onBlur,
}: Props) {
  const labelRender = () => {
    if (typeof label === 'string') {
      return <InputLabel text={label} />;
    }

    return label;
  };

  return (
    <div className={clsx(styles.inputWrapper, className)}>
      {labelRender()}

      <Input
        value={value === null ? '' : value}
        placeholder={placeholder}
        className={clsx(styles.input, isDisabled && styles.input__disabled)}
        prefix={!!prefix ? <p className={styles.text}>{prefix}</p> : undefined}
        suffix={<p className={styles.text}>{unit}</p>}
        allowClear={{
          clearIcon: <CrossIcon className={styles.icon} />,
        }}
        status={isError ? 'error' : undefined}
        disabled={isDisabled}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onPressEnter={onPressEnter}
      />
      {!!errorText && <InputError errorTextRaw={errorText} />}
    </div>
  );
});
