import { TFieldItem } from 'src/api/directory/types';
import { Directories } from 'src/store/directories/directories.store';
import { TDirectoryItem } from 'src/store/directories/types';
import { TableRow } from 'src/store/table/types';

import { hasValue } from '../lib/common';

import { checkIsNumberArray } from './check-is-number-array';
import { checkIsStringArray } from './check-is-string-arrayt';

const isTableRowDataProperty = (value: unknown): value is string | number | boolean | string[] | number[] => {
  return (
    hasValue(value) &&
    (['string', 'number', 'boolean'].includes(typeof value) || checkIsNumberArray(value) || checkIsStringArray(value))
  );
};

export const serializeFormDataIntoTableRowData = (
  formData: Record<string, TFieldItem>,
  directories: Directories,
  directoryType: string
): TableRow => {
  const keys = Object.keys(formData);

  const serializedData: TableRow = {};

  for (const key of keys) {
    const keyAttributes = directories.getAttribute(`${directoryType}.${key}`);
    const value = formData[key];

    if (!keyAttributes) {
      continue;
    }

    if (keyAttributes.type !== 'ObjectReference') {
      if (keyAttributes.type === 'Object' && keyAttributes.attributes) {
        const { attributes } = keyAttributes;
        const attrWithId = attributes?.find((nestedAttr) => nestedAttr.isIdAttr);
        const attrWithValue = attributes?.find((nestedAttr) => nestedAttr.isValueAttr);

        if (!attrWithValue || !attrWithId || !Array.isArray(value)) continue;

        for (let valueItem of value) {
          if (typeof valueItem === 'number') continue;

          const itemKey = `${key}.${valueItem[attrWithId.name]}.${attrWithValue.name}`;
          const itemValue = valueItem[attrWithValue.name];
          serializedData[itemKey] = itemValue;
        }
      } else {
        if (!isTableRowDataProperty(value)) continue;

        if (Array.isArray(value)) {
          serializedData[key] = value.join(', ');
          continue;
        }

        serializedData[key] = value;
      }
    } else if (!keyAttributes.refObjectType || !keyAttributes.refObjectAttr) {
      continue;
    } else if (keyAttributes.refObjectType && keyAttributes.refObjectAttr) {
      const refObjectType = keyAttributes.refObjectType;
      const refObjectAttr = keyAttributes.refObjectAttr;

      if (Array.isArray(value)) {
        const refObjects: TDirectoryItem[] = [];

        value.forEach((valueItem) => {
          const refObject = directories.directories[refObjectType].find((item) => item.id === valueItem);

          if (refObject) {
            refObjects.push(refObject);
          }
        });

        const joinedValue = refObjects
          .map((refObject) => {
            const refValue = refObject?.data[refObjectAttr];

            return Array.isArray(refValue) ? '' : refValue;
          })
          .join(', ');

        serializedData[key] = joinedValue;
      } else {
        const refObject = directories.directories[refObjectType].find((item) => item.id === value);

        if (refObject) {
          const refValue = refObject?.data[refObjectAttr];

          if (!isTableRowDataProperty(refValue)) {
            continue;
          }

          if (Array.isArray(refValue)) {
            serializedData[key] = refValue.join(', ');
            continue;
          }

          serializedData[key] = refValue;
        }
      }
    }
  }

  return serializedData;
};
