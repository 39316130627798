import { observer } from 'mobx-react-lite';
import { PropsWithChildren, useEffect, useLayoutEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';

import styles from './modal-core.module.scss';

type Props = PropsWithChildren<{
  transitionDuration?: number;
  bgColor?: string;
  isOpened: boolean;
}>;

export const ModalCore = observer(function ModalCore({
  transitionDuration = 200,
  bgColor = 'rgba(0, 0, 0, 0.5)',
  isOpened,
  children,
}: Props) {
  const [container] = useState(() => document.createElement('div'));
  const [isContainerMounted, setIsContainerMounted] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (isOpened) {
      document.body.appendChild(container);
      setIsContainerMounted(true);
      return;
    }

    if (!isContainerMounted) return;
    const timer = setTimeout(() => {
      container.remove();
    }, transitionDuration);

    return () => clearTimeout(timer);
  }, [isContainerMounted, transitionDuration, container, isOpened]);

  useEffect(() => {
    if (isOpened && isContainerMounted) {
      window.requestAnimationFrame(() => {
        containerRef!.current?.classList.add(styles.wrapper__opened);
      });
      return;
    }

    if (!isOpened && isContainerMounted) {
      window.requestAnimationFrame(() => {
        containerRef!.current?.classList.remove(styles.wrapper__opened);
      });
    }
  }, [isOpened, isContainerMounted]);

  return createPortal(
    <div ref={containerRef} className={styles.wrapper} style={{ backgroundColor: bgColor }}>
      {children}
    </div>,
    container
  );
});
