import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { DirectoriesList } from 'src/features/directories-list';
import { DictionariesPage } from 'src/pages';
import { Directory } from 'src/pages/directory';
import { ImportMatchingPage } from 'src/pages/import-matching-page/import-matching-page';
import { ROUTES } from 'src/shared/constants/routes';

export const Router: FC = observer(function Router() {
  return (
    <Routes>
      <Route path={ROUTES.dicts} element={<DictionariesPage />}>
        <Route path={ROUTES.all} element={<DirectoriesList />} />
        <Route path={ROUTES.directory} element={<Directory />} />
        <Route path={ROUTES.matching} element={<ImportMatchingPage />} />
        <Route path="" element={<Navigate to={ROUTES.all} />} />
      </Route>
      <Route path="*" element={<Navigate to={ROUTES.dicts} />} />
    </Routes>
  );
});

export default Router;
