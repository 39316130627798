import { action, makeObservable } from 'mobx';
import { v4 as uuid } from 'uuid';

import { TFieldItem } from 'src/api/directory/types';
import { serializeFormDataIntoTableRowData } from 'src/shared/utils/serialize-form-data-into-table-row-data';
import { TDirectoryItem } from 'src/store/directories/types';

import { Directories } from '../directories/directories.store';
import { TableRow } from '../table/types';

type TImportStoreOptions = {
  directories: Directories;
};

export class ImportStore {
  private readonly directories: Directories;
  tableRows: TableRow[] | null = null;
  directoryItems: TDirectoryItem[] | null = null;

  constructor({ directories }: TImportStoreOptions) {
    this.directories = directories;

    makeObservable(this);
  }

  @action.bound
  setImportedDirectoryTableData(values: Record<string, TFieldItem>[], directoryType: string) {
    const valuesWithId = values.map((value) => {
      const valueWithId: { id: string; [key: string]: TFieldItem } = {
        id: `local-${uuid()}`,
        ...value,
      };

      return valueWithId;
    });

    const tableRows: TableRow[] = [];
    const directoryValues: TDirectoryItem[] = [];

    valuesWithId.forEach((value) => {
      tableRows.push({ ...serializeFormDataIntoTableRowData(value, this.directories, directoryType), id: value.id });

      const { id, ...rest } = value;
      directoryValues.push({
        id: id,
        data: rest,
        status: 'ACTIVE',
        objectType: directoryType,
      });
    });

    this.tableRows = tableRows;
    this.directoryItems = directoryValues;
  }
}
