import { observer } from 'mobx-react-lite';

import { TableCell } from 'src/shared/components/table-cell';
import { ColumnType, TableRow } from 'src/store/table/types';

import styles from './multi-cell.module.scss';

interface Props {
  row: TableRow;
  rowIndex: number;
  onSelectRow: (index: number) => void;
  onEditCell: (currentRowIndex: number, column: string, value: string | number | boolean) => void;
  childColumns?: Omit<ColumnType, 'width'>[];
  editing?: boolean;
}

export const MultiCell = observer(function MultiCell({
  row,
  rowIndex,
  onSelectRow,
  onEditCell,
  editing,
  childColumns,
}: Props) {
  return (
    <div className={styles.container}>
      {childColumns?.map((childColumn, index) => (
        <div className={styles.cell} key={index}>
          <TableCell
            row={row}
            rowIndex={rowIndex}
            handleSelectRow={onSelectRow}
            onEditCell={onEditCell}
            cellType={childColumn.type}
            columnName={childColumn.name}
            editing={editing}
          />
        </div>
      ))}
    </div>
  );
});
