import { Spin, Table } from 'antd';
import { ColumnsType as AntdColumnsType } from 'antd/lib/table';
import classNames from 'classnames';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import ResizeObserver from 'rc-resize-observer';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { VariableSizeGrid } from 'react-window';

import { ColumnType, ResizeParamsType } from 'src/store/table/types';

import { ResizableTableTitle } from '../resizable-table-title';
import { ResizeTableLine } from '../resize-table-line';

import styles from './virtual-table.module.scss';

interface Props {
  columns: AntdColumnsType<ColumnType[]>;
  dataSource: Record<string, React.ReactNode>[];
  cellHeight: number;
  isLoading: boolean;
  resizeParams: ResizeParamsType;
  setLeftOffset: (leftOffset: number) => void;
  fetchMoreTableData(): void;
  canLoadMore: boolean;
  tableHeight?: number;
}

export const VirtualTable = observer(function VirtualTable({
  columns,
  dataSource,
  cellHeight,
  isLoading,
  resizeParams,
  tableHeight = 500,
  setLeftOffset,
  fetchMoreTableData,
  canLoadMore,
}: Props) {
  const [tableWidth, setTableWidth] = useState<number>(0);

  const [connectObject] = useState(() =>
    Object.defineProperty({}, 'scrollLeft', {
      get: () => {
        if (gridRef.current) {
          return gridRef.current?.state?.scrollLeft;
        }
        return null;
      },
      set: (scrollLeft: number) => {
        if (gridRef.current) {
          gridRef.current.scrollTo({ scrollLeft });
        }
      },
    })
  );

  const tableRef = useRef<HTMLDivElement>(null);

  const gridRef = useRef<any>(null);

  const fixColumnRef = useRef<HTMLDivElement>(null);

  const fixedColumns = useMemo(() => columns?.filter((column) => column.fixed === 'left'), [columns]);

  const mergedColumns = columns!.map((column) => {
    if (column.width) {
      return column;
    }

    return {
      ...column,
      width: Math.floor(tableWidth / columns!.filter(({ width }) => !width).length),
    };
  });

  function resetVirtualGrid(): void {
    gridRef.current?.resetAfterIndices({
      columnIndex: 0,
      rowIndex: 0,
      shouldForceUpdate: true,
    });
  }

  function renderVirtualList(rawData: object[], { ref, onScroll }: any) {
    ref.current = connectObject;

    function getColumnWidth(index: number) {
      const { width, fixed } = mergedColumns[index];

      return fixed ? 0 : (width as number);
    }

    function getFixedColumnsWidth(): number {
      return fixedColumns?.reduce((sum, { width }) => sum + Number(width), 0) || 0;
    }

    return (
      <div className={styles.tableBodyContainer} ref={fixColumnRef}>
        {fixedColumns?.map((column, index) => (
          <div
            key={index}
            className={clsx(styles.fixedColumn, index + 1 === fixedColumns.length && styles.fixedColumnLast)}
            style={{ height: `${tableHeight - 4}px`, width: `${column.width}px` }}
          >
            {dataSource?.map((item, index) => {
              return (
                <div key={index} className={styles.fixedTableCell} style={{ height: `${cellHeight}px` }}>
                  {/* @ts-ignore */}
                  {item[column?.dataIndex]}
                </div>
              );
            })}
          </div>
        ))}

        <VariableSizeGrid
          ref={gridRef}
          columnCount={mergedColumns.length}
          columnWidth={getColumnWidth}
          height={tableHeight}
          rowCount={rawData.length}
          rowHeight={() => cellHeight}
          width={tableWidth - getFixedColumnsWidth()}
          onScroll={({ scrollLeft }) => onScroll({ scrollLeft })}
          className={styles.virtualTableGrid}
        >
          {({ columnIndex, rowIndex, style }) => {
            if (mergedColumns[columnIndex].fixed === 'left') {
              return null;
            }

            return (
              <div
                className={classNames('virtual-table-cell', {
                  'virtual-table-cell-last': columnIndex === mergedColumns.length - 1,
                })}
                style={style}
              >
                {(rawData[rowIndex] as Record<string, React.ReactNode>)[(mergedColumns as any)[columnIndex].dataIndex]}
              </div>
            );
          }}
        </VariableSizeGrid>
      </div>
    );
  }

  useEffect(() => {
    return () => resetVirtualGrid();
  }, [tableWidth, columns]);

  useEffect(() => {
    tableRef.current && setLeftOffset(tableRef.current.getBoundingClientRect().x);
  }, [setLeftOffset]);

  useEffect(() => {
    const node = fixColumnRef.current;

    if (!node || isLoading) {
      return;
    }

    const domElements = [...node.children];

    const callback = (event: Event): void => {
      const target = event.currentTarget;

      if (!target || !(target instanceof HTMLElement)) {
        return;
      }

      const scrollTop = target.scrollTop;
      const clientHeight = target.clientHeight;
      const scrollHeight = target.scrollHeight;

      domElements.forEach((column) => {
        column.scrollTop = scrollTop;
      });

      if (canLoadMore) {
        if (scrollTop + clientHeight + 1 >= scrollHeight) {
          fetchMoreTableData();
        }
      }
    };

    domElements.forEach((column) => {
      column.addEventListener('scroll', callback);
    });

    return () => {
      domElements.forEach((column) => {
        column.removeEventListener('scroll', callback);
      });
    };
  }, [fetchMoreTableData, isLoading, canLoadMore, fixedColumns]);

  return (
    <div ref={tableRef} className={styles.wrapper}>
      <ResizeTableLine resizeParams={resizeParams} action="static" />
      <ResizeObserver
        onResize={({ width }) => {
          setTableWidth(width);
        }}
      >
        <Table
          className={styles.tableContainer}
          columns={mergedColumns as any}
          dataSource={dataSource}
          showSorterTooltip={false}
          pagination={false}
          scroll={{ y: '100%', x: '100%' }}
          loading={{
            indicator: <Spin size="large" />,
            spinning: isLoading,
          }}
          components={{
            // @ts-ignore
            body: renderVirtualList,
            header: { cell: ResizableTableTitle },
          }}
        />
      </ResizeObserver>
      <ResizeTableLine resizeParams={resizeParams} action="dynamic" />
    </div>
  );
});
