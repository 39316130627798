import { useContext } from 'react';

import { RootStore } from './root-store';
import { RootStoreContext } from './root-store-context';

export function useStore(): RootStore {
  const store = useContext(RootStoreContext);

  if (!store) {
    throw new Error('Store was not provided. Did you forget wrap in <StoreProvider>?');
  }

  return store;
}
