import { TextField } from 'src/features/directory-form/entities/control-entities';
import { TDirectoryTypeAttribute } from 'src/store/directories/types';

import { TTextFieldRaw } from '../../types';

export const mapTextFieldItem = (item: TTextFieldRaw, attribute: TDirectoryTypeAttribute | undefined): TextField => {
  const fieldProps = {
    formElementRefId: item.attrName,
    fieldId: attribute?.name,
    unit: attribute?.unit,
    required: !!attribute?.required,
    enableIf: item.enableIf,
    showIf: item.showIf,
    label: attribute?.defaultLabel,
    defaultValueLink: item.defaultValueLink,
    restrictions: {
      required: !!attribute?.required,
      ...attribute?.restrictions,
    },
    type: item.type,
  };

  return new TextField(fieldProps);
};
