import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import styles from './input-label.module.scss';

type Props = {
  text: string;
  className?: string;
};

export const InputLabel = observer(function InputLabel({ text, className }: Props) {
  return (
    <div className={styles.test} title={text}>
      <p className={clsx(className, styles.label)}>{text}</p>
    </div>
  );
});
