import {
  TMatchingItemType,
  MatchHeadersType,
  TDirectoryValueIdsList,
} from 'src/pages/import-matching-page/import-matching-page.store';
import { throwApiError } from 'src/shared/utils/throwApiError';

import { agent } from '../agent';

import { mapMatchExcelHeaders, serializeMatchHeadersData } from './serializers';
import { MatchResponseType, UploadResponseType } from './types';

export async function uploadFile(file: File): Promise<UploadResponseType> {
  try {
    const formData = new FormData();

    formData.append('file', file);

    const { data } = await agent.post<UploadResponseType>('/import/excel', formData, {
      headers: { 'content-type': 'multi-part/formdata' },
    });

    return data;
  } catch (e) {
    throwApiError(e);
  }
}

export async function matchExcelHeaders(fileId: number, type: string, sheet: string): Promise<MatchHeadersType> {
  try {
    const { data } = await agent.get<MatchResponseType>(`/import/excel/${fileId}/${type}`, { params: { sheet } });

    return mapMatchExcelHeaders(data);
  } catch (e) {
    throwApiError(e);
  }
}

export async function parseExcelData(
  fileId: number,
  sheet: string,
  type: string,
  matchedData: TMatchingItemType[]
): Promise<TDirectoryValueIdsList> {
  try {
    const { data } = await agent.post<TDirectoryValueIdsList>(
      `/import/excel/${fileId}/${type}`,
      serializeMatchHeadersData(matchedData),

      {
        params: { sheet },
      }
    );

    return data;
  } catch (e) {
    throwApiError(e);
  }
}
