import { observer } from 'mobx-react-lite';

import { Control } from '../entities/abstract-entities';
import { FormStore } from '../entities/form.entity';
import { NewWellColumnItem } from '../new-well-column-item';

import styles from './new-well-column.module.scss';

type Props = {
  items: Control[];
  formStore: FormStore;
  getLabel: (item: Control) => string;
};

export const NewWellColumn = observer(function NewWellColumn({ items, formStore, getLabel }: Props) {
  return (
    <div className={styles.column}>
      {items.map((item) => (
        <NewWellColumnItem
          key={item.formElementRefId || item.fieldId}
          item={item}
          formStore={formStore}
          getLabel={getLabel}
        />
      ))}
    </div>
  );
});
