import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { InputLabel } from 'src/shared/components/form-components/input-label';

import { Label } from '../../entities/control-entities/label.entity';

import styles from './form-label-control.module.scss';

type Props = {
  item: Label;
  label?: string;
};

export const FormLabelControl = observer(function FormLabelControl({ item, label }: Props) {
  return (
    <div className={styles.wrapper}>
      {!!label && <InputLabel text={label} />}
      <div className={styles.innerWrapper}>
        <p className={clsx(item.formElementRefId ? styles.valueLabel : styles.primitivelabel)}>
          {item.valueWithLabel?.label}
        </p>
      </div>
    </div>
  );
});
