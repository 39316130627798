import { action, makeObservable, observable } from 'mobx';

import { TRequiredIf } from 'src/api/directory/types';

import { Control } from '../abstract-entities';
import { TControlValueType } from '../types';

type TCheckboxData = {
  formElementRefId: string;
  fieldId?: string;
  disabledOnFalse?: string[];
  disabledOnTrue?: string[];
  defaultValueLink?: string;
  initialValue?: boolean;
  type: TControlValueType;
  requiredIf?: TRequiredIf[];
};

export class Switcher extends Control<boolean> {
  @observable value: boolean;
  readonly disabledOnTrue: string[];
  readonly disabledOnFalse: string[];

  constructor(data: TCheckboxData) {
    super(data);
    this.value = !!data.initialValue;
    this.disabledOnFalse = data.disabledOnFalse || [];
    this.disabledOnTrue = data.disabledOnTrue || [];

    makeObservable(this);
  }

  setValue(value?: boolean | null) {
    this.value = !!value;
  }

  @action.bound
  toggleIsActive(value: boolean) {
    this.value = value;
  }

  @action.bound
  resetControl(): void {
    this.value = false;
  }
}
