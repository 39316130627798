import { action, makeObservable, observable } from 'mobx';
import { v4 as uuid } from 'uuid';

import { mapItems } from 'src/api/directory/serializers/common-serializers';
import { TFilter, TFilterRows, TItemRaw, TRequiredIf } from 'src/api/directory/types';
import i18n from 'src/i18n';
import { hasValue } from 'src/shared/lib/common';
import { checkIsObjectWithKeys } from 'src/shared/utils/checkIsObjectWithKeys';
import { Directories } from 'src/store/directories/directories.store';
import { TDirectoryItem, TDirectoryTypeAttribute, TOption } from 'src/store/directories/types';

import { Control, ItemContainer, ValidatableItem } from '../abstract-entities';
import { TControlValueType, TEnableIf } from '../types';

export type TRemovableRowRowView = {
  items: TItemRaw[];
  attributes: TDirectoryTypeAttribute[];
};

type TRemovableRowOptions = {
  directories: Directories;
  filters: TFilter[];
  attributes: TDirectoryTypeAttribute[];
  fieldId: string;
  refObjectType?: string;
  formElementRefId: string;
  enableIf?: TEnableIf[];
  defaultValueLink?: string;
  buttonLabel?: string;
  relatedToButtonComboboxAttrName?: string;
  rowView: TRemovableRowRowView;
  type: TControlValueType;
  requiredIf?: TRequiredIf[];
  isStaticRows: boolean;
  filterRows?: TFilterRows;
};

function getButtonOptions(data: TDirectoryItem[] | null): TOption[] {
  if (!data) return [];

  const options: TOption[] = [];

  for (const sectionType of data) {
    const label = sectionType.data.name;

    if (
      typeof label !== 'string' &&
      (typeof label !== 'number' || (typeof label === 'number' && Number.isNaN(Number(label))))
    ) {
      continue;
    }

    options.push({
      label: label.toString(),
      value: sectionType.id,
    });
  }

  return options;
}

export class RemovableRow extends ValidatableItem<Record<string, unknown>[]> {
  readonly initialButtonOptions: TOption[] = [];
  readonly buttonLabel: string;
  readonly rowView: TRemovableRowRowView;
  readonly filters: TFilter[];
  readonly refObjectType?: string;
  readonly relatedToButtonComboboxAttrName?: string;
  readonly filterRows?: TFilterRows;
  readonly isStaticRows: boolean;
  private readonly directories: Directories;

  @observable buttonOptions: TOption[];
  @observable filteredRows: RemovableRowRow[] | null = null;
  @observable rows: RemovableRowRow[] = [];

  constructor(item: TRemovableRowOptions) {
    super(item);
    this.refObjectType = item.refObjectType;
    this.filters = item.filters;
    this.isStaticRows = item.isStaticRows;
    this.filterRows = item.filterRows;
    this.directories = item.directories;
    this.rowView = item.rowView;
    this.relatedToButtonComboboxAttrName = item.relatedToButtonComboboxAttrName;
    this.buttonLabel = item.buttonLabel || i18n.t('common:add');
    this.initialButtonOptions = getButtonOptions(item.directories.getDirectory(item.refObjectType));
    this.buttonOptions = this.initialButtonOptions;

    makeObservable(this);
  }

  checkIsReady(): boolean {
    let isReady = true;
    this.rows.forEach((row) => {
      if (!row.checkIsReady()) {
        isReady = false;
      }
    });
    return isReady;
  }

  @action.bound
  setFilteredRows(filteredRows: RemovableRowRow[]): void {
    this.filteredRows = filteredRows;
  }

  @action.bound
  clearError(): void {
    this.rows.forEach((row) => {
      row.clearError();
    });
  }

  @action.bound
  validate(): void {
    this.rows.forEach((row) => row.validate());
  }

  @action.bound
  setButtonOptions(options: TOption[]): void {
    this.buttonOptions = options;
  }

  @action.bound
  addRow(selectedValue?: number | string): void {
    const rowView = this.rowView;

    const controls = mapItems(rowView.items, this.directories, rowView.attributes);
    const newRow = new RemovableRowRow(controls);
    if (this.relatedToButtonComboboxAttrName && hasValue(selectedValue)) {
      const relatedCombobox = newRow.fieldsList.find(
        (field) => field.formElementRefId === this.relatedToButtonComboboxAttrName
      );

      relatedCombobox?.setValue(selectedValue);
    }

    this.rows.push(newRow);
  }

  @action.bound
  deleteRow(row: RemovableRowRow): void {
    this.rows = this.rows.filter((currRow) => currRow.id !== row.id);
  }

  @action.bound
  setRows(rows: RemovableRowRow[]): void {
    this.rows = rows;
  }

  get value(): Record<string, unknown>[] {
    return this.rows.map((row) => row.value);
  }

  @action.bound
  setValue(value: unknown): void {
    if (Array.isArray(value)) {
      value.forEach((rowValue) => {
        const rowView = this.rowView;

        const controls = mapItems(rowView.items, this.directories, rowView.attributes);
        const newRow = new RemovableRowRow(controls);

        newRow.setValue(rowValue);

        this.rows.push(newRow);
      });
    }
  }

  @action.bound
  resetControl(): void {
    this.rows = [];
  }
}

export class RemovableRowRow extends ItemContainer {
  id: string = uuid();

  constructor(items: Control[]) {
    super({ type: 'Object' }, items);
  }

  get value(): Record<string, unknown> {
    const valuesObj: Record<string, unknown> = {};

    this.fieldsList.forEach((field) => {
      if (field.formElementRefId) {
        valuesObj[field.formElementRefId] = field.value;
      }
    });

    return valuesObj;
  }

  checkIsReady(): boolean {
    let isReady = true;
    this.fieldsList.forEach((field) => {
      if (field instanceof ValidatableItem && field.restrictions?.required && !field.checkIsReady()) {
        isReady = false;
      }
    });
    return isReady;
  }

  clearError(): void {
    this.fieldsList.forEach((field) => {
      if (field instanceof ValidatableItem) {
        field.clearError();
      }
    });
  }

  validate(): void {
    this.fieldsList.forEach((field) => {
      if (field instanceof ValidatableItem) {
        field.validate();
      }
    });
  }

  setValue(value: unknown): void {
    if (checkIsObjectWithKeys(value)) {
      this.fieldsList.forEach((field) => {
        if (field.formElementRefId) {
          const fieldValue = value[field.formElementRefId];

          if (hasValue(fieldValue)) {
            field.setValue(fieldValue);
          }
        }
      });
    }
  }
  resetControl(): void {
    this.fieldsList.forEach((field) => field.resetControl());
  }
}
