import { Input as AntdInput, InputProps } from 'antd';
import { observer } from 'mobx-react-lite';

export const Input = observer(function Input({
  className,
  value,
  prefix,
  suffix,
  onChange,
  onBlur,
  onFocus,
  ...rest
}: InputProps) {
  return (
    <AntdInput
      className={className}
      value={value}
      prefix={prefix}
      suffix={suffix}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      {...rest}
    />
  );
});
