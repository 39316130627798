export const storage = {
  GET: <TValue>(key: string): null | TValue => {
    const value = localStorage.getItem(key);
    if (!value) return null;
    return JSON.parse(value);
  },
  SET: <T>(key: string, value: T) => {
    const jsonValue = JSON.stringify(value);
    localStorage.setItem(key, jsonValue);
  },
  DELETE: (key: string) => localStorage.removeItem(key),
};
