import { i18n as Ii18 } from 'i18next';
import { flow, makeObservable, observable } from 'mobx';
import { NavigateFunction } from 'react-router-dom';

import { showErrorMessage } from 'src/shared/utils/messages';

import { AuthStore } from './auth/auth-store';
import { Directories } from './directories/directories.store';
import { I18NextStore } from './i18next/i18next-store';
import { ImportStore } from './import/import-store';
import { LanguageStore } from './language/language-store';
import { NotificationsStore } from './notifications-store/notifications-store';
import { RouterStore } from './router/router-store';
import { SidebarStore } from './sidebar/sidebar-store';
import { ThemeStore } from './theme/theme-store';

export class RootStore {
  readonly auth;
  readonly router;
  readonly sidebar;
  readonly i18;
  readonly language;
  readonly theme;
  readonly notifications;
  readonly directories;
  readonly importStore;

  @observable isInitialized: boolean = false;

  constructor({ navigate, i18 }: RootStore.InitArg) {
    this.auth = new AuthStore();
    this.i18 = new I18NextStore(i18);
    this.router = new RouterStore(navigate);
    this.sidebar = new SidebarStore();
    this.language = new LanguageStore();
    this.theme = new ThemeStore();
    this.notifications = new NotificationsStore();
    this.directories = new Directories(this.i18, this.notifications);
    this.importStore = new ImportStore({ directories: this.directories });

    makeObservable(this);
  }

  @flow.bound
  async *initialize() {
    try {
      await this.auth.initUserService();
      yield;

      await this.auth.loadUserData();
      yield;

      if (!this.auth.isAuthenticated) {
        return;
      }

      await this.directories.loadDirectoriesData();
      yield;

      this.language.init();
    } catch (e) {
      yield;

      console.error(e);
      showErrorMessage('Ошибка при инициализации приложения.');
    } finally {
      this.isInitialized = true;
    }
  }
}

export namespace RootStore {
  export type InitArg = {
    navigate: NavigateFunction;
    i18: Ii18;
  };
}
