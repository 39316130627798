import { observer } from 'mobx-react-lite';

import { Checkbox } from 'src/shared/components/checkbox';

import styles from './check-table-cell.module.scss';

interface Props {
  isChecked: boolean;
  currentRowIndex: number;
  column: string;
  onEditCell?: (currentRowIndex: number, column: string, value: boolean, id?: number, type?: string) => void;
  id?: number;
  type?: string;
  disabled?: boolean;
  isReadonly?: boolean;
}

export const CheckTableCell = observer(function EditableTextField({
  id,
  type,
  isChecked,
  onEditCell,
  disabled,
  isReadonly,
  currentRowIndex,
  column,
}: Props) {
  function handleChange() {
    onEditCell?.(currentRowIndex, column, !isChecked, id, type);
  }

  return (
    <div className={styles.container}>
      <Checkbox onChange={handleChange} isChecked={isChecked} isDisabled={disabled || isReadonly} />
    </div>
  );
});
