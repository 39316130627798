import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useStore } from 'src/store/use-store';

import { TMenuItem } from '../navigation/types';

import styles from './styles.module.scss';

type Props = {
  isActive?: boolean;
  isOpened: boolean;
  item: TMenuItem;
  onClick?: () => void;
};

export const MenuItem = observer(function MenuItem({ isActive, isOpened, item, onClick }: Props) {
  const { t } = useTranslation('sidebar');
  const { theme } = useStore();

  return (
    <button
      className={clsx(styles.wrapper, isActive && styles.wrapper__active, isOpened && styles.wrapper__opened)}
      onClick={onClick}
    >
      <div className={clsx(styles.iconWrapper, isActive && styles.iconWrapper__active)}>
        {!!item?.notifications?.count && <div className={styles.notificationsIconBall} />}
        {item.renderIcon(
          clsx(styles.icon, isActive && styles.icon__active, theme.theme === 'dark' && styles.icon__dark)
        )}
      </div>
      <div className={clsx(styles.textWrapper, isOpened && styles.textWrapper__opened)}>
        <p className={clsx(styles.text, isActive && styles.text__active, isOpened && styles.text__opened)}>
          {t(item.name)}
        </p>
        {!!item?.notifications?.count && (
          <p className={clsx(styles.subTextFont, styles.notificationsCount)}>{item.notifications.count}</p>
        )}
      </div>
    </button>
  );
});
