import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

import styles from './icon-button.module.scss';

interface Props {
  icon: ReactNode;
  onClick: VoidFunction;
  className?: string;
}

export const IconButton = observer(function IconButton({ icon, onClick, className }: Props) {
  return (
    <button className={clsx(className, styles.button)} onClick={onClick}>
      <div className={styles.iconWrapper}>{icon}</div>
    </button>
  );
});
