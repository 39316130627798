import { TFunction } from 'react-i18next';

export class ErrorWithTranslation extends Error {
  errorTranslation: string;

  constructor() {
    super();
    this.errorTranslation = '';
  }

  translateError(t: TFunction) {
    return t(this.errorTranslation);
  }
}
