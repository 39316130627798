import { TFieldItem, TItemRaw } from 'src/api/directory/types';

import { TTableFilter, TSorting } from '../table/types';

export type TDirectoryTypeItem = {
  name: string;
  label: string;
};

export type TComboboxOptionsSorting = 'ASC' | 'DESC';

export type TDirectoryType = {
  name: string;
  label: string;
  items: TDirectoryTypeItem[];
};

export type TDirectoryTypeAttributeWithReference = {
  type: ' String' | 'Number' | 'Boolean' | 'DateTime' | 'ObjectReference';
  refObjectType: string;
  refObjectAttr: string;
};

export type TDirectories = {
  [key: string]: TDirectoryItem[];
};

export type TDirectoryItem = {
  [key: string]: string | number | Record<string, TFieldItem>;
  id: number | string;
  status: string;
  objectType: string;
  data: {
    [key: string]: TFieldItem;
  };
};

export type TRequestDataItem = Pick<TDirectoryItem, 'data'> & {
  id?: number;
};

export type TDirectoryTypeAttribute = {
  name: string;
  defaultLabel: string;
  type: 'String' | 'Number' | 'Boolean' | 'DateTime' | 'ObjectReference' | 'Object';
  refObjectType?: string;
  refObjectAttr?: string;
  required?: boolean;
  requiredOr?: string[];
  isArray?: boolean;
  unit?: string;
  restrictions?: TRestrictions;
  optionsSorting?: TComboboxOptionsSorting;
  attributes?: (TDirectoryTypeAttribute & {
    isValueAttr?: boolean;
    isIdAttr?: boolean;
  })[];
};

export type TDirectoriesAttributesDictionary = {
  [key: string]: TDirectoryTypeAttribute;
};

export type TTableColumnRaw = {
  label?: string;
  mapping?: Record<string, string>;
  attrName?: string;
  valuePath?: string;
  innerColumns?: TTableColumnRaw[];
};

export type TTableView = {
  join: TRefQuery;
  columns: TTableColumnRaw[];
  massCopy?: {
    attr: string;
    label: string;
  };
  topFilters?: TItemRaw[];
};

export enum DictionaryStatus {
  active = 'ACTIVE',
  deleted = 'DELETED',
}

export type TTableData = {
  id: number;
  status: DictionaryStatus;
  objectType: string;
  data: Record<string, string | number | Record<string, string | number>[]>;
};

export type TRestrictions = {
  min?: TRestriction[];
  max?: TRestriction[];
};

export type TRestriction = TAttrRestriction | TConstRestriction;

export type TAttrRestriction = {
  type: 'ATTR';
  // Всегда содержит ссылку на определенный контрол (fieldId), поэтому всегда строка
  value: string;
};

export type TConstRestriction = {
  type: 'CONST';
  value: number;
};

export type TRefQuery<T = TTableFilter> = {
  objectType: string;
  joinedAlias?: string;
  join?: TJoin[];
  order?: TOrder[];
  where?: T[];
  hideInResult?: boolean;
  limit?: {
    offset: number;
    limit: number;
  };
};

export type TRefQueryVariable = {
  control: string;
  var: string;
};

export type TOrder = {
  joinedAlias: string;
  attr: string;
  direction: TSorting;
};

export type TOption = {
  label: string;
  value: string | number;
};

export type TJoin = {
  joinType?: string;
  hideInResult?: boolean;
  mainObjectAlias?: string;
  mainObjectType?: string;
  mainAttribute: string;
  joinedObjectType: string;
  joinedAttribute: string;
  joinedAlias: string;
  where?: Record<string, string | number | boolean | null>[];
};
