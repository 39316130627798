import { action, computed, makeObservable, observable } from 'mobx';

import { TRequiredIf } from 'src/api/directory/types';

import { IRestrictions } from '../types';
import { Validation } from '../validation/validation';

import {
  TTabProps,
  IItemProps,
  TValidatableItemProps,
  TFieldProps,
  TControlError,
  TShowIf,
  TEnableIf,
  TControlValueType,
} from './types';

export interface IFormElement {
  formElementRefId?: string;
}

export abstract class Tab implements IFormElement {
  formElementRefId: string;
  label: string;

  constructor({ formElementRefId, label }: TTabProps) {
    this.formElementRefId = formElementRefId;
    this.label = label;
  }

  abstract get errorsCount(): number;
}

export abstract class Control<T = unknown> implements IFormElement {
  readonly type: TControlValueType;

  @observable isDisabled = false;
  @observable parentControl?: ItemContainer;

  formElementRefId;
  fieldId?: string;
  label?: string;
  comment?: string;
  defaultValueLink?: string;
  enableIf?: TEnableIf[];
  showIf?: TShowIf[];
  requiredIf?: TRequiredIf[];

  constructor(item: IItemProps) {
    this.type = item.type;
    this.formElementRefId = item.formElementRefId;
    this.defaultValueLink = item.defaultValueLink;
    this.fieldId = item.fieldId;
    this.comment = item.comment;
    this.label = item.label;
    this.enableIf = item.enableIf;
    this.showIf = item.showIf;
    this.requiredIf = item.requiredIf;

    makeObservable(this);
  }

  @action.bound
  setParentControl(item: ItemContainer) {
    this.parentControl = item;
  }

  abstract get value(): T;

  abstract setValue(value: T): void;
  abstract resetControl(): void;

  @action.bound
  setIsDisabled(is: boolean) {
    this.isDisabled = is;
  }
}

export abstract class ItemContainer<C extends Control = Control> extends Control {
  @observable fieldsList: C[];
  parentControl?: ItemContainer;

  constructor(item: IItemProps, fieldsList: C[], parentControl?: ItemContainer) {
    super(item);
    this.fieldsList = fieldsList;
    this.parentControl = parentControl;
    fieldsList.forEach((field) => field.setParentControl(this));

    makeObservable(this);
  }

  @action.bound
  setFieldsList(list: C[]): void {
    this.fieldsList = list;
  }

  @computed
  get fields(): Record<string, C> {
    const fields: Record<string, C> = {};

    this.fieldsList.forEach((field) => {
      const id = field.fieldId || field.formElementRefId;
      if (id) {
        fields[id] = field;
      }
    });

    return fields;
  }
}

export abstract class ValidatableItem<T> extends Control<T> {
  @observable errorText?: string | [string, { [locKey: string]: number | string }];
  @observable restrictions?: IRestrictions;
  useInMainProgressBar: boolean;
  readonly requiredOr?: string[];
  validation?: Validation<T>;

  constructor(item: TValidatableItemProps) {
    super(item);
    this.requiredOr = item.requiredOr;
    this.errorText = undefined;
    this.useInMainProgressBar = !!item.useInMainProgressBar;
    this.restrictions = item.restrictions;

    makeObservable(this);
  }

  abstract clearError(): void;

  @action.bound
  setError(error: TControlError): void {
    this.errorText = error;
  }

  @action.bound
  setIsRequired(is: boolean) {
    if (this.restrictions) {
      this.restrictions.required = is;
    }
  }

  abstract validate(): void;

  abstract checkIsReady(): boolean;

  @computed
  get isReady(): boolean {
    return this.checkIsReady();
  }
}

export abstract class Field<T> extends ValidatableItem<T> {
  placeholder?: string;
  unit?: string;

  constructor(props: TFieldProps) {
    super(props);
    this.placeholder = props.placeholder;
    this.unit = props.unit;
  }
}
