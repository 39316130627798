import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { Switcher } from '../../entities/control-entities';
import { NewWellSwitcher } from '../../new-well-switcher/new-well-switcher';

import styles from './switcher-feature.module.scss';

type Props = {
  className?: string;
  item: Switcher;
  isDisabled?: boolean;
};

export const SwitcherFeature = observer(function SwitcherFeature({ className, item, isDisabled }: Props) {
  return (
    <div className={clsx(styles.wrapper, className)}>
      <NewWellSwitcher isChecked={item.value} isDisabled={isDisabled} onChange={item.toggleIsActive} />
    </div>
  );
});
