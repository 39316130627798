import { action, computed, flow, makeObservable, observable, toJS } from 'mobx';
import moment, { Moment } from 'moment';

import { changeObjectStatus, getTableData } from 'src/api/directory/requests';
import { mapItem } from 'src/api/directory/serializers/common-serializers';
import { getParsedData } from 'src/api/directory/utils';
import { getSortingKeyByColumnName } from 'src/features/directory/views/resizable-table-title/utils';
import { Control } from 'src/features/directory-form/entities/abstract-entities';
import { hasValue } from 'src/shared/lib/common';

import { Directories } from '../directories/directories.store';
import {
  DictionaryStatus,
  TDirectoryItem,
  TDirectoryTypeAttribute,
  TOrder,
  TRefQuery,
  TTableData,
  TTableView,
} from '../directories/types';
import { I18NextStore } from '../i18next/i18next-store';
import { NotificationsStore } from '../notifications-store/notifications-store';
import { RootStore } from '../root-store';

import { PAGE_LIMIT } from './consts';
import { ColumnType, ResizeParamsType, TableRow, TFilters, TSorting, TTableFilter } from './types';

export class TableStore {
  readonly directories: Directories;
  readonly notifications: NotificationsStore;
  readonly i18: I18NextStore;

  @observable columnsData: ColumnType[];
  @observable tableData: TableRow[];
  @observable validationList: TTableData[] = [];
  @observable attributes: TDirectoryTypeAttribute[] = [];
  @observable draftTableData: TableRow[] = [];
  @observable year: number | null = null;
  @observable loading: boolean = false;
  @observable isCheckable: boolean = false;
  @observable yearFilterItem: Control | null = null;
  @observable period: Moment | null = null;
  @observable isEditMode: boolean = false;
  @observable selectedRows: Record<string, boolean> = {};
  @observable isEditFormType: boolean = false;
  @observable tableView?: TTableView;
  @observable rawTableData: TDirectoryItem[] = [];
  @observable order?: TOrder;
  @observable filters?: TTableFilter[];
  @observable filterValues?: TFilters;
  @observable canLoadMore: boolean = true;
  @observable directoryType?: string;
  @observable resizeParams: ResizeParamsType = {
    isResize: false,
    leftOffset: null,
    columnIndex: null,
    columnWidth: null,
    minColumnWidth: null,
    leftColumnCoordinates: null,
    startCoordinates: null,
    xCoordinates: null,
  };

  constructor(rootStore: RootStore) {
    this.directories = rootStore.directories;
    this.notifications = rootStore.notifications;
    this.i18 = rootStore.i18;
    this.columnsData = [];
    this.tableData = [];
    this.period = moment().startOf('date');

    makeObservable(this);
  }

  @action.bound
  setLoading(isLoading: boolean) {
    this.loading = isLoading;
  }

  @action.bound
  setEditFormType() {
    this.isEditFormType = true;
  }

  @action.bound
  setCreatingFormType() {
    this.isEditFormType = false;
  }

  @action.bound
  setIsCheckable(value: boolean) {
    this.isCheckable = value;
  }

  @action.bound
  setTableView(view: TTableView) {
    this.tableView = view;
  }

  @action.bound
  setAttributes(attributes: TDirectoryTypeAttribute[]) {
    this.attributes = attributes;
  }

  setYearFilterItem(item: Control) {
    this.yearFilterItem = item;
  }

  setYear(year: number) {
    this.year = year;
  }

  @action.bound
  setTableFilterValues(filters: TFilters) {
    this.filterValues = filters;
  }

  @action.bound
  setDraftTableData(data: TableRow[]) {
    this.draftTableData = data;
  }

  @action.bound
  setValidationList(data: TTableData[]) {
    this.validationList = data;
  }

  @action.bound
  clearDraftTableData() {
    this.draftTableData = [];
  }

  @action.bound
  addDraftTableData(data: TableRow) {
    /**
     * поле используется в зависимостях useMemo для построения таблицы,
     * переприсваивание, в отличии от метода push, в данном случае, нужно,
     * чтобы сработало обновление таблицы после редактирования
     */
    this.draftTableData = [...this.draftTableData, data];
  }

  @action.bound
  updateDraftTableData(id: number | string, data: TableRow) {
    const dataIndex = this.draftTableData.findIndex((data) => data.id === id);
    const isDataAlreadyExists = dataIndex !== -1;

    if (isDataAlreadyExists) {
      this.draftTableData = this.draftTableData.map((item) => (item.id === id ? data : item));
    } else {
      this.addDraftTableData(data);
    }
  }

  @action.bound
  setPeriod(value: Moment | null) {
    this.period = value;
  }

  @action.bound
  setEditMode(value: boolean) {
    this.isEditMode = value;
  }

  @action.bound
  setDefaultSelectedRows(data: TableRow[]) {
    this.selectedRows = data.reduce<Record<string, boolean>>((acc, next) => {
      acc[Number(next.id)] = false;
      return acc;
    }, {});
  }

  @action.bound
  setSelectedRows(data: Record<string, boolean>) {
    this.selectedRows = data;
  }

  @action.bound
  toggleRow(id: number) {
    if (this.selectedRows) {
      this.selectedRows = { ...this.selectedRows, [id]: !this.selectedRows[id] };
    }
  }

  @action.bound
  getIsAllSelected(): boolean {
    if (!this.selectedRows) return false;

    return Object.values(this.selectedRows).every((value) => value);
  }

  private getFilters(attr: string, values: (string | number)[], isArray: boolean): TTableFilter | null {
    if (!isArray) {
      return {
        attr,
        condition: 'in',
        value: values.join(','),
      };
    }

    if (!values.length) {
      return null;
    }

    const firstFilter = values[0];
    const restFilters = values.slice(1);

    const mainFilter: TTableFilter = {
      attr,
      condition: 'contains',
      value: firstFilter,
    };

    if (restFilters.length) {
      const restFiltersObjects = restFilters.map((filterValue) => ({
        operator: 'or',
        attr,
        condition: 'contains',
        value: filterValue,
      }));

      mainFilter.additionalConditions = restFiltersObjects;
    }

    return mainFilter;
  }

  @action.bound
  setFilters(attr: string, values: (string | number)[], isArray: boolean): TTableFilter[] | undefined {
    if (values.length === 0) {
      this.filters = this.filters?.filter((item) => item.attr !== attr);

      return;
    }

    const newFilter = this.getFilters(attr, values, isArray);

    if (!newFilter) {
      return;
    }

    if (!this.filters) {
      this.filters = [newFilter];

      return [newFilter];
    }

    const draftFilters = this.filters.filter((filterItem) => filterItem.attr !== attr);
    draftFilters.push(newFilter);

    this.filters = draftFilters;

    return this.filters;
  }

  @action.bound
  setTableOrder(key: string, direction?: TSorting): TOrder | undefined {
    const sortingKey = getSortingKeyByColumnName(this.columnsData, key);

    if (sortingKey) {
      const [joinedAlias, attr] = sortingKey.split('.');

      this.order = direction
        ? {
            direction,
            joinedAlias,
            attr,
          }
        : undefined;

      return this.order;
    }
  }

  @action.bound
  setColumnsData(columnsData: ColumnType[]) {
    this.columnsData = columnsData;
  }

  @action.bound
  setRawTableData(data: Record<string, TTableData | TTableData[]>[], type: string) {
    const rawTableData: TDirectoryItem[] = [];

    for (const dataItem of data) {
      const currentItem = dataItem[type];
      if (currentItem && !Array.isArray(currentItem)) {
        rawTableData.push({
          ...currentItem,
          objectType: type,
        });
      }
    }

    this.rawTableData = rawTableData;
  }

  @action.bound
  setTableData(tableData: TableRow[]) {
    this.tableData = tableData;
    this.setDefaultSelectedRows(tableData);
  }

  @action.bound
  addTableData(tableData: TableRow[]) {
    this.tableData.push(...tableData);
    this.setDefaultSelectedRows(this.tableData);
  }

  @action.bound
  setCanLoadMore(canLoadMore: boolean) {
    this.canLoadMore = canLoadMore;
  }

  @action.bound
  updateTableCell(rowIndex: number, column: string, value: string | number | boolean) {
    this.tableData[rowIndex][column] = value;
  }

  @flow.bound
  async *editCell(
    currentRowIndex: number,
    column: string,
    newValue: string | number | boolean,
    id?: number,
    type?: string
  ) {
    if (column === 'status' && id && type) {
      if (this.loading || !this.tableView) return;

      this.setLoading(true);

      try {
        const newStatus = newValue ? DictionaryStatus.active : DictionaryStatus.deleted;
        await changeObjectStatus(type, id, newStatus);

        this.updateTableCell(currentRowIndex, column, newValue);
      } catch (error) {
        yield;
        console.error(error);
      } finally {
        this.setLoading(false);
      }
    } else {
      const selectedRows: number[] = [];
      this.tableData.forEach((row, index) => row.selected && selectedRows.push(index));

      if (selectedRows.length > 0) {
        selectedRows.forEach((item) => this.updateTableCell(item, column, newValue));
        this.updateTableCell(currentRowIndex, column, newValue);
      }

      if (selectedRows.length === 0) {
        this.updateTableCell(currentRowIndex, column, newValue);
      }
    }
  }

  @action.bound
  setSelectAllRows() {
    if (this.isSelectedAllRows) {
      this.tableData.forEach((row) => (row.selected = false));
      return;
    }

    if (!this.isSelectedAllRows) {
      this.tableData.forEach((row) => (row.selected = true));
      return;
    }
  }

  @action.bound
  setDirectoryType(type: string): void {
    this.directoryType = type;
  }

  @action.bound
  resetTableData(): void {
    this.setTableData([]);
    this.setCanLoadMore(true);
    if (this.directoryType) {
      this.setRawTableData([], this.directoryType);
    }
  }

  onFiltersChange = (attr: string, values: (string | number)[], isArray: boolean) => {
    this.setFilters(attr, values, isArray);
    this.resetTableData();

    this.fetchTableData();
  };

  onTableSortingChanged = (columnName: string, value: TSorting) => {
    this.setTableOrder(columnName, value);
    this.resetTableData();

    this.fetchTableData();
  };

  @flow.bound
  async *fetchTableData() {
    if (this.loading || !this.tableView || !this.directoryType) return;

    if (this.tableView.topFilters && this.tableView.topFilters[0].control !== 'YearOnlyPicker') {
      return;
    }

    this.loading = true;

    try {
      let body = toJS(this.tableView.join);

      if (this.order && this.order.direction !== 'DEFAULT') {
        body.order = [this.order];
      }

      if (this.tableView.topFilters) {
        const filterView = this.tableView.topFilters[0];

        this.setIsCheckable(true);

        if (!this.filters?.some((el) => el.attr === 'year')) {
          const filterControl = mapItem(filterView, this.directories);
          this.setYearFilterItem(filterControl);
          this.yearFilterItem?.setValue(moment().year());
          this.setFilters('year', [moment().year()], false);
        }
      }

      body = this.filters ? { ...body, where: this.filters } : body;

      const preparedBody: TRefQuery = {
        ...body,
        limit: {
          limit: PAGE_LIMIT,
          offset: this.tableData.length,
        },
      };

      const joinedData = await getTableData(preparedBody);
      yield;

      if (joinedData.length < PAGE_LIMIT || !joinedData) {
        this.setCanLoadMore(false);
      }

      const parsedData = getParsedData(joinedData, this.tableView.columns, this.attributes);

      this.setRawTableData(joinedData, this.directoryType);
      this.addTableData(parsedData);
    } catch (error) {
      yield;

      console.error(error);
      this.notifications.showNotificationMessage(this.i18.t('directory:Errors.loadMore'));
    } finally {
      this.loading = false;
    }
  }

  @computed
  get isSelectedAllRows() {
    return this.tableData.every((row) => row.selected);
  }

  @action.bound
  selectRow(rowIndex: number) {
    if (this.tableData[rowIndex].selected) {
      this.removeSelectedRow(rowIndex);
      return;
    }

    if (!this.tableData[rowIndex].selected) {
      this.addSelectedRow(rowIndex);
      return;
    }
  }

  @computed
  get normalizedColumnsData() {
    return this.columnsData.reduce<Record<string, ColumnType>>((acc, next) => {
      acc[next.name] = next;
      return acc;
    }, {});
  }

  @action.bound
  getUnit(key?: string): string | undefined {
    return key ? this.normalizedColumnsData[key]?.unit : '';
  }

  @action.bound
  private removeSelectedRow(rowIndex: number) {
    this.tableData[rowIndex].selected = false;
  }

  @action.bound
  private addSelectedRow(rowIndex: number) {
    this.tableData[rowIndex].selected = true;
    if (this.tableData.every((row) => row.selected)) {
    }
  }

  @action.bound
  setLeftOffset(leftOffset: number) {
    this.resizeParams.leftOffset = leftOffset;
  }

  @action.bound
  resizeStart(columnName: string, columnWidth: number, minColumnWidth: number, startCoordinates: number) {
    const columnIndex = this.columnsData.findIndex((column) => column.name === columnName);
    if (columnIndex !== -1) {
      this.resizeParams.isResize = true;
      this.resizeParams.columnIndex = columnIndex;
      this.resizeParams.columnWidth = columnWidth;
      this.resizeParams.minColumnWidth = minColumnWidth;
      this.resizeParams.leftColumnCoordinates = startCoordinates - columnWidth;
      this.resizeParams.startCoordinates = startCoordinates;
      this.resizeParams.xCoordinates = startCoordinates;
    }
  }

  @action.bound
  setXCoordinates(coordinates: number) {
    if (
      hasValue(this.resizeParams.columnIndex) &&
      hasValue(this.resizeParams.columnWidth) &&
      hasValue(this.resizeParams.startCoordinates) &&
      hasValue(this.resizeParams.minColumnWidth)
    ) {
      const newColumnWidth = this.resizeParams.columnWidth + (coordinates - this.resizeParams.startCoordinates);

      if (newColumnWidth > this.resizeParams.minColumnWidth) {
        this.resizeParams.xCoordinates = coordinates;
      }
    }
  }

  @action.bound
  resizeEnd(): ColumnType[] {
    if (
      hasValue(this.resizeParams.columnIndex) &&
      hasValue(this.resizeParams.columnWidth) &&
      hasValue(this.resizeParams.startCoordinates) &&
      hasValue(this.resizeParams.xCoordinates)
    ) {
      this.columnsData[this.resizeParams.columnIndex].width =
        this.resizeParams.columnWidth + (this.resizeParams.xCoordinates - this.resizeParams.startCoordinates);
    }

    this.resizeParams.isResize = false;
    this.resizeParams.columnIndex = null;
    this.resizeParams.columnWidth = null;
    this.resizeParams.minColumnWidth = null;
    this.resizeParams.leftColumnCoordinates = null;
    this.resizeParams.startCoordinates = null;
    this.resizeParams.xCoordinates = null;

    return this.columnsData;
  }
}
