import { AxiosError } from 'axios';

export type TViolation = {
  attrName: string;
  message: string;
};

export type TValidationErrorData = {
  code: 'validation';
  message?: string;
  violations: TViolation[];
};

export abstract class BaseApiError extends Error {
  axiosError: AxiosError | null = null;

  constructor(error?: unknown) {
    super();
    if (error instanceof AxiosError) {
      this.axiosError = error;
    }
  }
}

export class Server500ApiError extends BaseApiError {}

export class Unauthorized401ApiError extends BaseApiError {}

export class Validation400ApiError extends BaseApiError {}

export class NetworkApiError extends BaseApiError {}

export class UnknownApiError extends BaseApiError {}

export class NotFoundApiError extends BaseApiError {}

export class Validation400ApiErrorWithCause<T> extends Validation400ApiError {
  readonly reason: T;

  constructor(error: unknown, reason: T, message?: string) {
    super(error);
    if (message) {
      this.message = message;
    }
    this.reason = reason;
  }
}

export abstract class Error400 extends BaseApiError {}

interface IValidationError {
  error: AxiosError;
  violations?: TViolation[];
}

export class ValidationError extends Error400 {
  violations?: TViolation[];

  constructor(data: IValidationError) {
    super(data.error);
    this.violations = data.violations;
  }
}

export class NotFoundError extends Error {}
