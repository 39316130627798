import { Typography } from 'antd';
import { observer } from 'mobx-react-lite';

import styles from './ellipsis-table-cell.module.scss';

interface Props {
  value?: string | number | boolean;
  onClick?: () => void;
}

export const EllipsisTableCell = observer(function EditableTextField({ value, onClick }: Props) {
  return (
    <div className={styles.container}>
      <Typography.Paragraph ellipsis={{ rows: 3, tooltip: value }} onClick={onClick}>
        {value ?? '-'}
      </Typography.Paragraph>
    </div>
  );
});
