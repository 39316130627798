import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

import styles from './menu-subheader-layout.module.scss';

type Props = {
  className?: string;
  children: ReactNode;
};

export const MenuSubheaderLayout = observer(function MenuSubheaderLayout({ className, children }: Props) {
  return <div className={clsx(styles.wrapper, className)}>{children}</div>;
});
