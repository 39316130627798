import { StringsList } from 'src/features/directory-form/entities/control-entities/strings-list.entity';
import { Directories } from 'src/store/directories/directories.store';
import { TDirectoryTypeAttribute } from 'src/store/directories/types';

import { TStringsListRaw } from '../types';

export const mapStringsList = (
  directories: Directories,
  item: TStringsListRaw,
  attribute?: TDirectoryTypeAttribute
): StringsList => {
  const data = {
    formElementRefId: item.attrName,
    fieldId: attribute?.name,
    label: attribute?.defaultLabel,
    defaultValueLink: item.defaultValueLink,
    restrictions: { required: !!attribute?.required, ...attribute?.restrictions },
    enableIf: item.enableIf,
    showIf: item.showIf,
    requiredIf: item.requiredIf,
    type: item.type,
  };

  return new StringsList(data);
};
