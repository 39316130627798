import { observer } from 'mobx-react-lite';

import { Switcher } from 'src/shared/components/switcher/switcher';
import { THEMES } from 'src/shared/constants/themes';
import { useStore } from 'src/store';

import { themeItems } from './consts';

type Props = {
  orientation: 'vertical' | 'horizontal';
  isCompact: boolean;
};

export const ThemeSwitcher: React.FC<Props> = observer(function ThemeSwitcher({
  orientation = 'horizontal',
  isCompact,
}) {
  const { theme } = useStore();

  return (
    <div>
      <Switcher
        items={themeItems}
        orientation={orientation}
        isActive={theme.theme === THEMES.light}
        isCompact={isCompact}
        onToggle={theme.toggle}
      />
    </div>
  );
});
