import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './directory-form.module.scss';
import { Control } from './entities/abstract-entities';
import { FormStore } from './entities/form.entity';
import { NewWellColumn } from './new-well-column';

type Props = {
  formStore: FormStore;
};

export const DirectoryForm = observer(function DirectoryForm({ formStore }: Props) {
  const { t } = useTranslation();
  const { effect } = formStore;

  const getLabel = (item: Control): string => {
    return item.label || formStore.getFieldName(item) || t('directory:Errors.unrecName');
  };

  useEffect(effect, [effect]);

  if (formStore.directories.isLoading) return null;

  return (
    <div className={styles.wrapper}>
      {formStore.groups.map((group) => {
        return (
          <div key={group.id} className={styles.groupWrapper}>
            {!!group.label && <p className={styles.groupLabel}>{group.label}</p>}
            <div className={styles.columnsWrapper}>
              {group.columns.map((column) => (
                <NewWellColumn key={column.id} items={column.rows} formStore={formStore} getLabel={getLabel} />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
});
