import jwtDecode from 'jwt-decode';

import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, SUB_KEY } from 'src/shared/constants/auth';

import { hasValue } from './common';

type TokenPayload = {
  sub: string | undefined;
  exp: number | undefined;
  realm_access:
    | {
        roles: string[];
      }
    | undefined;
};

const isToken = (token: unknown): token is TokenPayload => {
  return typeof token === 'object' && !!token && 'sub' in token && 'exp' in token && 'realm_access' in token;
};

export const parseToken = (token: string): TokenPayload => {
  const tokenObject = jwtDecode(token);

  if (!isToken(tokenObject) || !tokenObject.sub) {
    throw new Error();
  }

  return tokenObject;
};

export function getIsTokenExpired(): boolean {
  const token = getAccessToken();

  if (!token) {
    return true;
  }

  const { exp } = parseToken(token);
  const now = new Date().getTime() / 1000;

  return hasValue(exp) && now >= exp;
}

export function getAccessToken(): string | null {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}

export function getRefreshToken(): string | null {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
}

export function removeTokens(): void {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  // Also remove the SUB since the SUB is part of the access token.
  localStorage.removeItem(SUB_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
}

export function setTokens(access?: string, refresh?: string): void {
  if (access) {
    localStorage.setItem(ACCESS_TOKEN_KEY, access);

    const { sub } = parseToken(access);

    if (sub) {
      localStorage.setItem(SUB_KEY, sub);
    }
  }

  if (refresh) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refresh);
  }
}
