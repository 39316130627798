import { JoinComboBoxItem } from 'src/features/directory-form/entities/control-entities/join-combobox.entity';
import { MultiCombobox } from 'src/features/directory-form/entities/control-entities/multicombobox.entity';
import { TOption } from 'src/store/directories/types';

const isValidOptionsValue = (value: unknown): value is number | string => {
  return ['string', 'number'].includes(typeof value);
};

export function joinsToOptions(
  item: JoinComboBoxItem | MultiCombobox,
  joinedResult: Record<string, string | number | boolean>[] | null
): TOption[] {
  if (!joinedResult) return [];

  const options: TOption[] = [];

  for (const rawOption of joinedResult) {
    const optionValue = rawOption[`${item.refObjectType}.id`];
    if (!isValidOptionsValue(optionValue)) continue;
    const label = rawOption[`${item.refObjectType}.name`].toString();
    options.push({
      label,
      value: optionValue,
    });
  }

  return options;
}
