import { Control } from 'src/features/directory-form/entities/abstract-entities';
import { Group } from 'src/features/directory-form/entities/group.entity';
import { TDirectoryItem, TRequestDataItem } from 'src/store/directories/types';

import { TObjectList, TSerializedForm } from '../types';

type TSerializedFields = Record<string, unknown>;

const serializeFields = (items: Control[]): TSerializedFields => {
  const serializedFields: TSerializedFields = {};
  for (let item of items) {
    if (!item.fieldId) continue;
    if (item.formElementRefId && item.value) {
      serializedFields[item.fieldId] = item.value;
    }
  }

  return serializedFields;
};

export const serializeFormForRequest = (groups: Group[]) => {
  let serializedForm: TSerializedForm = {};

  for (let group of groups) {
    for (let column of group.columns) {
      serializedForm = { ...serializedForm, ...serializeFields(column.rows) };
    }
  }

  return serializedForm;
};

export const serializeDataForRequest = (data: TDirectoryItem[]): TRequestDataItem[] => {
  return data.map((item) => {
    if (typeof item.id === 'number') {
      return {
        id: item.id,
        data: item.data,
      };
    }

    return {
      data: item.data,
    };
  });
};

export const serializeYearCopyDataForRequest = (
  data: TDirectoryItem[],
  idsList: (number | string)[],
  year: string
): TRequestDataItem[] => {
  const filteredData = data.filter((item) => idsList.includes(item.id));

  return filteredData.map((item) => ({
    data: { ...item.data, year },
  }));
};

export const serializeValidationList = ({
  draftData,
  editingDirectoryId,
}: {
  draftData: TDirectoryItem[];
  editingDirectoryId: number | string | null;
}): TObjectList => {
  const newDataList = draftData
    .filter((item) => item.id !== editingDirectoryId)
    .map((item) => ({
      ...(typeof item.id === 'number' && { id: item.id }),
      data: item.data,
    }));

  return newDataList;
};
