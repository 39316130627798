import { TRefQueryVariable } from 'src/store/directories/types';

export const processReQueryVariablesToObject = (variables?: TRefQueryVariable[]) => {
  const object: Record<string, string> = {};
  if (!variables) {
    return object;
  }
  variables.forEach((variable) => {
    object[variable.var] = variable.control;
  });
  return object;
};
