import { ColumnsType, ColumnType as AntdColumnType } from 'antd/lib/table';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { VirtualTable } from 'src/features/directory/views/virtual-table';
import { DirectoryPageStore } from 'src/pages/directory/directory.store';
import { ColumnType } from 'src/store/table/types';

import { CELL_HEIGHT, MIN_COLUMN_WIDTH, getSettingsColumn } from './constansts';
import styles from './directory-table.module.scss';
import {
  getLeftChildColumnOffset,
  getLeftColumnOffset,
  getTableData,
  getTableHeight,
  getTableWidth,
  getViewingColumns,
} from './utils';

interface Props {
  pageStore: DirectoryPageStore;
}

export const DirectoryTable = observer(function DirectoryTable({ pageStore }: Props) {
  const [tableHeaderHeight, setTableHeaderHeight] = useState<number>(60);

  const { directory } = useParams();

  const table = pageStore.directory.table;

  const columns = getViewingColumns(
    table.isEditMode ? [getSettingsColumn(table.isCheckable ? 3 : 2), ...table.columnsData] : table.columnsData
  );

  const mergedColumns: ColumnsType<ColumnType[]> = columns.map((antdColumn, columnIndex) => ({
    ...antdColumn,
    onHeaderCell: (column) => ({
      columnName: column.key,
      width: (column as AntdColumnType<ColumnType[]>).width,
      minColumnWidth: antdColumn?.children?.length ? antdColumn.children.length * MIN_COLUMN_WIDTH : MIN_COLUMN_WIDTH,
      onResizeStart: table.resizeStart,
      onResizeStop: table.resizeEnd,
      setXCoordinates: table.setXCoordinates,
      isResizable: column.key !== 'settingsColumn',
      isSortable:
        column.key !== 'settingsColumn' && column.key !== 'status' && String(column.key).split('.').length === 1,
      columnType: antdColumn.columnType,
      leftOffset: getLeftColumnOffset(columnIndex, columns, column.fixed),
      isParent: antdColumn?.children?.length && antdColumn.children.length > 0,
      setTableHeaderHeight: setTableHeaderHeight,
      setColumns: table.setColumnsData,
      onTableSortingChanged: table.onTableSortingChanged,
      order: table.order,
      filters: table.filters,
      style: { paddingBottom: 15 },
      onTableFiltersChanged: table.onFiltersChange,
      filterValues: table.filterValues,
      columns: table.columnsData,
      unit: table.getUnit(String(column.key)),
      selectedRows: table.selectedRows,
      isCopyMode: table.isCheckable,
      setSelectedRows: table.setSelectedRows,
    }),
    ...(antdColumn?.children && {
      children: antdColumn?.children.map((childColumn, childColumnIndex) => {
        return {
          ...childColumn,
          onHeaderCell: (column) => ({
            columnName: childColumn.key,
            width: (childColumn as AntdColumnType<ColumnType[]>).width,
            isResizable: false,
            isChild: true,
            setTableOrder: table.setTableOrder,
            onTableFiltersChanged: table.onFiltersChange,
            onTableSortingChanged: table.onTableSortingChanged,
            leftOffset: getLeftChildColumnOffset(
              columnIndex,
              childColumnIndex,
              columns,
              antdColumn.children,
              column.fixed
            ),
            filterValues: table.filterValues,
            columns: table.columnsData,
            order: table.order,
            filters: table.filters,
            isSortable: true,
            unit: childColumn.unit,
          }),
        };
      }),
    }),
  }));

  const dataSource = useMemo(() => {
    const newDraftData = table.draftTableData.filter((item) => typeof item.id === 'string');
    const editedDraftData = table.draftTableData.filter((item) => typeof item.id === 'number');
    const editedDraftDataIds = editedDraftData.map(({ id }) => id);
    const joinedDirectoryData = table.tableData.map((item) => {
      if (editedDraftDataIds.includes(item.id)) {
        return editedDraftData?.find((draftItem) => draftItem.id === item.id) ?? item;
      }

      return item;
    });

    return getTableData(
      table.selectRow,
      table.editCell,
      pageStore.setEditingDirectory,
      pageStore.setCopyDirectory,
      table.columnsData,
      [...newDraftData, ...joinedDirectoryData],
      table.toggleRow,
      table.selectedRows,
      directory,
      table.isCheckable
    );
  }, [
    table.columnsData,
    pageStore.setEditingDirectory,
    pageStore.setCopyDirectory,
    table.editCell,
    table.selectRow,
    table.tableData,
    table.draftTableData,
    directory,
    table.toggleRow,
    table.selectedRows,
    table.isCheckable,
  ]);

  return (
    <div
      className={clsx(styles.wrapper, table.resizeParams.isResize && styles.wrapperResizable)}
      style={{ width: getTableWidth(columns) }}
    >
      <VirtualTable
        columns={mergedColumns}
        dataSource={dataSource}
        cellHeight={CELL_HEIGHT}
        isLoading={pageStore.isLoading || pageStore.directory.loading || table.loading}
        resizeParams={table.resizeParams}
        tableHeight={getTableHeight(dataSource.length, CELL_HEIGHT, tableHeaderHeight, pageStore.availableSpaceHeight)}
        canLoadMore={table.canLoadMore}
        setLeftOffset={table.setLeftOffset}
        fetchMoreTableData={table.fetchTableData}
      />
    </div>
  );
});
