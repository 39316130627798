import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormSidebarStore } from 'src/features/edit-directory-record-sidebar/form-sidebar.store';
import { DirectoryPageStore } from 'src/pages/directory/directory.store';
import { ReactComponent as CheckIcon } from 'src/shared/assets/icons/checkmark.svg';
import { ReactComponent as CrossIcon } from 'src/shared/assets/icons/cross.svg';
import { Button } from 'src/shared/components/button';
import { Sidebar } from 'src/shared/components/sidebar/sidebar';
import { useStore } from 'src/store';
import { TDirectoryItem } from 'src/store/directories/types';

import { DirectoryForm } from '../directory-form';

import styles from './edit-directory-record-sidebar.module.scss';

type Props = {
  isOpened: boolean;
  directory: string | undefined;
  pageStore: DirectoryPageStore;
  isRecursive?: boolean;
  editingDirectory?: TDirectoryItem | null;
  copyingDirectory?: TDirectoryItem | null;
  onClose: () => void;
};

export const EditDirectoryRecordSidebar = observer(function NewDrillingRigSidebar({
  isOpened,
  directory,
  pageStore,
  isRecursive,
  editingDirectory,
  copyingDirectory,
  onClose,
}: Props) {
  const store = useStore();
  const { t } = useTranslation();
  const [formSidebarStore] = useState(() => new FormSidebarStore(store, pageStore, directory));

  const directoryType = formSidebarStore.directories.typesList.find((type) => type.name === directory);

  const [isSaving, setIsSaving] = useState(false);

  const { effect } = formSidebarStore;

  useEffect(() => {
    if (!directory) return;
    formSidebarStore.fetchDirectoryView(directory);
  }, [directory, formSidebarStore]);

  useEffect(() => {
    formSidebarStore.setEditingDirectory(editingDirectory ?? null);
  }, [formSidebarStore, directory, editingDirectory]);

  useEffect(() => {
    formSidebarStore.setCopyingDirectory(copyingDirectory ?? null);
  }, [formSidebarStore, directory, copyingDirectory]);

  useEffect(() => {
    if (formSidebarStore.isFormSent) {
      onClose();
      formSidebarStore.resetSidebar();
    }
  }, [formSidebarStore, formSidebarStore.isFormSent, onClose]);

  useEffect(effect, [effect]);

  if (!formSidebarStore.form) return null;

  const handleCreateNewDirectoryValue = async () => {
    if (!directoryType) return;

    setIsSaving(true);

    try {
      await formSidebarStore.sendForm(directoryType.name, !!isRecursive);
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    onClose();
    formSidebarStore.resetSidebar();
  };

  const addNewDirectoryValueTitle = directoryType?.label
    ? t('directory:addNewDirectoryValueTitle', { type: directoryType.label })
    : t('directory:Errors.unrecName');
  const editDirectoryValueTitle = directoryType?.label
    ? t('directory:editDirectoryValueTitle', { type: directoryType.label })
    : t('directory:Errors.unrecName');

  return (
    <>
      <Sidebar
        title={pageStore.directory.table.isEditFormType ? editDirectoryValueTitle : addNewDirectoryValueTitle}
        size="l"
        closeOnClickOutside={false}
        isOpened={isOpened}
        onClose={handleCancel}
      >
        <div className={styles.wrapper}>
          <div className={styles.newDrillingRigInputsWrapper}>
            <DirectoryForm formStore={formSidebarStore.form} />
          </div>
          <div className={styles.buttonsWrapper}>
            <Button className={styles.button} prefixIcon={<CrossIcon />} variant="danger" onClick={handleCancel}>
              {t('common:Buttons.cancel')}
            </Button>

            <Button
              className={styles.button}
              prefixIcon={<CheckIcon />}
              variant="success"
              loading={isSaving}
              disabled={!formSidebarStore.form.isAllNecessaryFieldsAreFilled || isSaving}
              onClick={handleCreateNewDirectoryValue}
            >
              {t('common:Buttons.save')}
            </Button>
          </div>
        </div>
      </Sidebar>
      <EditDirectoryRecordSidebar
        isOpened={!!formSidebarStore.childSidebarObjectType}
        directory={formSidebarStore.childSidebarObjectType}
        pageStore={pageStore}
        isRecursive
        onClose={formSidebarStore.closeChildSidebar}
      />
    </>
  );
});
