import { ColumnType } from 'src/store/table/types';

const ICON_WIDTH = 20;

export const MIN_COLUMN_WIDTH = 120;
export const DEFAULT_COLUMN_WIDTH = 180;
export const CELL_HEIGHT = 70;

export function getSettingsColumn(iconsNumber?: number): ColumnType {
  return {
    name: 'settingsColumn',
    label: '',
    type: 'checkbox',
    width: 28 + (iconsNumber ?? 0) * ICON_WIDTH,
  };
}
