import { BaseApiError } from 'src/errors';

import { safelyParseJSON } from './safelyParseJSON';

export const serializeAxiosError = (error: BaseApiError): Record<string, unknown> => {
  const axiosError = error.axiosError;
  const info: Record<string, unknown> = {};

  if (axiosError) {
    if (axiosError.response && axiosError.response.data) {
      info.response = axiosError.response.data;
    }
    if ('responseURL' in axiosError.request) {
      info.responseURL = axiosError.request.responseURL;
    }

    if (axiosError.config.headers) {
      info.headers = axiosError.config.headers;
    }

    if (axiosError.config.data) {
      info.body = safelyParseJSON(axiosError?.config.data);
    }
  }

  info.status = axiosError?.status;
  return info;
};
