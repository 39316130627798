import { computed, flow, makeObservable, observable } from 'mobx';

import { Roles } from 'src/shared/constants/auth';
import { getIsTokenExpired } from 'src/shared/lib/auth';
import { showErrorMessage } from 'src/shared/utils/messages';

import { UserService } from './user-service';

export namespace Auth {
  export type UserInfo = {
    name: string;
  };

  export const isUserInfo = (object: unknown): object is UserInfo =>
    typeof object === 'object' && !!object && 'name' in object;
}

export class AuthStore {
  @observable private roles?: string[];

  @observable isAuthenticated = false;
  @observable userInfo: Auth.UserInfo | null = null;

  constructor() {
    makeObservable(this);
  }

  @computed
  get hasEditingPermission(): boolean {
    if (this.roles?.includes(Roles.golibraryEditor)) {
      return true;
    }

    if (this.roles?.includes(Roles.golibraryAdmin)) {
      return true;
    }

    return false;
  }

  @flow.bound
  async *initUserService(): Promise<void> {
    if (getIsTokenExpired()) {
      const isAuthenticated = await UserService.initUserServiceWithCheck();
      yield;

      this.isAuthenticated = isAuthenticated || false;
    } else {
      await UserService.initUserService();
      yield;

      this.isAuthenticated = true;
    }

    const tokenParsed = UserService.getParsedToken();

    this.roles = tokenParsed?.realm_access?.roles;
  }

  @flow.bound
  async *loadUserData(): Promise<void> {
    try {
      const userData = await UserService.getUserData();
      yield;

      if (userData) {
        const userInfo = userData;

        if (Auth.isUserInfo(userInfo)) {
          this.userInfo = userInfo;
        }
      }
    } catch (e) {
      yield;

      console.error(e);
      showErrorMessage('Не удалось получить данные пользователя.');
    }
  }

  @flow.bound
  async *logout(): Promise<void> {
    try {
      await UserService.logout();
      yield;

      this.userInfo = null;
      this.isAuthenticated = false;
    } catch (e) {
      yield;

      console.error(e);
      showErrorMessage('Не удалось выйти из аккаунта.');
    }
  }
}
