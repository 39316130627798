import { RegularComboBox } from 'src/features/directory-form/entities/control-entities';
import { JoinComboBoxItem } from 'src/features/directory-form/entities/control-entities/join-combobox.entity';
import { Directories } from 'src/store/directories/directories.store';
import { TDirectoryTypeAttribute } from 'src/store/directories/types';

import { TComboboxRaw } from '../types';

export const mapComboBoxItem = (
  item: TComboboxRaw,
  attribute: TDirectoryTypeAttribute | undefined,
  directories: Directories
): RegularComboBox | JoinComboBoxItem => {
  const data = {
    formElementRefId: item.attrName,
    directories,
    fieldId: attribute?.name,
    refExternalSource: item.refExternalSource,
    refObjectAttr: attribute?.refObjectAttr,
    refObjectType: attribute?.refObjectType,
    enableIf: item.enableIf,
    showIf: item.showIf,
    requiredOr: attribute?.requiredOr,
    label: attribute?.defaultLabel,
    defaultValueLink: item.defaultValueLink,
    refObjectFilter: item.refObjectFilter,
    restrictions: { required: !!attribute?.required, ...attribute?.restrictions },
    filterByControlValue: item.filterByControlValue,
    filters: item.filters,
    requiredIf: item.requiredIf,
    type: item.type,
    optionsSorting: attribute?.optionsSorting,
  };

  if ('refQuery' in item) {
    const joinComboboxData = {
      ...data,
      refQuery: item.refQuery,
      refQueryVariables: item.refQueryVariables,
      directory: directories.getJoinedObject(item.refQuery),
    };

    return new JoinComboBoxItem(joinComboboxData);
  }

  return new RegularComboBox(data);
};
