import clsx from 'clsx';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { CollapseItem } from './collapse-item';
import styles from './collapse.module.scss';

type Props = {
  value: string[] | number[];
  isOpened: boolean;
  onClose(): void;
  onOpen(): void;
  onDelete(item: string | number): void;
};

export const Collapse = observer(function Collapse({ value, isOpened, onClose, onOpen, onDelete }: Props) {
  const { t } = useTranslation();
  const listRef = useRef<HTMLDivElement>(null);

  const reversedValue = toJS(value).reverse();

  useEffect(() => {
    const element = listRef.current;

    if (element && !isOpened) {
      element.scrollTo(0, 0);
    }
  }, [isOpened]);

  return (
    <div
      className={clsx(
        styles.wrapper,
        !isOpened && value.length > 1 && styles.wrapperHidden,
        !value.length && styles.wrapperEmpty
      )}
    >
      {value.length ? (
        <div
          ref={listRef}
          className={clsx(
            styles.collapseItemsWrapper,
            value.length > 1 && !isOpened && styles.collapseItemsWrapperHidden
          )}
        >
          <CollapseItem
            key={reversedValue[0]}
            value={reversedValue[0]}
            showButtons={isOpened || reversedValue.length === 1}
            isLast={reversedValue.length === 1}
            onDelete={onDelete}
          />
          {reversedValue.length > 1 &&
            reversedValue
              .slice(1)
              .map((itemValue, index) => (
                <CollapseItem
                  key={itemValue}
                  value={itemValue}
                  showButtons={isOpened || reversedValue.length === 1}
                  isLast={index === reversedValue.length - 2}
                  onDelete={onDelete}
                />
              ))}
        </div>
      ) : (
        <div className={styles.emptyList}>
          <p className={styles.emptyListText}>{t('directory:Controls.enterValueDesc')}</p>
        </div>
      )}

      {reversedValue.length > 1 && (
        <button className={styles.toggleOpenButton} onClick={isOpened ? onClose : onOpen}>
          {isOpened ? t('common:collapse') : t('common:expand')}
        </button>
      )}
    </div>
  );
});
