export const ALLOWED_FILE_TYPES = [
  'application/msexcel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/msexcel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/msexcel',
  'application/msexcel',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-excel.sheet.macroenabled.12',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  'application/vnd.ms-excel.sheet.binary.macroenabled.12',
  'application/vnd.ms-excel.template.macroEnabled.12',
  'application/vnd.ms-excel.template.macroenabled.12',
  'application/vnd.ms-excel.addin.macroenabled.12',
];
